import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withRouter } from "./index";

/**
 * Using an identical interface as Redux's 'connect' method,
 * this function both connects the given component and injects
 * routing props: location, navigate, and params.
 *
 * For use in class components that can't use React Router hooks.
 */
export const connectWithRouter =
  (mapStateToProps = () => ({}), mapDispatchToProps = {}, mergeProps) =>
  Component =>
    withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component));

export const routerPropTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    /** Pathname plus search */
    fullPathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.any,
    key: PropTypes.string,
    query: PropTypes.object,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

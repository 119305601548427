import { useContext } from "react";

import {
  ModalFooter as Footer,
  ModalFooterProps as FooterProps,
} from "@unchained/component-library";
import { NavigateFunction } from "react-router-dom";

import { useNavigate } from "Components/Link";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import { trefoilStore } from "Redux/store";
import { VaultAPI } from "Shared/api";

import { getSelectedAccountKeysFromSigningKeys } from "./functions";
import { IWithdrawModalContext } from "./types";

const getConstructedOutputs = ({
  isBatchSpendFlow,
  batchData,
  singleDestinationAddress,
  isTransferFlow,
  transferDestination,
  amount,
  isMaxSpend,
}: IWithdrawModalContext) => {
  if (isBatchSpendFlow) {
    return batchData.map(({ address: { value: address }, amount: { value: amount } }) => ({
      address,
      amount,
      max: false,
    }));
  }

  let amountInfo: { max: boolean } | { amount: string };
  if (isMaxSpend) {
    amountInfo = { max: true };
  } else {
    amountInfo = { amount, max: false };
  }

  let destinationInfo: { address: string } | { product_type: "Vault" | "Loan"; uuid: string };
  if (isTransferFlow) {
    destinationInfo = {
      product_type: transferDestination.product_type === "vault" ? "Vault" : "Loan",
      uuid: transferDestination.uuid,
    };
  } else {
    destinationInfo = { address: singleDestinationAddress };
  }

  return [{ ...amountInfo, ...destinationInfo }];
};

const handleCreateTransactionError = err => {
  const { data, status } = err?.response || {};

  if (status === 403) {
    return "You are not allowed to create a transaction for this vault";
  }
  if (status === 404) {
    return "Could not find vault";
  }
  if (status === 409) {
    return "You cannot initiate a transaction while another transaction is active.";
  }

  return data?.message || "Something went wrong, please try again later.";
};

const handleCreateTransaction = async (
  context: IWithdrawModalContext,
  navigate: NavigateFunction
) => {
  context.setState({ submittingTransaction: true });
  const vault = trefoilStore.getState().vaults.vaultShow.vault;

  try {
    const outputDetails = getConstructedOutputs(context);
    const response = await VaultAPI.CreateTransaction(
      vault.uuid,
      context.feeRate,
      getSelectedAccountKeysFromSigningKeys(context.signingKeys).map(key => key.uuid),
      outputDetails
    );

    navigate(`/vaults/${vault.uuid}/transactions/${response.uuid}`);
  } catch (err) {
    // The button was disabled when changing the state of
    // submittingTransaction. Re-enable it here.
    context.setState({
      nextDisabled: false,
      submittingTransaction: false,
      submissionErrorMessage: handleCreateTransactionError(err),
    });
  }
};

const footStepper = (context: IWithdrawModalContext, navigate: NavigateFunction) => {
  const { step, nextDisabled, isBatchSpendFlow, submittingTransaction, setState } = context;

  // The default action for the Next button is to increment the step. All steps
  // start with a disabled Next button which is enabled during step validation.
  // Additionally, all changes to the context, save for enabling the button,
  // will disable the button.
  const stepActions: FooterProps["actions"] = [
    {
      children: "Next",
      onClick: () => setState({ step: step + 1 }),
      disabled: nextDisabled,
    },
  ];

  if (isBatchSpendFlow && step === 0) {
    // File drop action goes to the next step, so the Next button is not needed.
    stepActions.pop();
  }

  if ((!isBatchSpendFlow && step === 3) || (isBatchSpendFlow && step === 4)) {
    // This is the summary review step. The Create transaction button here
    // submits the transaction to the server. This button becomes disabled when
    // the request is submitted. The "Back" control in the header as well as the
    // ability to close the modal are disabled while the request is being
    // submitted.
    stepActions[0] = {
      children: "Create transaction",
      disabled: submittingTransaction,
      onClick: () => handleCreateTransaction(context, navigate),
    };
  }

  return stepActions;
};

export const ModalFooter = () => {
  const context = useContext(WithdrawModalContext);
  const navigate = useNavigate();

  if (context.step === 0 && context.isBatchSpendFlow) {
    return null;
  }

  return (
    <Footer
      actions={footStepper(context, navigate)}
      className={
        !context.batchHasError && context.isBatchSpendFlow && context.step === 1 && "!mt-0"
      }
    />
  );
};

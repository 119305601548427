import React from "react";

import cn from "classnames";

import zIndexes from "Assets/scss/abstracts/_z_indexes.scss";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { AppModalManager } from "Shared/components/Modals";

import { SellBitcoinStepperWizard } from "./SellBitcoinStepperWizard";

const SellBitcoinButton = React.memo(
  ({
    disabled,
    className = "ml-3 ",
    buttonColor,
    fullWidth = true,
  }: {
    disabled?: boolean;
    className?: string;
    buttonColor?: string;
    fullWidth?: boolean;
  }) => {
    const openSellStepper = () => AppModalManager.open(() => <SellBitcoinStepperWizard />);

    const { isSaleInProgress } = useSellBitcoinStore();

    const color =
      buttonColor || isSaleInProgress
        ? // TODO replace these styles with component library inProgress button when that button has been merged into the component library
          "!p-1 bg-yellow-500 border-yellow-500 hover:bg-yellow-400 hover:border-yellow-400 text-white"
        : "btn-primary !bg-primary-600 hover:!border-primary-600 hover:!bg-white hover:!text-primary-600";

    return (
      <button
        disabled={disabled}
        className={cn("btn", className, color, fullWidth && "btn-full")}
        onClick={openSellStepper}
        style={{ zIndex: zIndexes["trading-highlight-z-index"] }}
      >
        Sell {isSaleInProgress ? "(in progress)" : null}
      </button>
    );
  }
);

export { SellBitcoinButton };

import { combineReducers } from "redux";

import { keyCheckWizardReducer as keyCheckWizard } from "./keyCheckReducer";
import { keyNewWizardReducer as keyNewWizard } from "./keyNewWizardReducer";
import { keyIndexReducer as keyIndex } from "./keysIndexReducer";

const accountKeyReducers = combineReducers({
  keyIndex,
  keyNewWizard,
  keyCheckWizard,
});

export { accountKeyReducers };

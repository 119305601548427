import React from "react";

import { useNavigate } from "Components/Link";

/**
 * This function injects a navigate function, identical to previous
 * browserHistory.push, into the wrapped component.
 *
 * For use in class components that can't use React Router hooks.
 */
export const withNavigate = Component => props => {
  const navigate = useNavigate();

  return <Component {...props} navigate={navigate} />;
};

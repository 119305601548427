import { combineReducers } from "redux";

import { accountKeyReducers as accountKeys } from "./accountKeyReducers";
import accountKeyReplace from "./accountKeyReplace";
import accountKeyShowSigner from "./accountKeyShowSigner";
import { accountReducers as account } from "./accountReducers";
import passwordReset from "./authenticationReducers/passwordReset";
import business from "./business";
import collateralHealthGauge from "./collateralHealthGauge";
import consumer from "./consumer";
import contractSign from "./contractSign";
import { cryptoReducers as crypto } from "./cryptoReducers";
import features from "./features";
import flash from "./flash";
import getBitcoinAddress from "./getBitcoinAddress";
import { lenderReducer as lenders } from "./lenderReducer";
import loanApprove from "./loanApprove";
import loanDeny from "./loanDeny";
import loanLenders from "./loanLenders";
import { loanReducers as loans } from "./loanReducers";
import loanReturn from "./loanReturn";
import { orgReducers as orgs } from "./orgReducers";
import { overlayReducer as overlay } from "./overlayReducer";
import pagination from "./pagination";
import prepaymentCreate from "./prepaymentCreate";
import principal from "./principal";
import scroll from "./scroll";
import sendBitcoin from "./sendBitcoin";
import slice from "./slice";
import spendPublish from "./spendPublish";
import spendSign from "./spendSign";
import spendSignHardwareWallet from "./spendSignHardwareWallet";
import spendSignOfflineWallet from "./spendSignOfflineWallet";
import { transactionReducers as transaction } from "./transactionReducers";
import { userReducers as user } from "./userReducers";
import { vaultReducers as vaults } from "./vaultReducers";
import verificationApprove from "./verificationApprove";
import walletExporter from "./walletExporter";
import walletMembership from "./walletMembership";

const rootReducer = combineReducers({
  account,
  loans,
  orgs,
  vaults,
  accountKeys,
  features,
  crypto,
  transaction,
  user,
  flash,
  scroll,
  passwordReset,
  lenders,
  loanApprove,
  loanDeny,
  loanLenders,
  contractSign,
  slice,
  loanReturn,
  spendSign,
  spendSignHardwareWallet,
  spendSignOfflineWallet,
  spendPublish,
  consumer,
  business,
  principal,
  walletExporter,
  walletMembership,
  pagination,
  sendBitcoin,
  getBitcoinAddress,
  prepaymentCreate,
  collateralHealthGauge,
  accountKeyReplace,
  accountKeyShowSigner,
  verificationApprove,
  overlay,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

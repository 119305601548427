import React, { Fragment, PureComponent } from "react";

import { Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";

import styles from "./ExploreBlockChain.module.scss";

class ExploreBlockChainBase extends PureComponent {
  static propTypes = {
    item: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    url: PropTypes.string.isRequired,
    showItem: PropTypes.bool,
  };

  static defaultProps = {
    showItem: true,
  };

  render() {
    const { item, url, showItem } = this.props;

    return (
      <div className={styles.txid}>
        {showItem && <code className="break-words">{item}</code>}
        <IconButton href={url} target="_blank" classes={{ root: styles.button }} size="large">
          <Search classes={{ root: styles.icon }} />
        </IconButton>
      </div>
    );
  }
}

export const ExploreBlockChain = ExploreBlockChainBase;

/* global trackEvent ga */
import {
  ApproveLoan200,
  ApproveLoanRequest,
  CreateCollateralSaleRequest201,
  CreateLoanCloseCollateralSaleRequest201,
  CreatePrepayment200,
  GetActivePrepayment200,
  GetPayoffInfo200,
  LoanCloseSellCollateralAgreement200,
  ReturnAddress200,
  SellCollateralAgreement200,
  ShowLoan200,
} from "Specs/v1";

import { API } from "./api";

export class LoanAPI {
  static List = async (page = 1, perPage = 20) => {
    const params = { page, per_page: perPage };
    const response = await API.Get<any>(`/loans`, params);
    const { loans, total, total_pages, per_page } = response.data;
    return { loans, total, totalPages: total_pages, perPage: per_page };
  };

  static ListForOrg = async orgUUID => {
    const response = await API.Get<any>(`/orgs/${orgUUID}/loans`);
    const { loans } = response.data;
    return loans;
  };

  static Create = async (orgUUID, data) => {
    const response = await API.Post<any>(`/orgs/${orgUUID}/loans`, data);
    trackEvent({
      category: "FUNNEL/LOAN",
      action: "created",
      label: data.unit,
    });
    return response.data;
  };

  static Get = async uuid => {
    const response = await API.Get<ShowLoan200>(`/loans/${uuid}`);
    const { loan } = response.data;
    return loan;
  };

  static ApproveLoan = async (uuid: string, data: ApproveLoanRequest) => {
    return await API.Post<ApproveLoan200>(`/loans/${uuid}/approve`, data);
  };

  static DenyLoan = async (uuid, data) => {
    return await API.Post<any>(`/loans/${uuid}/deny`, data);
  };

  static GetAPR = async (uuid, data) => {
    // Calculate new APR from updated loan terms
    const response = await API.Get<any>(`/loans/${uuid}/apr`, data);
    return response.data;
  };

  static UpdateNote = async (uuid, note) => {
    const response = await API.Put<any>(`/loans/${uuid}/note`, note);
    const { message } = response.data;
    return { message };
  };

  static DeleteAsBorrower = async uuid => {
    const response = await API.Delete<any>(`/loans/${uuid}`);
    return response.data;
  };

  static DeleteAsServicer = async uuid => {
    const response = await API.Delete<any>(`/loans/${uuid}/admin`);
    return response.data;
  };

  static SetCloseReason = async (uuid, reason) => {
    const response = await API.Post<any>(`/loans/${uuid}/close_reason`, { close_reason: reason });
    return response.data;
  };

  static SetLender = async (uuid, data) => {
    const response = await API.Post<any>(`/loans/${uuid}/lender`, data);
    return response.data;
  };

  static GetBtcTransactions = async (
    { pageIndex, pageSize } = { pageIndex: 1, pageSize: 20 },
    uuid,
    sortBy,
    sortOrder,
    include_own_transfer_locations = false,
    includeUnbroadcasted = false
  ) => {
    const params = {
      page: pageIndex,
      per_page: pageSize,
      include_unbroadcasted: Boolean(includeUnbroadcasted) ? 1 : 0,
      include_own_transfer_locations,
      sortBy,
      sortOrder,
    };
    const response = await API.Get<any>(`/loans/${uuid}/btc/transactions`, params);
    const { btc_transactions, total, total_pages, per_page } = response.data;
    return {
      btc_transactions,
      total,
      totalPages: total_pages,
      perPage: per_page,
    };
  };

  static CreateRedemptionAsBorrower = async (uuid, feeRate, accountKeyUUIDs, outputs) => {
    const response = await API.Post<any>(`/loans/${uuid}/borrower_redemptions`, {
      fee_rate: feeRate,
      account_key_uuids: accountKeyUUIDs,
      outputs,
    });
    return response.data;
  };

  /**
   * We have a different route for a servicer POSTing a redemption to allow loan servicers the ability to
   * create redemptions with slightly different permissions than the borrower. Namely - two important ones
   * - the CTP ratio can go lower than 250% (e.g. to 200%) and there is no restriction on how recently a
   * redemption was created (e.g. can do multiple redemptions in a 30-day window).
   */
  static CreateRedemptionAsServicer = async (uuid, feeRate, accountKeyUUIDs, outputs, note) => {
    const response = await API.Post<any>(`/loans/${uuid}/servicer_redemptions`, {
      fee_rate: feeRate,
      account_key_uuids: accountKeyUUIDs,
      outputs,
      note,
    });
    return response.data;
  };

  static GetRedemption = async (loanUUID, operationUUID) => {
    const response = await API.Get<any>(`/loans/${loanUUID}/redemptions/${operationUUID}`);
    return response.data;
  };

  static CreateCollateralSale = async (
    uuid,
    amount,
    usd_received,
    exchange_rate,
    trade_id,
    sale_date,
    exchange,
    reason,
    note,
    full_liquidation_date = null
  ) => {
    const response = await API.Post<any>(`/loans/${uuid}/collateral_sales`, {
      btc_sold: amount,
      price: exchange_rate,
      usd_received: usd_received.toString(),
      trade_id,
      sale_date,
      exchange,
      reason,
      note,
      full_liquidation_date,
    });
    return response.data;
  };

  static UpdateCollateralSale = async (loanUuid, saleUuid, trade_id, note) => {
    const response = await API.Put<any>(`/loans/${loanUuid}/collateral_sales/${saleUuid}`, {
      trade_id,
      note,
    });
    return response.data;
  };

  static GetCollateralSales = async uuid => {
    const response = await API.Get<any>(`/loans/${uuid}/collateral_sales`);
    return response.data;
  };

  static CreateLiquidation = async (uuid, sales, feeRate, accountKeyUUIDs, outputs, note) => {
    const response = await API.Post<any>(`/loans/${uuid}/liquidations`, {
      collateral_sale_uuids: sales,
      fee_rate: feeRate,
      account_key_uuids: accountKeyUUIDs,
      outputs,
      note,
    });
    return response.data;
  };

  static GetLiquidation = async (vaultUUID, operationUUID) => {
    const response = await API.Get<any>(`/loans/${vaultUUID}/liquidations/${operationUUID}`);
    return response.data;
  };

  static CreateClosing = async (uuid, feeRate, accountKeyUUIDs, outputs) => {
    const response = await API.Post<any>(`/loans/${uuid}/closing`, {
      fee_rate: feeRate,
      account_key_uuids: accountKeyUUIDs,
      outputs,
    });
    return response.data;
  };

  static GetClosing = async (loanUUID, operationUUID) => {
    const response = await API.Get<any>(`/loans/${loanUUID}/closings/${operationUUID}`);
    return response.data;
  };

  static GetSweep = async (loanUUID, operationUUID) => {
    const response = await API.Get<any>(`/loans/${loanUUID}/sweeps/${operationUUID}`);
    return response.data;
  };

  /**
   * Used for prepayments and full liquidations calculations.
   * Optionally takes a date string to get the payoff amount for a future
   * date. If no date is provided, the payoff amount will default to the next
   * non-holiday business day.
   *
   * date: string in `YYYY-MM-DD` format
   */
  static GetPayoffInfo = async (loanUUID: string, date?: string) => {
    const response = await API.Get<GetPayoffInfo200>(`/loans/${loanUUID}/payoff-info`, { date });
    return response.data;
  };

  static CreateCollateralSaleRequest = async (loanUUID, btcSellQuantity) => {
    await API.Post<CreateCollateralSaleRequest201>(`/loans/${loanUUID}/collateral_sale_request`, {
      btc_sell_qty: Number(btcSellQuantity),
    });
  };

  static CreateLoanCloseCollateralSaleRequest = async (loanUUID: string) => {
    await API.Post<CreateLoanCloseCollateralSaleRequest201>(
      `/loans/${loanUUID}/loan-close-collateral-sale-request`
    );
  };

  static GetSellCollateralAgreement = async (loanUUID: string, btcQty: string) => {
    // Values passed here will be inserted to the agreement.
    const response = await API.Get<SellCollateralAgreement200>(
      `/loans/${loanUUID}/sell-collateral-agreement`,
      {
        "btc-quantity": Number(btcQty).toFixed(8),
      }
    );

    return response.data;
  };

  static GetCollateralSaleRequestsForLoan = async loanUUID => {
    const response = await API.Get<any>(`/loans/${loanUUID}/collateral_sale_requests`);

    return response.data;
  };

  static GetCollateralSaleRequests = async () => {
    const response = await API.Get<any>(`/collateral_sale_requests`);
    return response.data;
  };

  static CancelCollateralRequestBatch = async requests => {
    const response = await API.Post<any>(`/collateral_sale_requests/cancel`, requests);
    return response.data;
  };

  static GetBalances = async (loanUUID: string) => {
    const response = await API.Get<any>(`/loans/${loanUUID}/balances`);
    return response.data;
  };

  static GetLoanReturnAddress = async (loanUUID: string) => {
    const response = await API.Get<ReturnAddress200>(`/loans/${loanUUID}/return_address`);
    return response.data;
  };

  static GetLoanCloseSellCollateralAgreement = async (loanUUID: string) => {
    const response = await API.Get<LoanCloseSellCollateralAgreement200>(
      `/loans/${loanUUID}/loan-close-collateral-sale-agreement`
    );

    return response.data;
  };

  static CreateLoanPrepayment = async ({
    loanUUID,
    dueOn,
  }: {
    loanUUID: string;
    dueOn: string;
  }) => {
    const response = await API.Post<CreatePrepayment200>(`/loans/${loanUUID}/prepayment`, {
      due_on: dueOn,
    });

    return response.data;
  };

  static GetLoanPrepayment = async ({ loanUUID }: { loanUUID: string }) => {
    const response = await API.Get<GetActivePrepayment200>(`/loans/${loanUUID}/prepayment`);

    return response.data;
  };
}

import { Banner } from "@unchained/component-library";

import { Link } from "Components/Link";
import { useMemoizedState } from "Redux/selectors/hooks";

export const InitialBannerWarning = () => {
  const org = useMemoizedState("account.orgs.current");

  return (
    <>
      {org.account_type === "ira" ? (
        <Banner type="error" title="Paperwork required before withdrawal" className="mt-1">
          Before proceeding with an IRA withdrawal, please contact our team at{" "}
          <Link to="mailto:IRA@unchained.com">IRA@unchained.com</Link> to receive the required
          paperwork. Failure to do so is a breach of our Terms of Service and could result in
          negative tax consequences.
        </Banner>
      ) : (
        <Banner type="info" title="Beware of phishing attempts">
          Unchained will never ask you to send bitcoin to an unsolicited address.
        </Banner>
      )}
    </>
  );
};

import { UsdDepositStatusEnum } from "Specs/v1/getDepositActivity/200";

export type DropDownSelectionType = {
  value: string;
  label: string;
};

export type StatusOptionType = {
  value: UsdDepositStatusEnum | "none";
  label: string;
};

export enum AdminTypeEnum {
  creator = "creator",
  approver = "approver",
}

import { useContext } from "react";

import { ModalContent as Content } from "@unchained/component-library";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";

import { AmountAndFees } from "./AmountAndFees";
import { BatchSpendReview } from "./BatchSpendReview";
import { FlowChooser } from "./FlowChooser";
import { KeySelect } from "./KeySelect";
import { TransactionSummary } from "./TransactionSummary";
import { FlowOption } from "./types";

export const ModalContent = ({
  allowSpendToSelf,
  excludeFlows,
}: {
  allowSpendToSelf?: boolean;
  excludeFlows?: FlowOption[];
}) => {
  const { step, isBatchSpendFlow } = useContext(WithdrawModalContext);

  return (
    <Content
      className={
        // This is required because the dropdown selector for transfers fights
        // with modals.
        step === 0 && excludeFlows?.find(flow => flow === "transfer") === undefined
          ? "!overflow-y-visible"
          : ""
      }
    >
      {/* Step 0 is the flow chooser. It gets radio buttons. */}
      {step === 0 && <FlowChooser allowSpendToSelf={allowSpendToSelf} exclude={excludeFlows} />}

      {/* Single address spend steps */}
      {!isBatchSpendFlow && step === 1 && <AmountAndFees />}
      {!isBatchSpendFlow && step === 2 && <KeySelect />}
      {!isBatchSpendFlow && step === 3 && <TransactionSummary />}

      {/* Batch spend steps */}
      {isBatchSpendFlow && step === 1 && <BatchSpendReview />}
      {isBatchSpendFlow && step === 2 && <AmountAndFees />}
      {isBatchSpendFlow && step === 3 && <KeySelect />}
      {isBatchSpendFlow && step === 4 && <TransactionSummary />}
    </Content>
  );
};

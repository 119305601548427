import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";
import { useSelector } from "react-redux";

import { getOwnVaults } from "Redux/selectors";
import { AppModalManager } from "Shared/components/Modals";

export const FundIraModal = () => {
  const vaults = useSelector(getOwnVaults);

  return vaults?.length > 0 ? (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <ModalTitle>IRA funding form redirect</ModalTitle>
      </ModalHeader>
      <ModalContent className="prose">
        Please be aware that you are being redirected to a different Unchained subdomain. You will
        be directed to <em>https://unchained.com/ira-forms</em>. This is an Unchained website
        managed outside of this platform.
      </ModalContent>
      <ModalFooter
        actions={[
          { children: "Proceed", to: "https://unchained.com/ira-forms" },
          { children: "Cancel", onClick: AppModalManager.close },
        ]}
      />
    </Modal>
  ) : (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <ModalTitle>Vault required</ModalTitle>
      </ModalHeader>
      <ModalContent className="prose">
        You must create a vault before starting the IRA funding process.
      </ModalContent>
      <ModalFooter
        actions={[{ children: "Create vault", to: "/vaults/new", onClick: AppModalManager.close }]}
      />
    </Modal>
  );
};

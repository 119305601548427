import React from "react";

import { NotificationType } from "Shared/api/notificationsApi";

import { ApproveSignatureRequestNotification } from "./ApproveSignatureRequestNotification";
import { BroadcastTransactionNotification } from "./BroadcastTransactionNotification";
import { BtcTransactionApprovalNotification } from "./BtcTransactionApprovalNotification";
import { DepositCollateralNotification } from "./DepositCollateralNotification";
import { KeyCheckNotification } from "./KeyCheckNotification";
import { PastDuePaymentNotification, UpcomingPaymentNotification } from "./PaymentNotification";
import { PendingDepositNotification } from "./PendingDepositNotification";
import { PendingOrgMembershipNotification } from "./PendingOrgMembershipNotification";
import { ProvideIraInfoNotification } from "./ProvideIraInfoNotification";
import { ProvideProfileInfoNotification } from "./ProvideProfileInfoNotification";
import { ReturnAddressNotification } from "./ReturnAddressNotification";
import { SignDocumentNotification } from "./SignDocumentNotification";
import { SignIraContractNotification } from "./SignIraContractNotification";
import { SignTransactionNotification } from "./SignTransactionNotification";

export type NotificationItem = {
  to: string;
  title: string;
  subtitle?: string;
  Icon: React.JSXElementConstructor<any>;
  urgent?: boolean;
  additionalAction?: () => void;
};

/**
 * description, given a notification type, return the
 * notification item for displaying that notification
 * @param {object} notification - Notification object
 * @param {object} currentOrg - Current org object
 */
export const getNotificationItem = (
  notification,
  currentOrg,
  ownedVaults = []
): null | NotificationItem => {
  if (!notification) {
    return null;
  }

  let type = notification.action_type;
  if (type === "payment") {
    type = notification.payment_state === "late" ? "past_due_payment" : "upcoming_payment";
  } else if (type === "provide_basic_info") {
    type = currentOrg.account_type === "ira" ? "provide_ira_info" : "provide_profile_info";
  }

  const typeMap = {
    accept_org_membership: PendingOrgMembershipNotification,
    approve_btc_transaction: BtcTransactionApprovalNotification,
    approve_signature_request: ApproveSignatureRequestNotification,
    broadcast_transaction: BroadcastTransactionNotification,
    deposit_collateral: DepositCollateralNotification,
    key_check: KeyCheckNotification,
    past_due_payment: PastDuePaymentNotification,
    pending_deposit: PendingDepositNotification,
    provide_ira_info: ProvideIraInfoNotification,
    provide_profile_info: ProvideProfileInfoNotification,
    set_return_address: ReturnAddressNotification,
    sign_btc_transaction: BtcTransactionApprovalNotification,
    sign_ira_contract: SignIraContractNotification,
    sign_legal: SignDocumentNotification,
    sign_transaction: SignTransactionNotification,
    upcoming_payment: UpcomingPaymentNotification,
  } as unknown as Record<NotificationType, (notification: Notification) => NotificationItem>;

  const notificationItemGenerator = typeMap[type as NotificationType];

  if (!notificationItemGenerator) return null;

  return notificationItemGenerator({ ...notification, org: currentOrg, ownedVaults });
};

import React from "react";

import { Loader } from "@unchained/component-library";
import { UseQueryResult } from "react-query";
import { ValueOf } from "type-fest";

import { Forbidden } from "Components/errors/Forbidden";
import NotFound from "Components/errors/NotFound";
import { ServerError } from "Components/errors/ServerError";
import { REQUEST_STATUS } from "Shared/api";

type Status = number | ValueOf<typeof REQUEST_STATUS>;
interface ApiError {
  message?: string;
  response?: { status?: Status };
}

interface ReactQueryLoaderProps<SuccessType, ErrorType> {
  /** The result of calling React Query's useQuery function, or any such custom query hook. */
  useQueryResult: UseQueryResult<SuccessType, ErrorType>;
  /** What do do if there's an error. By default, the error will be logged and the message thrown. */
  handleError?: (error: ErrorType, data: any) => void;
  /** Accepts a functional child which is passed the data received on isSuccess.  */
  children: (result: UseQueryResult<SuccessType, ErrorType>) => JSX.Element;
  /** Whether the loading indicator should be full page. */
  fullPage?: boolean;
}

/**
 * Handles loading and error states for React Query in a consistent way,
 * passing success data into a functional child.
 */
export function ReactQueryLoader<SuccessType, ErrorType extends ApiError>({
  useQueryResult,
  handleError = (error, data?: unknown) => {
    console.error(error);
    if (data) console.error(data);
    throw new Error(error.message);
  },
  children,
  fullPage = true,
}: ReactQueryLoaderProps<SuccessType, ErrorType>): null | JSX.Element {
  if (useQueryResult.isError) {
    handleError(useQueryResult.error, useQueryResult.data);
    return null;
  }

  if (useQueryResult.isLoading) {
    return <Loader className={fullPage ? "h-screen" : null} />;
  }

  return children(useQueryResult);
}

/** This one behaves like LoadingWrapper, returning an appropriate component for each error type */
export function ReactQueryWrapper<SuccessType, ErrorType extends ApiError>({
  useQueryResult,
  children,
  fullPage = false,
}: ReactQueryLoaderProps<SuccessType, ErrorType>): null | JSX.Element {
  if (useQueryResult.isError) {
    const status = useQueryResult.error.response?.status;

    const { FORBIDDEN, NOT_FOUND } = REQUEST_STATUS;
    if ([NOT_FOUND, 404].includes(status as any)) return <NotFound />;
    if ([FORBIDDEN, 403].includes(status as any)) return <Forbidden />;

    // Catch all if the type doesn't match the above
    return <ServerError />;
  }

  if (useQueryResult.isLoading) return <Loader className={fullPage ? "h-screen" : ""} />;

  return children(useQueryResult);
}

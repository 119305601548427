import React from "react";

import { KEYSTORES } from "@caravan/wallets";
import { FormHelperText, Grid, Tooltip } from "@mui/material";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setWalletTypeKeyCheckAction } from "Actions/keyActions/keyCheckWizardActions";
import {
  setDefaultBip32PathAction,
  setNewWalletTypeAction,
} from "Actions/keyActions/keyNewWizardActions";
import customLogo from "Assets/images/custom.png";
import {
  spendingOperationSelector,
  spendingSigRequestsSelector,
} from "Redux/selectors/spendingSelectors";
import { LoanAPI } from "Shared/api/loanApi";
import { VaultAPI } from "Shared/api/vaultApi";
import { featureOn } from "Utils/config";

import { ItemButton } from "../Elements/Buttons/ItemButton";
import { ColdcardLogo, LedgerLogo, TrezorLogo } from "../Logos";
import QALogo from "../Logos/bitcoin-wizard.png";
import styles from "./HardwareWalletSelector.module.scss";
import { WizardComponentWrapper } from "./WizardComponentWrapper";
import { WizardNavButtons } from "./WizardNavButtons";
import { WizardTitle } from "./WizardTitle";

const NEW_MODE = "New";
const CHECKING_MODE = "Checking";
const SIGNING_MODE = "Signing";

class HardwareWalletSelectorBase extends React.PureComponent {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    walletType: PropTypes.string.isRequired,
    size: PropTypes.string,
    next: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired,
    setWalletType: PropTypes.func.isRequired,
    accountUUID: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  async checkForProductKeys() {
    const { accountType, accountUUID } = this.props;
    const response = await (accountType === "vault" ? VaultAPI : LoanAPI).Get(accountUUID);
    return response && response.slice;
  }

  onDeviceClick = selectedWalletType => () => {
    const { setWalletType, next, setDefaultBip32Path } = this.props;
    setWalletType(selectedWalletType);
    if (selectedWalletType === KEYSTORES.COLDCARD) {
      setDefaultBip32Path("coldcard");
    } else {
      setDefaultBip32Path();
    }
    this.setState({
      errorMessage: "",
    });
    next();
  };

  onNext = () => {
    const { walletType, next } = this.props;
    if (walletType === "") {
      this.setState({
        errorMessage: "Choose a hardware wallet.",
      });
    } else {
      next();
    }
  };

  determineCustomButtonVisibility = () => {
    const { mode, currentUser, currentOrg } = this.props;

    if (mode === NEW_MODE) {
      return (
        featureOn("custom_xpub_upload", currentUser) || featureOn("custom_xpub_upload", currentOrg)
      );
    } else if (mode === SIGNING_MODE) {
      return (
        featureOn("custom_psbt_signing", currentUser) ||
        featureOn("custom_psbt_signing", currentOrg)
      );
    } else {
      return false;
    }
  };

  render() {
    const { back, walletType, mode, size, hideBack, currentUser, selectedSigRequest } = this.props;

    return (
      <WizardComponentWrapper size={size} classes={styles.container}>
        <WizardTitle size={size}>Choose your hardware wallet</WizardTitle>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <ItemButton
              onClick={this.onDeviceClick(KEYSTORES.TREZOR)}
              active={KEYSTORES.TREZOR === walletType}
              icon={TrezorLogo}
              size={this.props.size}
              name="Trezor"
            />
          </Grid>

          {/* The backend cannot properly generate a PSBT if any xfp's are missing.
              We'll use this to nag the user to do keychecks for their keys because they can't sign
              with a Coldcard until they do the keychecks. This only applies to old keys that have not
              been recently keychecked.
              TODO: it's possible none of these checks are needed anymore after PPK scorched earth
              */}
          {featureOn("coldcard", currentUser) &&
            (mode !== SIGNING_MODE || selectedSigRequest.signing_xfp !== "00000000" ? (
              <Grid item>
                <ItemButton
                  onClick={this.onDeviceClick(KEYSTORES.COLDCARD)}
                  active={KEYSTORES.COLDCARD === walletType}
                  icon={ColdcardLogo}
                  name="Coldcard"
                  size={this.props.size}
                />
              </Grid>
            ) : (
              <Grid item>
                <Tooltip title="Please perform key checks to enable Coldcard signing.">
                  {/* Wrap in a div because `disabled` disables pointer-events.
                    See https://github.com/react-toolbox/react-toolbox/issues/651 */}
                  <div>
                    <ItemButton
                      active={KEYSTORES.COLDCARD === walletType}
                      image={ColdcardLogo}
                      name="Coldcard"
                      size={this.props.size}
                      disabled={true}
                    />
                  </div>
                </Tooltip>
              </Grid>
            ))}

          {this.determineCustomButtonVisibility() && (
            <Grid item>
              <ItemButton
                onClick={this.onDeviceClick(KEYSTORES.CUSTOM)}
                active={KEYSTORES.CUSTOM === walletType}
                image={customLogo}
                imageAltText="Custom"
                name="Custom"
                size={this.props.size}
              />
            </Grid>
          )}

          <Grid item>
            <ItemButton
              onClick={this.onDeviceClick(KEYSTORES.LEDGER)}
              active={KEYSTORES.LEDGER === walletType}
              icon={LedgerLogo}
              size={this.props.size}
              name="Ledger"
            />
          </Grid>

          {featureOn("hermit", currentUser) && (
            <Grid item>
              <ItemButton
                onClick={this.onDeviceClick(KEYSTORES.HERMIT)}
                active={KEYSTORES.HERMIT === walletType}
                name="Hermit"
                size={this.props.size}
              />
            </Grid>
          )}

          {mode === SIGNING_MODE && featureOn("qa_sign", currentUser) && (
            <Grid item>
              <ItemButton
                onClick={this.onDeviceClick("QA")}
                active={"QA" === walletType}
                image={QALogo}
                size={this.props.size}
                name="QA"
              />
            </Grid>
          )}
        </Grid>

        <FormHelperText error={true}>{this.state.errorMessage}</FormHelperText>
        <WizardNavButtons
          onNext={this.onNext}
          onBack={back}
          disableNext={walletType === ""}
          hideBack={hideBack}
          wrapperClass={classnames({ [styles.buttonWrapper]: size === "sm" })}
        />
      </WizardComponentWrapper>
    );
  }
}

const mapNewKeyStateToProps = state => {
  return {
    walletCurrency: state.accountKeys.keyNewWizard.walletCurrency,
    walletType: state.accountKeys.keyNewWizard.walletType,
    mode: NEW_MODE,
    currentUser: state.account.user,
    currentOrg: state.account.orgs.current,
  };
};

const mapNewKeyDispatchToProps = {
  setWalletType: setNewWalletTypeAction,
  setDefaultBip32Path: setDefaultBip32PathAction,
};

const mapKeyCheckStateToProps = state => {
  return {
    walletType: state.accountKeys.keyCheckWizard.walletType,
    hideBack: true,
    mode: CHECKING_MODE,
    currentUser: state.account.user,
    currentOrg: state.account.orgs.current,
  };
};

const mapKeyCheckDispatchToProps = {
  setWalletType: setWalletTypeKeyCheckAction,
  setDefaultBip32Path: setDefaultBip32PathAction,
};

// FIXME we are using redux store from key check for signing...
const mapSigningStateToProps = state => {
  return {
    walletType: state.accountKeys.keyCheckWizard.walletType,
    size: "sm",
    mode: SIGNING_MODE,
    currentUser: state.account.user,
    currentOrg: state.account.orgs.current,
    hideBack: true,
    ...spendingOperationSelector(state),
    ...spendingSigRequestsSelector(state),
  };
};

const mapSigningDispatchToProps = {
  setWalletType: setWalletTypeKeyCheckAction,
  setDefaultBip32Path: setDefaultBip32PathAction,
};

export const NewKeyHardwareWalletSelector = connect(
  mapNewKeyStateToProps,
  mapNewKeyDispatchToProps
)(HardwareWalletSelectorBase);
export const KeyCheckHardwareWalletSelector = connect(
  mapKeyCheckStateToProps,
  mapKeyCheckDispatchToProps
)(HardwareWalletSelectorBase);
export const SigningHardwareWalletSelector = connect(
  mapSigningStateToProps,
  mapSigningDispatchToProps
)(HardwareWalletSelectorBase);

import { useEffect, useState } from "react";

import { useQuery } from "react-query";

import { SupportPinsAPI } from "../supportPinsApi";

const supportPinsRequestKeys = {
  clientPin: ["clientPin"] as const,
  adminPin: ["adminPin"],
};

export const useGetSupportPin = (userUuid: string) =>
  useQuery(supportPinsRequestKeys.clientPin, () => SupportPinsAPI.GetSupportPin(userUuid));

export const useGetAdminPin = (userUuid: string) =>
  useQuery(supportPinsRequestKeys.adminPin, () => SupportPinsAPI.GetAdminSupportPin(userUuid));

export const useRefreshSupportPin = query => {
  const [timeLeft, setTimeLeft] = useState(0);
  const { data, refetch } = query;

  useEffect(() => {
    const calcDelay = (validUntil: string) => Date.parse(validUntil) - Date.now();

    if (data) {
      const delay = calcDelay(data.validUntil);

      const timer = setTimeout(() => {
        refetch();
      }, delay);

      setTimeLeft(delay);

      const interval = setInterval(() => {
        setTimeLeft(calcDelay(data.validUntil));
      }, 1000);

      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
  }, [data, refetch]);

  return { query, timeLeft, pin: query.data?.pin };
};

export const useRefreshGetSupportPin = (userUuid: string) => {
  return useRefreshSupportPin(useGetSupportPin(userUuid));
};

export const useRefreshGetAdminPin = (userUuid: string) => {
  return useRefreshSupportPin(useGetAdminPin(userUuid));
};

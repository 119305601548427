import { RadioButton, RadioOrCheckboxSecondTier, Star } from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { Formik, FormikRadioGroup } from "Components/FormFields";
import { Link } from "Components/Link";
import { ManifestStep } from "Routes/onboard/(shared)";
import { useWrapManifestMutation } from "Routes/onboard/(utils)";
import { useAddOrgOnboarding, useGetAccount } from "Shared/api";
import { SKUS } from "Shared/api/v2";
import { useSKUPurchasable } from "Shared/api/v2/hooks/purchasables";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { yup } from "Utils/yup";

export const devicesValidator = yup.object({
  devices: yup.number().min(0).max(2).required().label("Number of devices"),
});

export const getDevicesVals = (org: CompleteOrg | IraOrg) => ({
  devices: "devices_requested" in org ? org.devices_requested : (org as IraOrg).devicesRequested,
});

const signaturePrice = (
  <h2 className="flex flex-row items-center gap-2 text-d-sm font-semi text-[#DB9905]">
    <span>Free</span>
    <Star />
  </h2>
);

export const HardwareWallets = ({
  org,
  buttonText = "Continue",
}: {
  org: CompleteOrg | IraOrg;
  buttonText?: string;
}) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useGetAccount()?.data || {};
  const isSignature = user?.signature;
  const isIra = "iraPlan" in org;
  const wrapManifestMutation = useWrapManifestMutation({ orgUuid: uuid, isIra });

  const devicePurchaseable = useSKUPurchasable(SKUS.TREZOR_SAFE_THREE);
  const devicePriceNumber = parseFloat(devicePurchaseable?.usdPrice);

  const initVals = getDevicesVals(org);

  const addOrgOnboarding = wrapManifestMutation(useAddOrgOnboarding(uuid));
  const onContinueClick = ({ devices }: typeof initVals) =>
    addOrgOnboarding.mutate({ devicesRequested: devices });

  return (
    <Formik<typeof initVals>
      initialValues={initVals}
      onSubmit={onContinueClick}
      validationSchema={devicesValidator}
    >
      {({ handleSubmit }) => {
        return (
          <ManifestStep
            width="wide"
            actions={[
              {
                children: buttonText,
                onClick: handleSubmit,
              },
            ]}
            title="Hardware wallets"
            description={
              <span>
                You will need two{" "}
                <Link to="https://help.unchained.com/what-hardware-wallets-do-you-support">
                  supported devices
                </Link>{" "}
                in order to create a vault.{" "}
                {isSignature ? (
                  <>
                    These devices are covered under your{" "}
                    <span className="font-semi text-[#DB9905]">Signature subscription.</span>
                  </>
                ) : (
                  "Don't have any, or only have one? No worries, we've got you covered. We currently provide Trezor Safe 3 hardware wallets."
                )}
              </span>
            }
          >
            <FormikRadioGroup className="grid gap-4 md:grid-cols-3" name="devices" showError>
              <RadioButton
                label="None"
                value={0}
                description={
                  <RadioOrCheckboxSecondTier
                    upperLeft={
                      isSignature ? (
                        signaturePrice
                      ) : (
                        <h2 className="text-d-sm font-semi text-gray-700">$0</h2>
                      )
                    }
                    lowerLeft="I already have two hardware wallets."
                  />
                }
              />
              <RadioButton
                label="I need one"
                value={1}
                description={
                  <RadioOrCheckboxSecondTier
                    upperLeft={
                      isSignature ? (
                        signaturePrice
                      ) : (
                        <h2 className="text-d-sm font-semi text-gray-700">
                          ${devicePriceNumber * 1}
                        </h2>
                      )
                    }
                    lowerLeft="I already have one Trezor, Ledger, or Coldcard."
                  />
                }
              />
              <RadioButton
                label="I need two"
                value={2}
                description={
                  <RadioOrCheckboxSecondTier
                    upperLeft={
                      isSignature ? (
                        signaturePrice
                      ) : (
                        <h2 className="text-d-sm font-semi text-gray-700">
                          ${devicePriceNumber * 2}
                        </h2>
                      )
                    }
                    lowerLeft="I need two new Trezor Safe 3 hardware wallets."
                  />
                }
              />
            </FormikRadioGroup>
          </ManifestStep>
        );
      }}
    </Formik>
  );
};

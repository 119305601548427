import { useToast } from "@unchained/component-library";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { AppModalManager } from "Shared/components/Modals";
import { CreateNonPlaidBankAccountRequest } from "Specs/v1";
import { BankAccount } from "Specs/v1/getAccount/200";

import { BankAccountAPI } from "../";

export const bankAccountKeys = {
  getOrgBankAccountsAdmin: (orgUuid: string) => ["getOrgBankAccountsAdmin", orgUuid],
  bankAccountsWithValidNonPlaid: "linkedAccounts",
};

export const useCreateNonPlaidBankAccount = (orgUuid: string, onSuccess?: () => void) => {
  const { enqueueSimpleToast } = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    async (values: CreateNonPlaidBankAccountRequest) => {
      return await BankAccountAPI.CreateNonPlaidBankAccount(orgUuid, values);
    },
    {
      onSuccess: () => {
        enqueueSimpleToast({
          type: "success",
          title: "Success",
          description: "You have successfully added a bank account.",
          dismissable: true,
        });
        queryClient.invalidateQueries(["bankAccounts"]);
        onSuccess();
      },
      onError: () => {
        enqueueSimpleToast({
          title: "Could not create bank account",
          type: "error",
          description: "If this issue persists, please contact support.",
          dismissable: true,
          persist: true,
        });
      },
    }
  );
};

export const useGetOrgBankAccountsAdminQuery = (orgUuid?: string) => {
  return useQuery(
    bankAccountKeys.getOrgBankAccountsAdmin(orgUuid),
    () => BankAccountAPI.GetOrgBankAccountsAdmin(orgUuid),
    {
      refetchOnWindowFocus: true,
      enabled: !!orgUuid,
    }
  );
};

export const useGetOrgBankAccountsWithValidNonPlaid = (
  orgUuid: string,
  handleOnSuccess?: (data: BankAccount[]) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    bankAccountKeys.bankAccountsWithValidNonPlaid,
    () => BankAccountAPI.GetOrgBankAccountsWithValidNonPlaid(orgUuid),
    {
      refetchOnWindowFocus: false,
      enabled: !!orgUuid,
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

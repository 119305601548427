import { useContext, useEffect, useState } from "react";

import { ArrowBack } from "@mui/icons-material";
import { Radio, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AddressParam, PaymentIntent } from "@stripe/stripe-js";
import { Button, ButtonProps, Loader } from "@unchained/component-library";
import cn from "classnames";
import { omit } from "lodash";
import { useQueryClient } from "react-query";

import { useNavigate } from "Components/Link";
import {
  SummaryTableCell,
  SummaryTableHeaderCell,
} from "Components/Shared/Elements/Summary/SummaryTableCell";
import { StepColumn } from "Components/Shared/Layouts/FullPageWizard";
import { useLoadingContext } from "Contexts/LoadingContext";
import { CheckoutHeader } from "Routes/inheritance/(shared)/CheckoutHeader";
import { InvoiceReceiptShow } from "Routes/invoices/(shared)/InvoiceReceiptShow";
import { Shipping } from "Routes/invoices/(shared)/ShippingAddressShow";
import {
  applyCouponCodeToSubscription,
  getCompletedPaymentRedirectLink,
} from "Routes/invoices/(shared)/utils";
import { accountQueryKeys, orgQueryKeys, useOrgPayingCustomer } from "Shared/api";
import { INVOICE_STATUS, Invoice, InvoiceAPI, SKUS } from "Shared/api/v2";
import { useInvoice, usePatchInvoice } from "Shared/api/v2/hooks/invoices";
import { mapCreditCardBrandToLogoSvg } from "Utils/billing";
import { useStripeElementProps } from "Utils/stripe";
import { readableTime } from "Utils/time";
import { useEasyToasts } from "Utils/toasts";

import {
  CompletedPayment,
  FailedPayment,
  SignatureZeroCostPaymentCompleted,
} from "../../(shared)/PaymentFinalized";
import { InvoiceContext } from "../InvoiceStepper";

const { FAILED, SETTLED, COMPLETED, PENDING, CANCELED } = INVOICE_STATUS;

const PaymentMethodSelection = ({
  payingCustomerQuery,
  useExistingPaymentMethod,
  setUseExistingPaymentMethod,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  existingPaymentMethods,
  handlePaymentChange,
  paymentStatus,
  invoice,
}) => {
  if (payingCustomerQuery.isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {existingPaymentMethods.length > 0 && useExistingPaymentMethod && (
        <>
          <div className="my-4 text-md font-semi text-gray-700">
            Use an existing payment method:
          </div>
          {existingPaymentMethods.map((card, index) => (
            <div
              key={index}
              className={cn(
                "mb-4 flex cursor-pointer content-center items-center rounded-lg border-2 border-gray-200 p-2 hover:border-primary-500"
              )}
              onClick={() => setSelectedPaymentMethod(card.id)}
            >
              <img
                src={mapCreditCardBrandToLogoSvg(card.brand)}
                className="mr-2 inline max-h-[32px]"
                alt={card.brand}
              />
              <span>ending in {card.last4}</span>
              <span className="grow"></span>
              <Radio className="m" checked={selectedPaymentMethod === card.id} />
            </div>
          ))}
          <Button
            type="text"
            buttonType="button"
            onClick={() => {
              setUseExistingPaymentMethod(false);
              setSelectedPaymentMethod(undefined);
            }}
          >
            + new credit card
          </Button>
        </>
      )}

      {!useExistingPaymentMethod && existingPaymentMethods?.length > 0 && (
        <Button
          type="text"
          className="mb-4"
          buttonType="button"
          startIcon={<ArrowBack key={"arrow-back"} />}
          onClick={() => setUseExistingPaymentMethod(true)}
        >
          Back
        </Button>
      )}
      {(!useExistingPaymentMethod || !existingPaymentMethods?.length) && (
        <>
          <PaymentElement
            onChange={handlePaymentChange}
            id="payment-element"
            options={{ layout: "tabs" }}
          />
        </>
      )}
      <Loader
        className={
          paymentStatus === "processing" ||
          (paymentStatus === "succeeded" && invoice.status === PENDING)
            ? ""
            : "hidden"
        }
      />
    </div>
  );
};

/*
 * Checkout page for invoices (invoice uuid in route).
 * Collects credit card information and processes payment.
 * Currently used for account annual fee + CO checkout.
 */
const InvoiceCheckout = ({ invoice }: { invoice: Invoice }) => {
  const [paymentStatus, setPaymentStatus] = useState<PaymentIntent.Status | "failed">();
  const [isFormValid, setFormValid] = useState(false);
  const loading = useLoadingContext();
  const { from, shippingName, paymentProcessing } = useContext(InvoiceContext);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { showErrorToast, showApiSuccessToast } = useEasyToasts();
  const patchInvoiceMutation = usePatchInvoice(invoice.id);
  const [submitted, setSubmitted] = useState(false);
  const isPaymentInFinalState =
    ["failed", "succeeded"].includes(paymentStatus) ||
    [SETTLED, COMPLETED].includes(invoice.status);
  const queryClient = useQueryClient();

  const payingCustomerQuery = useOrgPayingCustomer(invoice.orgId);

  const successContinueLink = getCompletedPaymentRedirectLink(invoice, from);

  const isZeroCostSignatureInvoice = invoice?.discounts?.some(
    discount => discount.name === "Signature"
  );
  const shippingAddress = (invoice.shippingAddress || {}) as Required<
    typeof invoice.shippingAddress
  >;

  const isIraOrg = invoice.lineItems.some(item => item.sku === SKUS.IRA_ACCOUNT);

  const existingPaymentMethods = payingCustomerQuery.data?.paymentMethods || [];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined);
  const [useExistingPaymentMethod, setUseExistingPaymentMethod] = useState(true);

  const applyCouponCode = async (couponCode: string) => {
    setPaymentStatus("processing");
    try {
      const sub = await applyCouponCodeToSubscription(
        couponCode,
        invoice.subscriptionId,
        invoice.orgId,
        invoice.shippingAddress
          ? { address: invoice.shippingAddress, name: invoice.shippingName }
          : undefined
      );
      showApiSuccessToast({
        title: "Coupon applied",
        description: "Your subscription has been updated.",
      });
      navigate(`/invoices/${sub.invoices[0].id}`);
    } catch (e) {
      showErrorToast(e, { title: "Invalid coupon code" });
      setPaymentStatus(undefined);
    }
  };

  useInvoice(invoice.id, {
    refetchInterval: 1000,
    enabled: submitted && ![COMPLETED, SETTLED].includes(invoice.status),
  });

  const onError = (err: unknown) => {
    console.error(err);
    showErrorToast("Something went wrong. Please try again.");
  };

  // If the Stripe payment succeeded, update the invoice status to "completed"
  useEffect(() => {
    if (paymentStatus === "succeeded") {
      patchInvoiceMutation.mutate(
        { status: COMPLETED },
        {
          onSuccess: () => {
            setSubmitted(true);
            showApiSuccessToast({
              title: "Thank you for your purchase!",
              description: "See your email for confirmation and details.",
            });
            queryClient.invalidateQueries(accountQueryKeys.get);
          },
          onError: err => {
            let error = err as { response: { status: number } };
            // Can end up in a context where the state has already been changed,
            // which throws an inconsequential conflict error
            if (error?.response?.status === 409) {
              setSubmitted(true);
            } else {
              onError(err);
              setPaymentStatus("failed");
            }
          },
        }
      );
    }
  }, [paymentStatus]);

  const handlePayWithExistingCard = async () => {
    setPaymentStatus("processing");
    paymentProcessing.set(true);
    try {
      const paymentReq = await InvoiceAPI.PayWithExistingPaymentMethod(
        invoice.id,
        selectedPaymentMethod
      );

      setPaymentStatus("succeeded");
    } catch (err) {
      setPaymentStatus("failed");
      onError(err);
    }
    paymentProcessing.set(false);
  };

  const handleSubmit = async () => {
    setPaymentStatus("processing");
    paymentProcessing.set(true);
    const return_url = window.location.href;
    const shipping = {
      address: {
        ...omit(shippingAddress, "zip"),
        postal_code: invoice.shippingAddress?.zip,
      } as AddressParam,
      name: shippingName.value,
    };

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url,
          shipping: invoice.shippingAddress ? shipping : undefined,
        },
        redirect: "if_required",
      });

      const { status } = paymentIntent || {};

      if (error) {
        onError(error);
        setPaymentStatus("failed");
        paymentProcessing.set(false);
      } else if (status) {
        setPaymentStatus(status);
      }
    } catch (err) {
      setPaymentStatus("failed");
      paymentProcessing.set(false);
      onError(err);
    }
  };

  const getActions = (): [ButtonProps, ButtonProps] | [ButtonProps] => {
    if (!isPaymentInFinalState) {
      if (existingPaymentMethods.length && useExistingPaymentMethod) {
        return [
          {
            children: "Complete purchase",
            onClick: handlePayWithExistingCard,
            disabled: !selectedPaymentMethod || paymentStatus === "processing",
          },
        ];
      }
      return [
        {
          children: "Complete purchase",
          onClick: handleSubmit,
          disabled: !stripe || !elements || !isFormValid || paymentStatus === "processing",
        },
      ];
    } else if (invoice.status === COMPLETED) {
      return [
        {
          children: "Done",
          type: "secondary",
          onClick: async () => {
            await Promise.all([
              queryClient.refetchQueries(accountQueryKeys.get),
              queryClient.refetchQueries(
                isIraOrg ? orgQueryKeys.showIra(invoice.orgId) : orgQueryKeys.show(invoice.orgId)
              ),
            ]);
            navigate(successContinueLink);
          },
        },
      ];
    } else if (paymentStatus === "failed" || invoice.status === FAILED) {
      return [
        {
          children: "Try again",
          type: "secondary",
          onClick: handleTryAgain,
        },
        {
          children: "Exit",
          type: "text",
          to: from || "/home",
        },
      ];
    }
  };

  const handlePaymentChange = event => setFormValid(event.complete);
  const handleTryAgain = () => setPaymentStatus(undefined);

  const renderPaymentSection = () => {
    if ([SETTLED, COMPLETED].includes(invoice.status)) {
      if (isZeroCostSignatureInvoice) {
        return <SignatureZeroCostPaymentCompleted />;
      }
      return (
        <div>
          <CompletedPayment />
          {invoice.paymentMethod && (
            <Table className="my-4">
              <TableBody>
                <TableRow>
                  <SummaryTableHeaderCell>Paid at</SummaryTableHeaderCell>
                  <SummaryTableCell>{readableTime(invoice.paidAt)}</SummaryTableCell>
                </TableRow>
                <TableRow>
                  <SummaryTableHeaderCell>Payment method</SummaryTableHeaderCell>
                  <SummaryTableCell>
                    <img
                      src={mapCreditCardBrandToLogoSvg(invoice.paymentMethod?.brand)}
                      className="mr-2 inline max-h-[32px]"
                      alt={invoice.paymentMethod?.brand}
                    />
                    ending in {invoice.paymentMethod?.last4}
                  </SummaryTableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      );
    } else if (
      paymentStatus === "failed" ||
      invoice.status === FAILED ||
      invoice.status === CANCELED
    ) {
      return <FailedPayment />;
    } else {
      return (
        <PaymentMethodSelection
          payingCustomerQuery={payingCustomerQuery}
          useExistingPaymentMethod={useExistingPaymentMethod}
          setUseExistingPaymentMethod={setUseExistingPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          existingPaymentMethods={existingPaymentMethods}
          handlePaymentChange={handlePaymentChange}
          paymentStatus={paymentStatus}
          invoice={invoice}
        />
      );
    }
  };

  return (
    <div className="max-h-screen max-w-4xl">
      <StepColumn width="full" actions={getActions()} loading={loading.value}>
        <CheckoutHeader
          title="Payment"
          subtitle={
            isPaymentInFinalState ? undefined : "Please enter your payment information below"
          }
        />
        <div className="flex min-h-[36rem] flex-col gap-8 lg:flex-row">
          <div className="flex w-96 flex-1 flex-col">
            <h2 className="mb-6 text-gray-900">Purchase details</h2>

            <InvoiceReceiptShow
              invoice={invoice}
              applyCouponCode={
                !(isPaymentInFinalState || paymentProcessing.value) ? applyCouponCode : undefined
              }
            />
            {invoice.shippingAddress && (
              <Shipping name={invoice.shippingName} address={invoice.shippingAddress} />
            )}
          </div>

          <div className="w-96 flex-1">
            <h2 className="mb-6 text-gray-900">Payment information</h2>

            {renderPaymentSection()}
          </div>
        </div>
      </StepColumn>
    </div>
  );
};

export const InvoiceCheckoutWithElements = ({ invoice }) => {
  const elementProps = useStripeElementProps(invoice.clientSecret);

  return (
    <Elements {...elementProps}>
      <InvoiceCheckout invoice={invoice} />
    </Elements>
  );
};

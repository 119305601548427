import React, { Component } from "react";

import PropTypes from "prop-types";

import { exploreAddressURL } from "Utils/wallet";

import { ExploreBlockChain } from "./ExploreBlockChain";

class ExploreAddressBase extends Component {
  static propTypes = {
    address: PropTypes.string,
    unit: PropTypes.string.isRequired,
    showAddress: PropTypes.bool,
  };

  static defaultProps = {
    showAddress: true,
  };

  exploreUrl = () => {
    const { address, unit } = this.props;
    return exploreAddressURL(window.TREFOIL_CONFIG.wallet.testnet, unit, address);
  };

  render() {
    const { address, showAddress } = this.props;

    return <ExploreBlockChain url={this.exploreUrl()} item={address} showItem={showAddress} />;
  }
}

export const ExploreAddress = ExploreAddressBase;

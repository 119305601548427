import React, { ComponentType, ReactElement, useEffect } from "react";

import { Loader } from "@unchained/component-library";
import { cloneDeep } from "lodash";
import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";
import { CamelCasedProperties, Merge } from "type-fest";

import {
  setCurrentUserAccountDataAction,
  updateAccountOrgsAction,
  updateAccountUserAction,
  updateAccountUserStatusAction,
} from "Actions/accountActions";
import { DEFAULT_ORG_MENU_UUID_STORAGE_NAME } from "Actions/accountActions/constants";
import {
  replaceAccountCurrentOrgAction,
  updateAccountCurrentOrgStatusAction,
} from "Actions/accountActions/orgActions";
import { useCurrentOrg as useReduxCurrentOrg } from "Redux/selectors/hooks";
import { AccountPersonalOrg, CompleteOrg, GetAccount200 } from "Specs/v1/getAccount/200";
import { useActions } from "Utils/hooks";
import { useEnsureQuery } from "Utils/reactQuery";

import { OrgAPI, UserAPI } from "../";
import { REQUEST_STATUS } from "../api";
import { orgQueryKeys } from "./orgs";

const { SUCCESS } = REQUEST_STATUS;

export const accountQueryKeys = {
  get: "account" as const,
};

/** Given account data, calculate currentOrg and whether user shouldOnboard. */
const extractCurrentOrgFromAccountResponse = (
  account: CamelCasedProperties<GetAccount200>,
  queryClient: ReturnType<typeof useQueryClient>
): { currentOrg?: CompleteOrg | AccountPersonalOrg; shouldOnboard?: boolean } => {
  const defaultUuid = window.localStorage.getItem(DEFAULT_ORG_MENU_UUID_STORAGE_NAME);
  if (!account) return {};

  const { personalOrg, memberships } = account;

  let selectedOrgUUID = defaultUuid;

  let selectedOrgState;
  if (selectedOrgUUID) {
    // If an org was previously selected, then determine its state.
    if (selectedOrgUUID === personalOrg.uuid) {
      selectedOrgState = personalOrg.state;
    } else {
      const selectedOrgMembership = memberships.find(
        membership => membership.org.uuid === selectedOrgUUID
      );
      if (selectedOrgMembership) {
        selectedOrgState = selectedOrgMembership.org.state;
      } else {
        // this means the defaultUUID was probably from a different user
        // since it didn't match any of our orgs or personal.
        // we'll default to the personal in this case and let the condition
        // at the end sort out result based on state
        selectedOrgState = personalOrg.state;
        selectedOrgUUID = personalOrg.uuid;
      }
    }
  }

  // If no org was selected OR the selected org was pending
  // activation, try to select a better org.
  if (!selectedOrgUUID || selectedOrgState === "pending_activation") {
    if (personalOrg.state === "pending_activation") {
      // If the personal org is pending activation, then try to select
      // another org that the user has accepted.
      const acceptedMemberships = memberships.filter(membership => membership.state === "accepted");
      if (acceptedMemberships.length > 0) {
        selectedOrgUUID = acceptedMemberships[0].org.uuid;
        selectedOrgState = acceptedMemberships[0].org.state;
      }
    } else {
      // If the personal org is *not* pending activation, then select
      // it.
      selectedOrgUUID = personalOrg.uuid;
      selectedOrgState = personalOrg.state;
    }
  }

  // Regardless of how we got here, if the selected org UUID/state is
  // not set or the state is 'pending_activation' then we know we show
  // the personal org and send to onboard if it isn't live.
  if (!selectedOrgUUID || !selectedOrgState || selectedOrgState === "pending_activation") {
    localStorage.setItem(DEFAULT_ORG_MENU_UUID_STORAGE_NAME, personalOrg.uuid);
    return {
      currentOrg: personalOrg,
      shouldOnboard: !["pending_payment", "live"].includes(personalOrg.state),
    };
  }

  const orgs: (AccountPersonalOrg | CompleteOrg)[] = [personalOrg, ...memberships.map(m => m.org)];

  // Membership orgs are lightweight. If we've got a full one in the cache, return that
  const cachedOrg = queryClient.getQueryData(orgQueryKeys.show(selectedOrgUUID));
  return {
    currentOrg: cachedOrg || orgs.find(o => o.uuid === selectedOrgUUID),
    shouldOnboard: false,
  };
};

export type GetAccount = CamelCasedProperties<GetAccount200> & {
  currentOrg: CompleteOrg;
  shouldOnboard: boolean;
  isUnchainedAdmin: boolean;
  /**
   * If the current org response from the getAccount query has been replaced by a more
   * complete org fetched from the orgs API
   */
  currentOrgComplete?: boolean;
};

/**
 * Grabs account (personalOrg, memberships, user),
 * calculates currentOrg if applicable,
 * then loads full current org in background,
 * and dispatches results to Redux store.
 **/
export const useGetAccount = (queryOptions: UseQueryOptions = {}) => {
  const currentOrgInRedux = useReduxCurrentOrg();
  const {
    replaceAccountCurrentOrg,
    updateAccountUser,
    updateAccountOrgs,
    updateAccountUserStatus,
    updateAccountCurrentOrgStatus,
    setCurrentUserAccountData: setUserAccountData,
  } = useActions({
    replaceAccountCurrentOrgAction,
    updateAccountUserAction,
    updateAccountOrgsAction,
    updateAccountUserStatusAction,
    setCurrentUserAccountDataAction,
    updateAccountCurrentOrgStatusAction,
  });

  const queryClient = useQueryClient();
  const ensureQuery = useEnsureQuery();

  const getAccount = useQuery<GetAccount200>(accountQueryKeys.get, UserAPI.GetAccount, {
    select: (data: GetAccount) => {
      const { memberships, personalOrg } = data;

      const orgs = [personalOrg, ...memberships.map(m => m.org)];

      return {
        ...data,
        ...extractCurrentOrgFromAccountResponse(data, queryClient),
        isUnchainedAdmin: orgs.some(o => o.type === "unchained"),
      };
    },
    onSuccess: (data: GetAccount) => {
      const { memberships, personalOrg, user } = data;
      updateAccountUser(user);
      updateAccountOrgs(personalOrg, memberships);
      updateAccountUserStatus(SUCCESS);

      if (!window.TREFOIL_USER) return;

      const simpleTrefoilUser = cloneDeep(window.TREFOIL_USER) as { [key: string]: unknown };
      delete simpleTrefoilUser.readableCreatedAt;
      delete simpleTrefoilUser.readableUpdatedAt;
      setUserAccountData(simpleTrefoilUser);
    },
    ...queryOptions,
  });

  const reduxOrgUuid = currentOrgInRedux?.uuid;

  const localStorageUuid = window.localStorage.getItem(DEFAULT_ORG_MENU_UUID_STORAGE_NAME);

  /** Watch the localStorageUuid, and when it changes,
   * - update Redux current user info
   * - make sure to fetch the full current org
   * - further down, insert that full org in the place of
   *   the preliminary current org returned by the account query
   **/
  useEffect(() => {
    if (!getAccount.data) return;

    const { currentOrg } = getAccount.data as GetAccount;

    if (currentOrg && reduxOrgUuid !== currentOrg.uuid) {
      replaceAccountCurrentOrg(currentOrg);
      updateAccountCurrentOrgStatus(SUCCESS);
    }

    // If the current org is not "complete" (doesn't have a full version in the cache),
    // fetch that full version. (Unblocking; prioritizes cache.)
    // Further down in the useGetAccount hook, we'll check for this and return the full one.
    // Here, we also add it to the redux store.
    ensureQuery({
      queryKey: orgQueryKeys.show(currentOrg.uuid),
      queryFn: () => OrgAPI.Get(currentOrg.uuid),
    })
      .then(org => {
        replaceAccountCurrentOrg(org);
        updateAccountCurrentOrgStatus(SUCCESS);
      })
      .catch(err => {
        console.error(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccount.data as GetAccount, reduxOrgUuid, localStorageUuid]);

  const data = (getAccount.data || {}) as GetAccount;
  const currentOrgComplete = !!(queryClient.getQueryData(
    orgQueryKeys.show(data.currentOrg?.uuid)
  ) as CompleteOrg);
  const account = {
    ...getAccount,
    data: { ...data, currentOrgComplete },
  } as UseQueryResult<GetAccount>;

  // Attach account data to the window locally for debugging purposes
  if (process.env?.NODE_ENV === "development") {
    (window as unknown as { account: unknown }).account = account.data;
  }

  return account;
};

/** Fetches the account and the full current org, and shows a loader until both have returned */
export function withAccount<T = unknown>(
  Component: ComponentType<Merge<GetAccount, T>>,
  options: {
    /** If true, the provided/default loader will display until the GET org request has resolved the full org. */
    waitForFullCurrentOrg?: boolean;
    /** Loader to replace the default spinner */
    loader?: ReactElement | null;
  } = {}
): React.FC<T> {
  const { waitForFullCurrentOrg = false, loader = <Loader className="h-screen" /> } = options;
  return (props: T) => {
    const account = useGetAccount();

    if (account.isLoading) return loader;

    // Account membership orgs come back incomplete.
    // Because some pages expect more complete features,
    // we fetch the full current org explicitly, and optional wait until it's in the cache.
    if (waitForFullCurrentOrg && !account.data?.currentOrgComplete) return loader;

    const fullProps = { ...(account.data || {}), ...(props as T) } as Merge<GetAccount, T>;
    return <Component {...fullProps} />;
  };
}

export const useAccountCurrentOrg = () => {
  const account = useGetAccount();
  return account.data?.currentOrg || {};
};

export const useAccountPersonalOrg = () => {
  const account = useGetAccount();
  return account.data?.personalOrg || {};
};

import { get } from "lodash";
import { useSelector } from "react-redux";

import {
  AccountPersonalOrg,
  AccountUser,
  CompleteOrg,
  OrgForListStateEnum,
} from "Specs/v1/getAccount/200";

export const useMemoizedState = <T = any>(statePath: string): T => {
  const selector = state => get(state, statePath);
  return useSelector(selector);
};

export const useCurrentUser = (): AccountUser => useMemoizedState("account.user");

export const isLensable = ({ name, state }: { name?: string; state?: OrgForListStateEnum }) =>
  name && state !== "pending_activation";

export const useCurrentOrg = () => {
  const org = useMemoizedState("account.orgs.current");
  return {
    ...org,
    lensable: isLensable(org),
  } as (AccountPersonalOrg | CompleteOrg) & {
    /* Whether the org is good to interact with -- ie, it's no longer pending and has a name */
    lensable: boolean;
  };
};

import React, { useContext } from "react";

import { ModalHeader as Header, ModalTitle } from "@unchained/component-library";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";

import { IWithdrawModalContext } from "./types";

const stepBacker = ({
  step,
  isBatchSpendFlow,
  isMaxSpend,
  maxSpendLocked,
  submittingTransaction,
  setState,
}: IWithdrawModalContext) => {
  let back: {
    text: string;
    onClick: () => void;
  };

  if (step > 0) {
    back = {
      text: "Back",
      onClick: () => !submittingTransaction && setState({ step: step - 1 }),
    };
  }

  if (step === 1 && !isBatchSpendFlow) {
    back.onClick = () =>
      setState({
        step: 0,
        // The max spend setting should be retained if maxSpendLocked is true.
        isMaxSpend: maxSpendLocked ? isMaxSpend : false,
        amount: "",
        feeRate: "",
      });
  }

  if (step === 1 && isBatchSpendFlow) {
    back.onClick = () =>
      setState({
        step: 0,
        batchData: [],
        amount: "",
        singleDestinationAddress: "",
      });
  }

  if (step === 2 && isBatchSpendFlow) {
    back.onClick = () =>
      setState({
        step: 1,
        feeRate: "",
      });
  }

  return back;
};

export const ModalHeader = () => {
  const context = useContext(WithdrawModalContext);
  const { step, isBatchSpendFlow } = context;

  return (
    <Header back={stepBacker(context)}>
      {step === 0 && (
        <ModalTitle subtitle="Where are you sending the bitcoin?">Send bitcoin</ModalTitle>
      )}

      {/* Single address spend steps */}
      {!isBatchSpendFlow && step === 1 && (
        <ModalTitle subtitle="Enter an amount, and select a fee rate. All calculated dollar amounts and fees are approximate due to fluctuations in the bitcoin exchange rate.">
          Send bitcoin
        </ModalTitle>
      )}
      {!isBatchSpendFlow && step === 2 && (
        <ModalTitle subtitle="Select the keys that will sign for this transaction.">
          Send bitcoin
        </ModalTitle>
      )}
      {!isBatchSpendFlow && step === 3 && (
        <ModalTitle subtitle="Review the transaction summary below. Once created, you can begin the signing process before broadcasting.">
          Send bitcoin
        </ModalTitle>
      )}

      {/* Batch spend steps */}
      {isBatchSpendFlow && step === 1 && (
        <ModalTitle subtitle="Please review the addresses and amounts before proceeding.">
          Send bitcoin
        </ModalTitle>
      )}
      {isBatchSpendFlow && step === 2 && (
        <ModalTitle subtitle="Select a fee rate. All calculated dollar amounts and fees are approximate due to fluctuations in the bitcoin exchange rate.">
          Send bitcoin
        </ModalTitle>
      )}
      {isBatchSpendFlow && step === 3 && (
        <ModalTitle subtitle="Select the keys that will sign for this transaction.">
          Send bitcoin
        </ModalTitle>
      )}
      {isBatchSpendFlow && step === 4 && (
        <ModalTitle subtitle="Review the transaction summary below. Once created, you can begin the signing process before broadcasting.">
          Send bitcoin
        </ModalTitle>
      )}
    </Header>
  );
};

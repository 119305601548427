import React, { Component, Fragment } from "react";

import { Grid } from "@mui/material";
import { Card, CardContent, CardHeader } from "@unchained/component-library";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ExploreTransaction } from "Components/Shared/Elements/Crypto/ExploreTransaction";
import { Success } from "Components/Shared/Elements/Success";
import { spendingTransactionSelector } from "Redux/selectors/spendingSelectors";

import styles from "./SpendConfirmation.module.scss";

class SpendConfirmationBase extends Component {
  static propTypes = {
    transactionTxid: PropTypes.string,
    unit: PropTypes.string,
  };

  render() {
    const { transactionTxid } = this.props;

    return (
      <Fragment>
        <Grid item xs={10}>
          <Card>
            <CardHeader title="Pending confirmation" />
            <CardContent>
              <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item xs={12} container justifyContent="center">
                  <Success size="md" successText="Broadcast success!" successTextClasses="h950" />
                </Grid>

                <Grid item xs={12} container spacing={2} alignItems="baseline">
                  <Grid item xs={4}>
                    <h4 className={styles.transactionIdLabel}>Transaction ID: </h4>
                  </Grid>
                  <Grid item xs={8}>
                    <ExploreTransaction txid={transactionTxid} unit="BTC" />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...spendingTransactionSelector(state),
  };
};

export const SpendConfirmation = connect(mapStateToProps)(SpendConfirmationBase);

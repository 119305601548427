import { AccessTime, Check, HelpOutline } from "@mui/icons-material";
import {
  Avatar,
  AvatarColor,
  Banner,
  Button,
  Chip,
  Switch,
  Table,
  Tooltip,
  Trash,
} from "@unchained/component-library";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { removeOrgPendingMemberAction } from "Actions/orgActions/orgManageActions";
import { Link } from "Components/Link";
import { useIsBuyBitcoinFeatureEnabled } from "Components/TradingDesk/hooks";
import { currentOrgSelector } from "Redux/selectors";
import { useCurrentUser } from "Redux/selectors/hooks";
import { orgQueryKeys, useGetOrg } from "Shared/api/hooks";
import { triggerConfirmationModal } from "Shared/components/Modals";
import { AppModal } from "Shared/components/Modals/AppModal";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { AcceptedMember } from "Specs/v1/getOrg/200";
import { getConfig } from "Utils/config";
import { userLabel } from "Utils/labels";

import { RoleTooltip } from "../RoleTooltip";
import {
  displayRoleName,
  mainOrgRoles,
  numMembersWhoCanApprove,
  rolesForUserUuid,
  tradingManagedRoles,
} from "../utils";
import { MemberActionsMenu } from "./MemberActionsMenu";
import styles from "./OrgManageMembers.module.scss";

type Member = AcceptedMember;

interface MemberRowData {
  name?: string;
  email: string;
  member?: Member;
  status: string;
  markedForRemoval?: boolean;
}

const CantAddMembers = () => (
  <Banner type="info" title="Upgrade to add members">
    Contact us to upgrade to an advanced account and enable adding members to this account:{" "}
    <Link to={`mailto:${getConfig("email.help")}?subject=Upgrade membership in business accounts`}>
      {getConfig("email.help")}
    </Link>
  </Banner>
);

const acceptedMemberRowData = (member: Member, usersToRemove): MemberRowData => ({
  name: userLabel(member.user),
  member: member,
  email: member.user.email,
  status: "Active",
  markedForRemoval: usersToRemove.includes(member.user.email),
});

const pendingMemberRowData = (email): MemberRowData => ({
  email,
  status: "Pending",
});

export const OrgManageMembers = ({
  isEditing,
  userRoleChanges,
  userTradingChanges,
  usersToRemove,
  setUserRoleChanges,
  setUserTradingChanges,
  markUserForRemoval,
}) => {
  const { uuid } = useSelector(currentOrgSelector);
  const orgQuery = useGetOrg(uuid);
  const dispatch = useDispatch();
  const { isTradingFeatureAvailable } = useIsBuyBitcoinFeatureEnabled();
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  if (orgQuery.isLoading || orgQuery.isError) return null;

  const org = orgQuery.data;
  const {
    owner,
    accepted_members,
    max_members,
    pending_members_emails,
    allowed_actions,
    child_roles,
    uuid: orgUuid,
    quorum_config,
  } = org;

  // Bail early if there aren't enough members to manage
  if (max_members === 1) return <CantAddMembers />;

  const canRemove = allowed_actions.includes("remove_member");

  const memberRowData = [
    ...(accepted_members || []).map(member => acceptedMemberRowData(member, usersToRemove)),
    ...(pending_members_emails || []).map(pendingMemberRowData),
  ];

  // These are the roles other than org-management/approval/trading roles,
  // and which are not custom, but instead administrative. They include roles like client-escalation, etc.
  const otherRoles = child_roles.filter(role => !role.dedicated && !mainOrgRoles.has(role.managed));

  const displayOtherRoles = otherRoles.length > 0;

  const setUserRole = (uuid, managedRoles) => {
    setUserRoleChanges(prev => ({
      ...prev,
      [uuid]: managedRoles,
    }));
  };

  const toggleTrading = (uuid, tradingToggle) => {
    setUserTradingChanges(prev => ({
      ...prev,
      [uuid]: tradingToggle,
    }));
  };

  const columns = [
    {
      header: " ",
      size: 20,
      Cell: ({ cell }) => {
        const { email, name, status, member } = cell.row.original as MemberRowData;

        let avatarColor: AvatarColor | undefined;

        if (status === "Pending") avatarColor = "lightGray";
        else {
          const roleDisplayName = displayRoleName(member.user, owner.uuid, child_roles);

          if (roleDisplayName === "Admin") avatarColor = avatarColor || "blue";
          else if (roleDisplayName === "Approver") avatarColor = avatarColor || "gray";
          else if (roleDisplayName === "Viewer") avatarColor = avatarColor || "green";
        }

        return (
          <Avatar
            shape="circle"
            name={name || email}
            color={avatarColor}
            style={{ margin: "1rem" }}
          />
        );
      },
    },
    {
      header: "Name",
      accessorKey: "name",
      size: 110,
      Cell: ({ cell }) => {
        const name = cell.getValue();
        if (!name) return null;

        return <span>{name}</span>;
      },
    },
    {
      header: "Email address",
      accessorKey: "email",
      size: 110,
      Cell: ({ cell }) => {
        const email = cell.getValue();
        if (!email) return null;

        return <Link to={`mailto:${email}`}>{email}</Link>;
      },
    },

    // This column should only show up if trading is enabled

    // This column is basically just for admin-only roles, and shouldn't show up for most orgs
    displayOtherRoles && {
      header: "Other roles",
      id: "other-roles",
      size: 150,
      Cell: ({ cell }) => {
        const { member } = cell.row.original as MemberRowData;
        if (!member) return null;

        const roles = rolesForUserUuid(member.user.uuid, otherRoles);

        return <span>{roles.map(role => role.name).join(", ")}</span>;
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      size: 110,
      Cell: ({ cell }) => {
        const { status, markedForRemoval } = cell.row.original;

        if (!status) return null;

        if (status === "Pending")
          return <Chip icon={<AccessTime />} type="info" label="Invite sent" />;

        if (status === "Verifying") return <Chip icon type="warning" label="Verifying member" />;

        if (markedForRemoval)
          return <Chip icon={<Trash />} label="Marked for removal" type="info" />;

        return <Chip label="Active" icon={<Check />} type="success" />;
      },
    },
    {
      header: "Role",
      Header: () => (
        <div>
          <span>Role</span>
          <Tooltip content={<RoleTooltip />}>
            <span style={{ fontSize: 16 }}>
              <HelpOutline fontSize="inherit" />
            </span>
          </Tooltip>
        </div>
      ),
      accessorKey: "role",
      size: 140,
      Cell: ({ cell }) => {
        const { email, member, status, markedForRemoval } = cell.row.original;

        if (status === "Pending" && canRemove && !isEditing)
          return (
            <Button
              type="text"
              style={{ justifyContent: "flex-start" }}
              onClick={() => {
                triggerConfirmationModal({
                  text: "Do you want to uninvite this pending member from your organization?",
                  onConfirm: async () => {
                    await dispatch(removeOrgPendingMemberAction(orgUuid, email));
                    queryClient.refetchQueries(orgQueryKeys.show(orgUuid));
                  },
                });
              }}
            >
              Uninvite
            </Button>
          );
        else if (status === "Pending") return null;
        else if (member.user.uuid === owner.uuid) {
          return isEditing ? (
            <div className="cursor-default">
              <Tooltip content="Can't change the role of the account creator">
                <span>
                  <span className="me-1">Admin</span>
                  <span style={{ fontSize: 16 }}>
                    <HelpOutline fontSize="inherit" />
                  </span>
                </span>
              </Tooltip>
            </div>
          ) : (
            "Admin"
          );
        }

        if (!isEditing) return displayRoleName(member.user, owner.uuid, child_roles);

        return (
          <MemberActionsMenu
            childRoles={child_roles}
            member={member}
            changeUserRole={setUserRole}
            disabled={markedForRemoval}
          />
        );
      },
    },
    isTradingFeatureAvailable && {
      header: "Trading",
      id: "trading",
      size: 90,
      Cell: ({ cell }) => {
        const { member, markedForRemoval } = cell.row.original as MemberRowData;
        if (!member) return null;

        const roles = rolesForUserUuid(member.user.uuid, child_roles);
        const newRoles = userRoleChanges[member.user.uuid] as string[];
        const userIsAdmin = newRoles
          ? newRoles.some(role => role === "admins")
          : roles.some(role => role.managed === "admins");

        const userTradingChange = userTradingChanges[member.user.uuid];
        const isEnabled =
          userTradingChange !== undefined
            ? userTradingChange
            : roles.some(role => tradingManagedRoles.includes(role.managed as string));

        return userIsAdmin && isEditing && !markedForRemoval ? (
          <Tooltip content="Admins will always have trading permission">
            <span>
              <Switch checked={true} disabled={true} data-testid="trading-toggle" />
            </span>
          </Tooltip>
        ) : (
          <Switch
            data-testid="trading-toggle"
            checked={isEnabled || userIsAdmin}
            disabled={!isEditing || userIsAdmin || markedForRemoval}
            onChange={() => toggleTrading(member.user.uuid, !isEnabled)}
          />
        );
      },
    },
    isEditing && {
      header: "",
      id: "remove",
      size: 24,
      Cell: ({ cell }) => {
        const { member, status, markedForRemoval } = cell.row.original as MemberRowData;
        if (status === "Pending" || member.user.uuid === owner.uuid) return null;

        return (
          <Button
            type="text"
            data-testid="remove-member"
            onClick={() => markUserForRemoval(member.user.email)}
            disabled={markedForRemoval}
          >
            <Trash />
          </Button>
        );
      },
    },
  ].filter(Boolean);

  interface RowData extends MemberRowData, Record<string, unknown> {}
  return (
    <div className="w-full">
      {quorum_config && numMembersWhoCanApprove(org) < quorum_config.min_approvals ? (
        <Banner
          type="warning"
          title="Quorum size exceeds number of available approvers"
          className="mb-8"
        >
          Your organization requires all outgoing transactions are approved by multiple users,
          however there are not enough members enabled with the required role. Please update your
          settings below by either adding more approvers or by decreasing your required quorum size.
        </Banner>
      ) : null}
      <div className="flex justify-between">
        <h1 className="mb-2">Account users</h1>
      </div>

      <Table<RowData>
        className={styles.membersTable}
        muiTableBodyRowProps={({ row }) => ({
          classes: {
            root:
              row.original?.email && usersToRemove.includes(row.original?.email)
                ? styles.markedForRemoval
                : undefined,
          },
          "data-testid": row.original?.email,
        })}
        data={memberRowData as RowData[]}
        columns={columns}
      />
    </div>
  );
};

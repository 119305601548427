import { useContext, useEffect } from "react";

import { CircularProgress } from "@mui/material";
import { Button, useToast, WizardStepperContext } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { BTC } from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { ReceivingAccountType } from "Contexts/SellBitcoin/types";
import { getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import {
  useCreateInProgressSaleMutation,
  useUpdateSellBitcoinSaleDetails,
} from "Shared/api/hooks/trading";
import { formatCurrency } from "Utils/strings";

import { AmountDisplayWithTooltip, CurrentPriceDisplay } from "../../components/AmountDisplay";
import { sellBitcoinNetworkFailureToastDescription } from "../../helpers";
import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { SellBitcoinForm } from "../components/SellBitcoinForm";

type SellDetailsStepProps = {
  onCancel: () => void;
  onContinue: () => void;
};
export const SaleDetailsStep = ({ onCancel, onContinue }: SellDetailsStepProps) => {
  const { goToNext } = useContext(WizardStepperContext);

  const { bitcoinPrice, sellLimitBTC, currentSaleUuid, availableSaleAmountBTC, maximumSaleAmount } =
    useSellBitcoinStore();
  const orgUuid: string = useSelector(getCurrentOrgId);
  const createSaleInProgress = useCreateInProgressSaleMutation(orgUuid);

  useEffect(() => {
    if (!currentSaleUuid) {
      createSaleInProgress.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSaleUuid]);

  const sellLimitToolTipContent =
    "By default, clients are limited to selling the bitcoin they've purchased from Unchained.";

  const maxTradeAmountToolTipContent = `You may sell up to $${formatCurrency(
    maximumSaleAmount.USD
  )} worth of bitcoin in a single trade.`;

  const IncreaseSellLimitButton = () => (
    <Button
      type="text"
      className="ml-2 text-sm text-primary-600"
      to={"https://unchainedcapital.formstack.com/forms/sell_order_request"}
    >
      Request increase
    </Button>
  );
  const handleUpdateSuccess = () => {
    onContinue();
    goToNext();
  };
  const { enqueueSimpleToast } = useToast();

  const handleUpdateError = () => {
    enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
  };
  const { mutate: updateSellBitcoinSaleDetails, isLoading: isSubmitLoading } =
    useUpdateSellBitcoinSaleDetails({
      orgUuid,
      currentSaleUuid,
      handleOnSuccess: handleUpdateSuccess,
      handleOnError: handleUpdateError,
    });
  const handleNext = (
    bankUuid: string,
    sellingVaultUuid: string,
    saleAmountBtc: string,
    receivingAccountType: ReceivingAccountType
  ) => {
    updateSellBitcoinSaleDetails({
      bankUuid,
      sellingVaultUuid,
      saleAmountBtc,
      receivingAccountType,
    });
  };

  return currentSaleUuid ? (
    <CancelModalWrapper closeStepper={onCancel}>
      <div className="max-h-screen">
        <h2 className="mb-0.5 text-d-sm">Enter details</h2>
        <CurrentPriceDisplay price={bitcoinPrice} />
        <AmountDisplayWithTooltip
          amount={availableSaleAmountBTC}
          label="Bitcoin available to sell"
          tooltipContent={sellLimitToolTipContent}
          currency={BTC}
          labelAddOn={<IncreaseSellLimitButton />}
        />
        <AmountDisplayWithTooltip
          amount={sellLimitBTC}
          label="Maximum trade amount"
          tooltipContent={maxTradeAmountToolTipContent}
          currency={BTC}
        />
      </div>
      <SellBitcoinForm next={handleNext} onCancel={onCancel} isSubmitLoading={isSubmitLoading} />
    </CancelModalWrapper>
  ) : (
    <CircularProgress data-testid="sell-bitcoin-wizard-loading-spinner" color="primary" size={53} />
  );
};

import { useQuery, UseQueryOptions } from "react-query";

import { Notification } from "Specs/v1/getNotifications/200";

import {
  InitNotificationType,
  NotificationObject,
  NotificationsAPI,
  NotificationsResponse,
} from "../notificationsApi";
import { useGetAccount } from "./account";

export const notificationsQueryKeys = {
  vaults: {
    allActiveNotifications: (vaultUuid: string) =>
      ["vaults", vaultUuid, "notifications", "active"] as const,
    allNotifications: (vaultUuid: string) => ["vaults", vaultUuid, "notifications"] as const,
  },
  users: {
    allActiveNotifications: (userUuid: string) =>
      ["users", userUuid, "notifications", "active"] as const,
    allNotifications: (userUuid: string) => ["users", userUuid, "notifications"] as const,
  },
  currentUser: ["currentUser", "notifications"] as const,
};

/**
 *
 * @param vaultUuid - Vault UUID
 * @param all (optional)
 * @returns By default, only active notifications are returned. If `all` is true, all notifications are returned.
 * Which would include dismissed, inactive and expired notifications etc... (subject to change)
 */
export const useVaultNotificationsQuery = (vaultUuid: string, all?: boolean) => {
  const queryKey = all
    ? notificationsQueryKeys.vaults.allNotifications
    : notificationsQueryKeys.vaults.allActiveNotifications;

  return useQuery(queryKey(vaultUuid), () =>
    NotificationsAPI.GetVaultNotifications(vaultUuid, all)
  );
};

interface UserNotificationsQueryParams {
  userUuid: string;
  all?: boolean;
  options?: UseQueryOptions<Notification[]>;
}

export const useUserNotificationsQuery = ({
  userUuid,
  all = false,
  options,
}: UserNotificationsQueryParams) => {
  const queryKey = all
    ? notificationsQueryKeys.users.allNotifications
    : notificationsQueryKeys.users.allActiveNotifications;

  return useQuery(
    queryKey(userUuid),
    () => NotificationsAPI.GetUserNotifications(userUuid, all),
    options
  );
};

const notificationsOrderByActionType = [
  "key_check",
  "sign_transaction",
  "approve_signature_request",
  "broadcast_transaction",
  "sign_legal",
  "deposit_collateral",
  "payment",
  "pending_deposit",
  "set_return_address",
] as InitNotificationType[];

const sortNotifications = (a: NotificationObject, b: NotificationObject) => {
  // sort by action_type using the fixed array
  // notificationsOrderByActionType as a guide
  let indexA = notificationsOrderByActionType.indexOf(a.action_type);
  let indexB = notificationsOrderByActionType.indexOf(b.action_type);

  // any action type not in the list should be deprioritized
  if (indexA === -1) return 1;
  else if (indexB === -1) return -1;

  return indexA - indexB;
};

export const useFlatNotifications = () => {
  const account = useGetAccount();
  return useQuery(notificationsQueryKeys.currentUser, () => NotificationsAPI.ForCurrentUser(), {
    refetchInterval: 1000 * 4 * 60,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
    enabled: !account.data?.isUnchainedAdmin,

    // Convert complex nested structure into a flat array of filtered, ordered notifications
    select: (data: NotificationsResponse) => {
      const currentOrgUuid = account.data?.currentOrg?.uuid;
      const { user, org } = data;

      const userNotifications = user.user || [];
      const orgNotifications = user.org || [];

      const currentOrgNotificationsObj = currentOrgUuid && org[currentOrgUuid];
      const currentOrgNotifications: NotificationObject[] = currentOrgNotificationsObj
        ? Object.values(currentOrgNotificationsObj).reduce((arr, items) => [...arr, ...items], [])
        : [];

      return [...userNotifications, ...orgNotifications, ...currentOrgNotifications].sort(
        sortNotifications
      );
    },
  });
};

import { KeyAPI } from "Shared/api/keyApi";

import { setKeyIndexStatusAction } from "./keyIndexActions";

export const SET_KEY_BY_UUID = "SET_KEY_SHOW";
export const SET_KEY_SHOW_CHECK_ENABLED = "SET_KEY_SHOW_CHECK_ENABLED";
export const SET_KEY_SHOW_NAME = "SET_KEY_SHOW_NAME";
export const SET_KEY_SHOW_NEW_NAME = "SET_KEY_SHOW_NEW_NAME";

/**
 * Set user key
 * @param userKey - User's account key
 */
const setUserKeyAction = (userKey, uuid) => {
  return async dispatch => {
    dispatch({
      type: SET_KEY_BY_UUID,
      payload: { userKey, uuid },
    });
  };
};

/**
 * Set whether user key check is enabled/disabled.
 * @param value - Value of 'check_enabled'
 */
export const setUserKeyCheckEnabledAction = (enabled, uuid) => {
  return async dispatch => {
    dispatch({
      type: SET_KEY_SHOW_CHECK_ENABLED,
      payload: { check_enabled: enabled, uuid },
    });
  };
};

/**
 * Set Key Name action in Show Key Flow
 * @param name - User input key name, or generated w/ the suggestion generator
 */
export const setKeyNameAction = (name, uuid) => ({
  type: SET_KEY_SHOW_NAME,
  payload: { name, uuid },
});

/**
 * Set Key New Name action in Show Key Flow
 * @param name - User input key name, or generated w/ the suggestion generator
 */
export const setKeyNewNameAction = (name, uuid) => ({
  type: SET_KEY_SHOW_NEW_NAME,
  payload: { name, uuid },
});

export const getKeyAction = uuid => {
  return async dispatch => {
    if (uuid === undefined) {
      return;
    }
    try {
      const userKey = await KeyAPI.Get(uuid);
      dispatch(setUserKeyAction(userKey, uuid));
      return userKey;
    } catch (e) {
      throw e;
    }
  };
};

import { LockUser200, UnlockUser200 } from "Specs/v1";
import {
  AccountPersonalOrg,
  GetAccount200,
  OrgMember,
  User,
  Verification,
} from "Specs/v1/getAccount/200";

import { API, API_V2 } from "./api";

export class UserAPI {
  static GetAccount = async () => {
    const response = await API.Get<GetAccount200>(`/account`);
    const { personal_org, ...rest } = response.data;
    return {
      personalOrg: personal_org,
      ...rest,
    };
  };

  static UpdateNote = async (uuid, note) => {
    const response = await API.Put<{ message: string }>(`/users/${uuid}/note`, note);
    const { message } = response.data;
    return { message };
  };

  static ChangeName = async (uuid, name) => {
    const response = await API.Post<{ message: string }>(`/users/${uuid}/name`, { name });
    const { message } = response.data;
    return { message };
  };

  static ChangeCodename = async (uuid, codename) => {
    const response = await API.Post<{ message: string }>(`/users/${uuid}/codename`, { codename });
    const { message } = response.data;
    return { message };
  };

  static GetUser = async uuid => {
    type FullUser = User & {
      verification: Verification;
      has_otp: boolean;
      created_at: string;
      email: string;
      phone: string;
    };
    const response = await API.Get<{
      user: FullUser;
      personal_org: AccountPersonalOrg;
      memberships: OrgMember[];
    }>(`/users/${uuid}`);
    const { user, personal_org, memberships } = response.data;
    return {
      user: {
        ...user,
        memberships,
      },
      personalOrg: personal_org,
    };
  };

  static GetLenders = async () => {
    const response = await API.Get<{ lenders: object[] }>("/lenders");
    const { lenders } = response.data;
    return lenders;
  };

  static ResendConfirmationEmail = async uuid => {
    const response = await API.Post(`/users/${uuid}/resend-confirmation-email`);
    return response.data;
  };

  static Search = async (q = "", page = 1, perPage = 20) => {
    const params = { q, page, per_page: perPage };
    const response = await API.Get<{
      users: object[];
      total: number;
      total_pages: number;
      per_page: number;
    }>(`/users`, params);
    const { users, total, total_pages, per_page } = response.data;
    return { users, total, totalPages: total_pages, perPage: per_page };
  };

  static Lock = async (uuid: string) => {
    const response = await API.Post<LockUser200>(`/users/${uuid}/lock`);
    return response.data;
  };

  static Unlock = async (uuid: string) => {
    const response = await API.Post<UnlockUser200>(`/users/${uuid}/unlock`);
    return response.data;
  };

  static ChangePhone = async (phone: string) => {
    const response = await API.Post<{ message: string }>("/credentials/phone/change", { phone });
    return response.data;
  };

  static UpdateFCPNote = async (uuid, fcpNote: string) => {
    const response = await API_V2.Put<{ fcpNote: string }>(`/users/${uuid}/fcp_note`, fcpNote);
    return response.data;
  };

  static UpdateCRR = async (uuid, data: { crrScore?: number; crrIdentifier?: string }) => {
    const response = await API_V2.Put<{ crrScore: number; crrIdentifier: string }>(
      `/users/${uuid}/crr`,
      data
    );
    return response.data;
  };

  static ToggleSignature = async (uuid: string, data: { signature: boolean }) => {
    const response = await API_V2.Post<{ signature: string }>(`/users/${uuid}/signature`, data);
    return response.data;
  };
}

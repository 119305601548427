import { ToastProps, useToast } from "@unchained/component-library";

import { useNavigate } from "Components/Link";

export type ErrorToastOptions = {
  redirectTo?: string;
  persist?: boolean;
  dismissable?: boolean;
} & Partial<ToastProps>;
const defaultUseToastOptions: ErrorToastOptions = {
  title: "Something went wrong",
  description: "Please try again or let us know if the problem persists.",
  type: "error",
  persist: true,
  dismissable: true,
};

/** Useful when catching errors.
 * 1. Logs the passed error,
 * 2. renders an error toast (with configurable title/description), and
 * 3. optionally navigates.
 *
 * Usage:
 *   const showErrorToast = useErrorToast();
 *   somePromise().catch(showErrorToast);
 *   // or: somePromise().catch(err => showErrorToast(err, { title: 'Change me', redirectTo: '/home' }));
 **/
export const useErrorToast = () => {
  const { enqueueSimpleToast } = useToast();
  const navigate = useNavigate();

  return (error?: unknown, passedOptions: ErrorToastOptions = {}): null => {
    const { redirectTo, ...options } = { ...defaultUseToastOptions, ...passedOptions };

    if (process.env.NODE_ENV !== "test" && error) {
      // No reason to log this in tests
      console.error(error);
    }

    enqueueSimpleToast(options);

    if (redirectTo) navigate(redirectTo);

    return null;
  };
};

export const useSuccessToast = () => {
  const { enqueueSimpleToast } = useToast();
  const navigate = useNavigate();

  return (data: { title: string; description: string }, redirectTo?: string) => {
    const title = data.title || "Operation successful";
    const description = data.description || "The API call completed successfully.";

    enqueueSimpleToast({
      type: "success",
      title: title,
      description,
      dismissable: true,
      persist: false,
    });

    if (redirectTo) navigate(redirectTo);

    return null;
  };
};

/**
 * Following the basic pattern whereby the API returns an object with a `message` on success,
 * this hook will render a toast with the message and optionally navigate to a new page.
 * By default, this toast will have a title of "Operation successful" and a description of
 * "The API call completed successfully.". Both of these can be overridden.
 *
 * * Usage:
 *   const showApiSuccessToast = useApiSuccessToast();
 *   someApiCall().then(showApiSuccessToast);
 *   // or: someApiCall().then(data => showApiSuccessToast(data, "/home"));
 *   // or: someApiCall().then(data => showApiSuccessToast({ title: 'You got it!', description: 'Loan found' }, `/loans/${data.loan.uuid}`));
 */
export const useApiSuccessToast = () => {
  const showSuccessToast = useSuccessToast();

  return (
    data: { message?: string; title?: string; description?: string } = {},
    redirectTo?: string
  ) =>
    showSuccessToast(
      { description: data.description || data.message, title: data.title },
      redirectTo
    );
};

/** Returns two typical toasts for handling API error and success states more cleanly */
export const useEasyToasts = () => {
  const showErrorToast = useErrorToast();
  const showApiSuccessToast = useApiSuccessToast();
  return { showErrorToast, showApiSuccessToast };
};

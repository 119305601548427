import { ReactNode, useCallback, useMemo } from "react";

import { ExtendedPublicKey, getMaskedDerivation, Network } from "@caravan/bitcoin";
import {
  getPolicyTemplateFromWalletConfig,
  KeyOrigin,
  MultisigWalletConfig,
} from "@caravan/wallets";
import {
  DownloadCloud,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";

import { Link } from "Components/Link";
import { InstructionTable } from "Components/Shared/Elements/InstructionList";
import { useGetWalletConfig } from "Shared/api";
import { WalletUuid } from "Shared/api/walletApi";
import { AppModalManager } from "Shared/components/Modals";
import { GetWalletConfig200 } from "Specs/v1";
import { downloadTextFile } from "Utils/download";

const OriginText = ({ origin }: { origin: KeyOrigin }) => {
  let path = origin.bip32Path || "";
  if (path[0] === "m") {
    path = origin.bip32Path.slice(1);
  }

  return (
    <span>
      [{origin.xfp}
      <b>{path}</b>]{origin.xpub}
    </span>
  );
};

export const WalletConfigInfo = ({
  walletConfig,
  withUuidAndPolicyRows,
}: {
  walletConfig: GetWalletConfig200;
  withUuidAndPolicyRows?: boolean;
}) => {
  const policyTemplate = useMemo(
    () => getPolicyTemplateFromWalletConfig(walletConfig as MultisigWalletConfig),
    [walletConfig]
  );
  const keys = useMemo(() => {
    return walletConfig.extendedPublicKeys
      .sort((a, b) => a.xpub.localeCompare(b.xpub))
      .map(key => {
        const xpub = ExtendedPublicKey.fromBase58(key.xpub);
        xpub.setNetwork(walletConfig.network as Network);
        const origin = new KeyOrigin({
          xfp: key.xfp,
          xpub: xpub.toBase58(),
          // makes sure to support case where derivation is "unknown"
          // and we want to mask it
          bip32Path: getMaskedDerivation(key),
          network: walletConfig.network,
        });
        return {
          name: key.name,
          origin: <OriginText origin={origin} />,
        };
      });
  }, [walletConfig.extendedPublicKeys, walletConfig.network]);

  const uuidPolicyRows = [
    {
      header: "Wallet UUID",
      content: walletConfig.uuid,
    },
    {
      header: "Policy Template",
      content: policyTemplate,
    },
  ];

  const pathXpubRows = keys.map(key => {
    return { header: key.name, content: key.origin };
  });

  return (
    <>
      <p>Each key is presented in a serialized format:</p>
      <br />
      <p>
        [root fingerprint<b>/derivation path</b>]extended public key
      </p>
      <br />
      {withUuidAndPolicyRows && <InstructionTable rows={uuidPolicyRows} />}
      <InstructionTable rows={pathXpubRows} className="my-3" />
    </>
  );
};

export const WalletConfigInfoModal = ({
  walletUuid,
  title = "Wallet configuration information",
  text,
}: {
  walletUuid: WalletUuid;
  title?: string;
  text?: string | ReactNode;
}) => {
  const walletConfig = useGetWalletConfig(walletUuid);
  let subtitle = text;
  if (!text) {
    subtitle = (
      <>
        This <b>2 of 3 multisig</b> wallet uses a <b>P2SH address type</b>. With your keys, this
        information allows you to access your wallet in{" "}
        <Link to="https://help.unchained.com/can-i-import-my-vault-into-third-party-wallets">
          external software
        </Link>
        . While your funds cannot be moved without keys, this information can reveal your bitcoin
        balance. Download and store this file in a{" "}
        <Link to="https://help.unchained.com/how-to-store-bitcoin-multisig-wallet-configuration-file">
          secure location
        </Link>
        .
      </>
    );
  }

  const Content = () => {
    if (walletConfig.isLoading)
      return (
        <div className="my-10">
          <Loader />
        </div>
      );
    return <WalletConfigInfo walletConfig={walletConfig.data} />;
  };

  const handleDownload = useCallback(() => {
    const body = JSON.stringify(walletConfig.data, null, 2);
    const filename = `wallet-config-${walletConfig.data.name}.json`;
    downloadTextFile(body, filename);
  }, [walletConfig.data]);

  return (
    <Modal onDismiss={() => AppModalManager.close()} className="max-w-none md:w-[44.6rem]">
      <ModalHeader>
        <ModalTitle subtitle={subtitle}>{title}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Content />
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: (
              <>
                <DownloadCloud className="mr-2" /> Download file
              </>
            ),
            onClick: handleDownload,
            disabled: walletConfig.isLoading,
          },
        ]}
      />
    </Modal>
  );
};

import { GetUserNotifications200 } from "Specs/v1";
import { GetNotifications200, Notification } from "Specs/v1/getNotifications/200";
import { ListNotifications200 } from "Specs/v2";

import { API, API_V2 } from "./api";

export type NotificationType =
  | "accept_org_membership"
  | "approve_btc_transaction"
  | "approve_signature_request"
  | "broadcast_transaction"
  | "deposit_collateral"
  | "key_check"
  | "past_due_payment"
  | "pending_deposit"
  | "provide_ira_info"
  | "provide_profile_info"
  | "set_return_address"
  | "sign_btc_transaction"
  | "sign_ira_contract"
  | "sign_legal"
  | "sign_transaction"
  | "upcoming_payment";

// These two additional types come from the API, but are translated into different type keys in the frontend
// (see the getNotificationItem function in src/components/Layout/Nav/NotificationItems/index.ts)
export type InitNotificationType = NotificationType | "payment" | "provide_basic_info";
export type NotificationModelType = "org" | "user" | "account_key" | "loan" | "vault";
export type NotificationObject = { action_type: InitNotificationType } & object;
export type NotificationMap = Record<NotificationModelType, NotificationObject[]>;
export type NotificationsResponse = {
  user: {
    org: NotificationObject[];
    user: NotificationObject[];
  };
  org: Record<string, NotificationMap>;
};

export class NotificationsAPI {
  static ForCurrentUser = async () => {
    const response = await API.Get<NotificationsResponse>(`/notifications`);
    // @ts-ignore
    const { user, org } = response.data;
    return { user, org };
  };

  static DismissVaultNotification = async (
    vaultUuid: string,
    notificationUuid: string
  ): Promise<"success"> => {
    await API.Delete<null>(`/vaults/${vaultUuid}/notifications/${notificationUuid}`);
    return "success";
  };

  static GetVaultNotifications = async (
    vaultUuid: string,
    all?: boolean
  ): Promise<Notification[]> => {
    const response = await API.Get<GetNotifications200>(
      `/vaults/${vaultUuid}/notifications?${all ? "" : "active=true"}`
    );

    return response.data["notifications"];
  };

  static DismissUserNotification = async (
    userUuid: string,
    notificationUuid: string
  ): Promise<"success"> => {
    await API.Delete<null>(`/users/${userUuid}/notifications/${notificationUuid}`);
    return "success";
  };

  static GetUserNotifications = async (
    userUuid: string,
    all?: boolean
  ): Promise<Notification[]> => {
    const response = await API.Get<GetUserNotifications200>(
      `/users/${userUuid}/notifications?${all ? "" : "active=true"}`
    );
    return response.data["notifications"];
  };

  static GetV2Notifications = async (orgUuid?: string): Promise<ListNotifications200> => {
    const resp = await API_V2.Get<ListNotifications200>(
      `/notifications${orgUuid ? `?org_uuid=${orgUuid}` : ""}`
    );
    return resp.data;
  };

  static PutV2NotificationState = async (
    uuid: string,
    state: ListNotifications200["notifications"][0]["state"]
  ) => {
    const resp = await API_V2.Put<null>(`/notifications/${uuid}/state`, `${state}`, {
      "Content-Type": "application/json",
    });
    return resp;
  };
}

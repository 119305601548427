import { CompleteOrg } from "@unchained/api-specs/trefoil/v1/getOrg/200";

import amex from "Assets/images/creditCards/amex.svg";
import diners from "Assets/images/creditCards/diners.svg";
import discover from "Assets/images/creditCards/discover.svg";
import jcb from "Assets/images/creditCards/jcb.svg";
import mastercard from "Assets/images/creditCards/mastercard.svg";
import unionpay from "Assets/images/creditCards/unionpay.svg";
import visa from "Assets/images/creditCards/visa.svg";
import { SKUS } from "Shared/api/v2";

export function mapCreditCardBrandToLogoSvg(brand: string) {
  switch (brand) {
    case "visa":
      return visa;
    case "mastercard":
      return mastercard;
    case "amex":
      return amex;
    case "discover":
      return discover;
    case "diners":
      return diners;
    case "jcb":
      return jcb;
    case "unionpay":
      return unionpay;
    default:
      return null;
  }
}

export function annualFeeSkuForOrg(org: CompleteOrg): string {
  switch (org.account_type) {
    case "trust":
      return SKUS.TRUST_ACCOUNT;
    case "individual":
      return SKUS.INDIVIDUAL_ACCOUNT;
    case "ira":
      return SKUS.IRA_ACCOUNT;
    case "business":
      switch (org.business_tier) {
        case "pro":
          return SKUS.BUSINESS_ACCOUNT_PRO;
        case "enterprise":
          return SKUS.BUSINESS_ACCOUNT_ENTERPRISE;
        case "basic":
          return org.max_members === 2
            ? SKUS.BUSINESS_ACCOUNT_BASIC_TWO
            : SKUS.BUSINESS_ACCOUNT_BASIC_ONE;
        default:
          throw new Error(`Unknown business tier: ${org.business_tier}`);
      }
    default:
      throw new Error(`Unknown account type: ${org.account_type}`);
  }
}

import React, { useContext } from "react";

import { Formik, FormikAddressForm } from "Components/FormFields";
import { ManifestStep } from "Routes/onboard/(shared)";
import {
  initPlaceOfRes,
  OnboardBasicContext,
  useWrapManifestMutation,
  validatePlaceOfRes,
} from "Routes/onboard/(utils)";
import { useUpdateProfileAddress } from "Shared/api";

export const PlaceOfResidence = () => {
  const info = useContext(OnboardBasicContext);
  const { personalOrg } = info;
  const wrapManifestMutation = useWrapManifestMutation({ orgUuid: personalOrg.uuid });
  const updateOrgAddress = wrapManifestMutation(useUpdateProfileAddress(personalOrg.uuid, true));

  return (
    <Formik
      initialValues={initPlaceOfRes(info)}
      onSubmit={values => {
        updateOrgAddress.mutate({ ...values.physical_address, role: "physical" });
      }}
      validationSchema={validatePlaceOfRes}
      debugName="PlaceOfResidence"
    >
      {({ handleSubmit }) => {
        return (
          <ManifestStep
            width="narrow"
            actions={[
              {
                disabled: personalOrg.state === "pending_activation",
                onClick: handleSubmit,
                children: "Continue",
                buttonType: "submit",
              },
            ]}
            title="Home address"
            description="Enter your current physical home address. A PO Box or mailing service cannot be used."
          >
            <FormikAddressForm nestingKey="physical_address" />
          </ManifestStep>
        );
      }}
    </Formik>
  );
};

import {
  CreateSubscriptionRequest,
  ModifySubscriptionRequest,
  Subscription,
} from "Specs/v2/components";

import { ApiType } from "../apiType";

export type SubscriptionItem = CreateSubscriptionRequest["items"][0];

export const SubscriptionAPIFactory = (API: ApiType) => ({
  Create: async (body: CreateSubscriptionRequest) =>
    (await API.Post<Subscription>(`/subscriptions`, body)).data,

  Patch: async (uuid: string, body: ModifySubscriptionRequest) =>
    (await API.MergePatch<Subscription>(`/subscriptions/${uuid}`, body)).data,

  Cancel: async (uuid: string) => (await API.Post(`/subscriptions/${uuid}/cancel`)).data,

  Get: async (uuid: string) => (await API.Get<Subscription>(`/subscriptions/${uuid}`)).data,

  List: async ({ orgId, sku }: { orgId: string; sku?: string }) =>
    (
      await API.Get<{ data: Subscription[] }>(
        `/subscriptions?orgId=${orgId}${sku ? `&sku=${sku}` : ""}`
      )
    ).data,
});

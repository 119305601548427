import { Button } from "@unchained/component-library";

import { useCurrentOrg } from "Redux/selectors/hooks";
import { useIraOrg } from "Shared/api/hooks";
import { AppModalManager } from "Shared/components/Modals";
import { IRA_CONTRACT_STATE } from "Specs/v1/getIraOrg/200";

import { FundIraModal } from "./FundIraModal";

export const FundIraButton = () => {
  const org = useCurrentOrg();

  const iraOrgQuery = useIraOrg(org.uuid, {
    enabled: !!org.uuid,
  });

  const contractState = iraOrgQuery.data?.org?.iraPlan?.contract?.state;

  if (org.state !== "live") return null;
  if (contractState !== IRA_CONTRACT_STATE.SIGNED) return null;

  return (
    <Button
      onClick={() => AppModalManager.open(FundIraModal)}
      className="!bg-primary-600 hover:!border-primary-600 hover:!bg-white hover:!text-primary-600"
      fullWidth
    >
      Fund IRA
    </Button>
  );
};

import { ReactNode } from "react";

import { AlertCircle, HeroIcon, HeroIconSize } from "@unchained/component-library";
import cn from "classnames";

import { Link } from "Components/Link";

type ErrorPageProps = {
  message?: ReactNode;
  size?: HeroIconSize;
  classes?: {
    wrapper?: string;
    icon?: string;
    message?: string;
  };
};

export const ErrorPage = ({ message, size = "md", classes = {} }: ErrorPageProps) => {
  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center gap-4 py-8",
        classes.wrapper
      )}
    >
      <HeroIcon color="red" Icon={AlertCircle} size={size || "md"} className={classes.icon} />
      <p className={classes.message}>
        {message || (
          <span className="text-center">
            <h4 className="mb-2">Something went wrong.</h4>
            Contact our support team at{" "}
            <Link to="mailto:help@unchained.com">help@unchained.com</Link> to get assistance.
          </span>
        )}
      </p>
    </div>
  );
};

import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./DownForMaintenance.module.scss";

const GeneralError = ({ userAuthenticated }) => (
  <div
    className={classnames({
      [styles.publicApp]: !userAuthenticated,
      [styles.privateApp]: userAuthenticated,
    })}
  >
    <h1>Error</h1>
    <p>An error has occurred.</p>
  </div>
);

GeneralError.propTypes = {
  userAuthenticated: PropTypes.bool.isRequired,
};

GeneralError.defaultProps = {
  userAuthenticated: false,
};

export default GeneralError;

import { useState } from "react";

import { PaginationState } from "@tanstack/react-table";
import { AxiosError } from "axios";
import Big from "big.js";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Merge } from "type-fest";

import {
  setSpendingDataAction,
  setSpendingRequestStatusAction,
} from "Actions/transactionActions/spendingActions";
import { setVaultShowStatusAction } from "Actions/vaultActions/vaultShowActions";
import { postApprovalStates } from "Components/Transactions/Spending/SpendingWizard";
import { BankAccountInterface } from "Components/bank_accounts/bankAccountUtils";
import { useBuyBitcoinDispatch } from "Contexts/BuyBitcoin";
import {
  closeOnboardingTradingCard,
  setIsTradingFeatureAvailable,
  setTradeStatements,
} from "Contexts/BuyBitcoin/buyBitcoinActions";
import {
  setCurrentSaleUuid,
  setIsSellTradingFeatureAvailable,
  setSellInfo,
  setSellInProgressData,
} from "Contexts/SellBitcoin/SellBitcoinActions";
import {
  useSellBitcoinDispatch,
  useSellBitcoinStore,
} from "Contexts/SellBitcoin/sellBitcoinContext";
import { ReceivingAccountType, SellStatus } from "Contexts/SellBitcoin/types";
import { getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { TradingAPI } from "Shared/api";
import { REQUEST_STATUS } from "Shared/api/api";
import { VaultAPI } from "Shared/api/vaultApi";
import {
  CreateBatchSettlementRequest,
  CreateLpSettlement200,
  CreateLpSettlementRequest,
  CreateLpTradeRequest,
  SellBitcoinDetailsOperationRequest,
  UpdateLpSettlementRequest,
  UpdateLpTradeRequest,
  UpdateOrgSellLimitRequest,
} from "Specs/v1";
import { TradeBuyActivity, TradeBuyActivityPage } from "Specs/v1/getBuyActivity/200";
import { GetLpTrades200 } from "Specs/v1/getLpTrades/200";
import { GetLpTradesQueryParams } from "Specs/v1/getLpTrades/params/query";
import { TradeSellActivity, TradeSellActivityPage } from "Specs/v1/getSellActivity/200";
import { ClientSellBitcoin } from "Specs/v1/getSellBitcoinOperation/200";
import { SettleableTrade } from "Specs/v1/getSettleableTrades/200";
import { StateAndTerritoryEligibilityStatuses } from "Specs/v1/getStateEligibity/200";

export interface TradeFilter {
  copied?: boolean;
  funded?: boolean;
}

export interface SellFilter {
  copied?: boolean;
  funded?: boolean;
  pending?: boolean;
  completed?: boolean;
  failed?: boolean;
}

interface TradeQueryParams {
  pageIndex?: number;
  pageSize?: number;
  filter?: TradeFilter;
}

interface SellQueryParams {
  pageIndex?: number;
  pageSize?: number;
  filter?: SellFilter;
}

export interface TradeActivityProcessed extends TradeBuyActivity, Record<string, unknown> {
  lifecycleStatus: TradeLifecycleStatus;
}

interface TradeActivityPageOverrides {
  buys: TradeActivityProcessed[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TradeActivityPageProcessed
  extends Merge<TradeBuyActivityPage, TradeActivityPageOverrides> {}

export interface TradeSellActivityProcessed extends TradeSellActivity, Record<string, unknown> {
  lifecycleStatus: TradeLifecycleStatus;
}

interface TradeSellActivityPageOverrides {
  sells: TradeSellActivityProcessed[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TradeSellActivityPageProcessed
  extends Merge<TradeSellActivityPage, TradeSellActivityPageOverrides> {}

export interface SettleableTradeProcessed extends SettleableTrade, Record<string, unknown> {}

export type SettlementVaultsResponse = {
  vaults: SettlementVault[];
};

export const tradesKeys = {
  allBuys: ["buys"] as const,
  listBuys: (params: TradeQueryParams) => [...tradesKeys.allBuys, params],
  allSells: ["sells"] as const,
  listSells: (params: SellQueryParams) => [...tradesKeys.allSells, params],
  settleableTrades: (params?: PaginationState) =>
    params ? ["settleableTrades", params] : ["settleableTrades"],
  settleableTradesSummary: ["settleableTradesSummary"] as const,
  settlementVaults: ["settlementVaults"] as const,
  tradingAgreements: (orgUuid: string) => ["tradingAgreements", orgUuid],
  stateEligibilities: ["stateEligibilities"] as const,
  tradingStatus: (orgUuid?: string) => (orgUuid ? ["tradingStatus", orgUuid] : ["tradingStatus"]),
  tradeStatements: ["tradeStatements"] as const,
  sellBitcoinInfo: (orgUuid: string) => ["sellBitcoinInfo", orgUuid],
  sellBitcoinInProgress: (orgUuid: string, saleUuid: string) => [
    "sellBitcoinInProgress",
    orgUuid,
    saleUuid,
  ],
  orgSellPower: (orgUuid: string) => ["orgSellPower", orgUuid],
  getSaleTx: (productUUID, operationUUID) => ["getSaleTx", productUUID, operationUUID],
  getLPTrade: (lpTradeUuid: string) => ["getLPTrade", lpTradeUuid],
  getLPTrades: (
    page: string,
    perPage: string,
    sortDirection: string,
    total: string,
    status: string
  ) => ["getLPTrades", page, perPage, sortDirection, total, status],

  getLiquidityProviders: ["liquidityProviders"] as const,
  getLpSettlementSummary: (liquidityProvider: string) => [
    "getLpSettlementSummary",
    liquidityProvider,
  ],
  getLpSettlements: ["getLpSettlements"] as const,
  getLpSettlementTrades: (liquidityProvider: string) => [
    "getLpSettlementTrades",
    liquidityProvider,
  ],
};

const transformTradeStatus = (data: TradeActivityPageProcessed): TradeActivityPageProcessed => ({
  ...data,
  buys: data.buys?.map(t => {
    let lifecycleStatus: TradeLifecycleStatus;
    if (t.status === "created") {
      if (t.funded) {
        lifecycleStatus = "funded";
      } else if (t.copied) {
        lifecycleStatus = "copied";
      } else {
        lifecycleStatus = "initiated";
      }
    } else {
      lifecycleStatus = t.status;
    }
    return { ...t, lifecycleStatus };
  }),
});

const transformSellTradeStatus = (
  data: TradeSellActivityPageProcessed
): TradeSellActivityPageProcessed => ({
  ...data,
  sells: data.sells?.map(t => {
    let lifecycleStatus: TradeLifecycleStatus;
    if (t.status === "pending") {
      if (t.copied) {
        lifecycleStatus = "copied";
      } else if (t.funded) {
        lifecycleStatus = "funded";
      } else {
        lifecycleStatus = "pending";
      }
    } else {
      lifecycleStatus = t.status;
    }
    return { ...t, lifecycleStatus };
  }),
});

const useBuyTradesQueryBase = <T = TradeActivityPageProcessed>(
  { pageIndex, pageSize, filter = {} }: TradeQueryParams,
  select: (data: TradeActivityPageProcessed) => T
) => {
  return useQuery(
    tradesKeys.listBuys({ pageIndex, pageSize, filter }),
    () => TradingAPI.GetClientBuyBitcoinActivity(pageIndex, pageSize, filter),
    {
      placeholderData: { buys: [], page: 0, per_page: 10, total: 0, total_pages: 0 },
      select: (data: TradeActivityPageProcessed): T => select(transformTradeStatus(data)),
      keepPreviousData: true,
    }
  );
};

export const useBuyTradesQuery = (params: TradeQueryParams) => {
  return useBuyTradesQueryBase(params, data => data);
};

export const useBuyTradeQuery = (cbbUuid: string, params: TradeQueryParams) => {
  return useBuyTradesQueryBase(params, data => data.buys.find(t => t.cbbUuid === cbbUuid));
};

const useSellTradesQueryBase = <T = TradeSellActivityPageProcessed>(
  { pageIndex, pageSize, filter = {} }: SellQueryParams,
  select: (data: TradeSellActivityPageProcessed) => T
) => {
  return useQuery(
    tradesKeys.listSells({ pageIndex, pageSize, filter }),
    () => TradingAPI.GetClientSellBitcoinActivity(pageIndex, pageSize, filter),
    {
      placeholderData: { sells: [], page: 0, per_page: 10, total: 0, total_pages: 0 },
      select: (data: TradeSellActivityPageProcessed): T => select(transformSellTradeStatus(data)),
      keepPreviousData: true,
    }
  );
};

export const useSellTradesQuery = (params: SellQueryParams) => {
  return useSellTradesQueryBase(params, data => data);
};

export const useSellTradeQuery = (csbUuid: string, params: SellQueryParams) => {
  return useSellTradesQueryBase(params, data => data.sells.find(t => t.csbUuid === csbUuid));
};

export const useBuyCopiedMutation = (copied: boolean, cbbUuid: string) => {
  const queryClient = useQueryClient();
  const mutationFunction = copied
    ? TradingAPI.UpdateBuyBitcoinCopied
    : TradingAPI.UpdateBuyBitcoinUncopied;

  return useMutation<void, Error>(() => mutationFunction(cbbUuid), {
    onSuccess: async () => {
      queryClient.invalidateQueries(tradesKeys.allBuys);
    },
  });
};

export const useSellCopiedMutation = (copied: boolean, csbUuid: string) => {
  const queryClient = useQueryClient();
  const mutationFunction = copied
    ? TradingAPI.UpdateSellBitcoinCopied
    : TradingAPI.UpdateSellBitcoinUncopied;

  return useMutation<void, Error>(() => mutationFunction(csbUuid), {
    onSuccess: async () => {
      queryClient.invalidateQueries(tradesKeys.allSells);
    },
  });
};

export const useBuyFundedMutation = (funded: boolean, cbbUuid: string) => {
  const queryClient = useQueryClient();
  const mutationFunction = funded
    ? TradingAPI.UpdateBuyBitcoinFunded
    : TradingAPI.UpdateBuyBitcoinUnfunded;

  return useMutation(() => mutationFunction(cbbUuid), {
    onSuccess: async () => {
      queryClient.invalidateQueries(tradesKeys.allBuys);
      queryClient.invalidateQueries(tradesKeys.settleableTradesSummary);
      queryClient.invalidateQueries(tradesKeys.settleableTrades());
    },
  });
};

export const useBuyCanceledMutation = (cbbUuid: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => TradingAPI.UpdateBuyBitcoinCanceled(cbbUuid), {
    onSuccess: async () => {
      queryClient.invalidateQueries(tradesKeys.allBuys);
    },
  });
};

export const useSellCanceledMutation = (csbUuid: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => TradingAPI.UpdateSellBitcoinCanceled(csbUuid), {
    onSuccess: async () => {
      queryClient.invalidateQueries(tradesKeys.allSells);
    },
  });
};

export const useSellWireSentMutation = (funded: boolean, csbUuid: string) => {
  const queryClient = useQueryClient();
  const mutationFunction = funded
    ? TradingAPI.UpdateSellBitcoinWireSent
    : TradingAPI.UpdateSellBitcoinUnwireSent;

  return useMutation(() => mutationFunction(csbUuid), {
    onSuccess: async () => {
      queryClient.invalidateQueries(tradesKeys.allSells);
      queryClient.invalidateQueries(tradesKeys.settleableTradesSummary);
      queryClient.invalidateQueries(tradesKeys.settleableTrades());
    },
  });
};

export const useSettleableTrades = (paginationParams: PaginationState) => {
  return useQuery(
    tradesKeys.settleableTrades(paginationParams),
    () => TradingAPI.GetSettleableTrades(paginationParams),
    {
      placeholderData: { buys: [], page: 0, perPage: 10, total: 0, totalPages: 0 },
      select: data => ({ ...data, buys: data.buys as unknown as SettleableTradeProcessed[] }),
      keepPreviousData: true,
    }
  );
};

export const useSettleableTradesSummary = () => {
  return useQuery(tradesKeys.settleableTradesSummary, () =>
    TradingAPI.GetSettleableTradesSummary()
  );
};

export const useSettlementVaults = () => {
  return useQuery(tradesKeys.settlementVaults, () => TradingAPI.GetSettlementVaults(), {
    placeholderData: { vaults: [] },
    select: data => ({
      ...data,
      vaults: data.vaults.sort((a, b) => Big(b.balance).minus(a.balance).toNumber()),
    }),
  });
};

export const useBatchSettlementCreatedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateBatchSettlementRequest) => TradingAPI.CreateBatchSettlement(data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(tradesKeys.allBuys);
        queryClient.invalidateQueries(tradesKeys.settleableTradesSummary);
        queryClient.invalidateQueries(tradesKeys.settleableTrades());
        queryClient.invalidateQueries(tradesKeys.settlementVaults);
      },
    }
  );
};

export const useTradingAgreements = (orgUuid: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    tradesKeys.tradingAgreements(orgUuid),
    () => TradingAPI.GetAllTradingAgreements(orgUuid),
    {
      staleTime: 0,
      enabled: !!orgUuid,
      onError: () => {
        queryClient.setQueryData(tradesKeys.tradingAgreements(orgUuid), []);
      },
    }
  );
};

export const useStateEligibilities = () => {
  return useQuery(tradesKeys.stateEligibilities, () => TradingAPI.GetStateEligibilities());
};

export const useUpdateStateEligibilitiesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ states, territories }: StateAndTerritoryEligibilityStatuses) => {
      return TradingAPI.UpdateStateEligibilities(states, territories);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(tradesKeys.stateEligibilities);
      },
    }
  );
};

export const useHideTradingStatusCardMutation = (orgUuid: string) => {
  const dispatch = useBuyBitcoinDispatch();

  return useMutation(
    () =>
      TradingAPI.UpdateTradingStatus(orgUuid, {
        isCardShowing: false,
      }),
    {
      onSuccess: updatedTradingStatus => {
        dispatch(setIsTradingFeatureAvailable(updatedTradingStatus));
      },
      onError: () => {
        // close card anyways
        dispatch(closeOnboardingTradingCard());
        //  since the request failed next time the user visits the site the card will still be shown
      },
    }
  );
};

export const useTradingStatus = (orgUuid: string) => {
  const dispatch = useBuyBitcoinDispatch();
  const sellDispatch = useSellBitcoinDispatch();

  return useQuery(tradesKeys.tradingStatus(orgUuid), () => TradingAPI.GetTradingStatus(orgUuid), {
    staleTime: 1000 * 10,
    enabled: !!orgUuid,
    onSuccess: data => {
      dispatch(setIsTradingFeatureAvailable(data));
      sellDispatch(
        setIsSellTradingFeatureAvailable(data.isSellBitcoinEnabled, data.isSaleBeingAuthored)
      );
    },
  });
};

export const useTradeStatements = (orgUuid: string, page, perPage) => {
  const dispatch = useBuyBitcoinDispatch();

  return useQuery(
    [tradesKeys.tradeStatements, orgUuid, page, perPage],
    () => TradingAPI.GetTradeStatements(orgUuid, page, perPage),
    {
      staleTime: 0,
      enabled: !!orgUuid,
      onSuccess: data => {
        const tradeData = data?.data?.trades ?? [];
        dispatch(setTradeStatements(tradeData));
      },
      onError: () => {
        dispatch(setTradeStatements([]));
      },
    }
  );
};

export const useTradingSellInfo = (orgUuid: string) => {
  const getJwtAndSellInfo = async () => {
    const { jwt } = await TradingAPI.StartTradingSession(orgUuid);

    const data = await TradingAPI.GetTradingSellInfo(jwt);
    return { data, jwt };
  };

  const dispatch = useSellBitcoinDispatch();

  return useQuery(tradesKeys.sellBitcoinInfo(orgUuid), () => getJwtAndSellInfo(), {
    staleTime: 0,
    enabled: !!orgUuid,
    refetchOnWindowFocus: false,
    onSuccess: responseData => {
      const { data, jwt } = responseData;

      const normalizeBankAccounts = data.usdDestinations?.bankAccounts?.map(bankInfo => {
        return {
          name: bankInfo.name,
          official_name: bankInfo.official_name,
          account_number: bankInfo.account_number,
          routing_number: bankInfo.routing_number,
          account_type: bankInfo.account_type,
          plaidLinkUpdateRequired: bankInfo.plaidLinkUpdateRequired,
          plaid_verification_status: bankInfo.plaid_verification_status,
          identity_verified: bankInfo.identity_verified,
          isLegacy: false, // no legacy accounts should be returned to the frontend
          state: bankInfo.state,
          uuid: bankInfo.uuid,
          denied_reason: bankInfo.denied_reason,
          institution_name: bankInfo.institution_name,
        } as BankAccountInterface;
      });
      dispatch(
        setSellInfo({
          bitcoinPrice: data.bitcoinPrice,
          sources: data.btcSources,
          sellStatus: data.status as SellStatus,
          minimumSaleAmount: {
            BTC: data.minimumSellAmountBtc,
            USD: Big(data.minimumSellAmountBtc).mul(data.bitcoinPrice).toString(),
          },
          maximumSaleAmount: {
            BTC: data.maximumSellAmountBtc,
            USD: Big(data.maximumSellAmountBtc).mul(data.bitcoinPrice).toString(),
          },
          feeRates: data.fees,
          bankAccounts: normalizeBankAccounts,
          currentSaleUuid: data.sellUuid,
          offlineStatus: {
            title: data.offlineStatus.title,
            description: data.offlineStatus.description,
          },
          sellLimitBTC: data.maximumSellAmountBtc,
          availableSaleAmountBTC: data.availableSellAmountBtc,
          jwt: jwt,
          cashBalanceUsd: data.usdDestinations?.cashBalanceUsd,
        })
      );
    },
  });
};

const normalizeSellInProgressData = (data: ClientSellBitcoin) => {
  return {
    sellAmount: data.saleAmount.bitcoinAmount,
    saleAmountBTC: data.saleAmount.bitcoinAmount,
    saleAmountUSD: data.saleAmount.usDollarAmount,
    amountUSDTobeSentToClient: data.amountUsdToBeSentToClient,
    feeAmountUSD: data.unchainedFee,
    transactionFeeSatsVByte: data.miningFeeRateSatsPerVByte,
    transactionFeeAmountUSD: data.miningFeeEstimate.usDollarAmount,
    transactionFeeAmountBTC: data.miningFeeEstimate.bitcoinAmount,
    customerBTCChangeAddress: data.clientChangeAddress,
    unchainedBTCReceiveAddress: data.unchainedDestinationAddress,
    bankName: data.bankName,
    bankLastFourDigits: data.bankLastFourDigits,
    selectedSource: {
      name: data.sellingVault.name,
      id: data.sellingVault.id,
      type: "vault" as const,
    },
    selectedKeys: data.sellingVaultKeys
      .filter(key => key.isSelected)
      .map(key => ({
        isUnchained: key.isUnchainedKey,
        isDelegatedKey: key.isDelegatedCustodianKey,
        name: key.name,
        uuid: key.uuid,
        logoSlug: key.logoSlug,
      })),
    allKeys: data.sellingVaultKeys.map(key => ({
      isUnchained: key.isUnchainedKey,
      isDelegatedKey: key.isDelegatedCustodianKey,
      logoSlug: key.logoSlug,
      name: key.name,
      uuid: key.uuid,
    })),
    transactionUuid: data.btcTransactionId,
    hasReviewedTx: data.hasReviewedTx,
    txVideoVerification: data.txVideoVerification,
    hasConfirmedSignatures: data.hasConfirmedSignatures,
    hasBroadcastTx: data.hasBroadcastTx,
    receivingAccountType: data.receivingAccountType as ReceivingAccountType,
  };
};

const useGetInProgressSale = (currentSaleUuid: string, orgUuid: string, enabled: boolean) => {
  const dispatch = useSellBitcoinDispatch();

  return useQuery(
    tradesKeys.sellBitcoinInProgress(orgUuid, currentSaleUuid),
    () => TradingAPI.GetSellBitcoinInProgress(orgUuid, currentSaleUuid),
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onSuccess: data => {
        dispatch(setSellInProgressData(normalizeSellInProgressData(data)));
      },
    }
  );
};

export const useCreateInProgressSaleMutation = (orgUuid: string) => {
  const dispatch = useSellBitcoinDispatch();

  return useMutation(() => TradingAPI.CreateSellBitcoin(orgUuid), {
    onSuccess: data => {
      dispatch(setCurrentSaleUuid(data.operation_uuid));
    },
  });
};
type useUpdateSellBitcoinProps = {
  orgUuid: string;
  currentSaleUuid: string;
  handleOnSuccess: () => void;
  handleOnError: () => void;
};
export const useUpdateSellBitcoinSaleDetails = ({
  orgUuid,
  currentSaleUuid,
  handleOnSuccess,
  handleOnError,
}: useUpdateSellBitcoinProps) => {
  const dispatch = useSellBitcoinDispatch();

  return useMutation(
    (saleDetails: SellBitcoinDetailsOperationRequest) =>
      TradingAPI.UpdateSellBitcoinSaleDetails(orgUuid, currentSaleUuid, saleDetails),
    {
      onSuccess: data => {
        dispatch(setSellInProgressData(normalizeSellInProgressData(data)));
        handleOnSuccess();
      },
      onError: (err: AxiosError) => {
        handleOnError();
      },
    }
  );
};

export const useUpdateSellBitcoinSelectedKeys = ({
  orgUuid,
  currentSaleUuid,
  handleOnSuccess,
  handleOnError,
}: useUpdateSellBitcoinProps) => {
  const dispatch = useSellBitcoinDispatch();

  return useMutation(
    (selectedKeysUuids: string[]) =>
      TradingAPI.UpdateSellBitcoinSelectedKeys(orgUuid, currentSaleUuid, selectedKeysUuids),
    {
      onSuccess: data => {
        dispatch(setSellInProgressData(normalizeSellInProgressData(data)));
        handleOnSuccess();
      },
      onError: (err: AxiosError) => {
        handleOnError();
      },
    }
  );
};

export const useUpdateSellBitcoinTxReview = ({
  orgUuid,
  currentSaleUuid,
  handleOnSuccess,
  handleOnError,
}: useUpdateSellBitcoinProps) => {
  const dispatch = useSellBitcoinDispatch();

  return useMutation(
    () => TradingAPI.UpdateSellBitcoinTxReview(orgUuid, currentSaleUuid),

    {
      onSuccess: data => {
        dispatch(setSellInProgressData(normalizeSellInProgressData(data)));
        handleOnSuccess();
      },
      onError: (err: AxiosError) => {
        handleOnError();
      },
    }
  );
};
export const useDeleteSellBitcoinOperation = ({
  orgUuid,
  currentSaleUuid,
  handleOnSuccess,
  handleOnError,
}: useUpdateSellBitcoinProps) => {
  return useMutation(
    () => TradingAPI.DeleteSellBitcoinInProgress(orgUuid, currentSaleUuid),

    {
      onSuccess: data => {
        handleOnSuccess();
      },
      onError: err => {
        handleOnError();
      },
    }
  );
};

export const useUpdateSellBitcoinConfirmSignatures = ({
  orgUuid,
  currentSaleUuid,
  handleOnSuccess,
  handleOnError,
}: useUpdateSellBitcoinProps) => {
  const dispatch = useSellBitcoinDispatch();

  return useMutation(
    () => TradingAPI.UpdateSellBitcoinConfirmSignatures(orgUuid, currentSaleUuid),

    {
      onSuccess: data => {
        dispatch(setSellInProgressData(normalizeSellInProgressData(data)));
        handleOnSuccess();
      },
      onError: err => {
        handleOnError();
      },
    }
  );
};
export const useDownloadSellTradeStatementDocument = (
  csbUuid: string,
  orgUuid: string,
  onError: () => void
) => {
  return useMutation(() => TradingAPI.GetSellStatementDocument(orgUuid, csbUuid), {
    onError: () => {
      onError();
    },
  });
};
export const useDownloadTradeStatementsCsv = (
  orgUuid: string,
  onSuccess: () => void,
  onError: () => void
) => {
  return useMutation(
    ({
      tradeType,
      year,
      product,
      productUuid,
    }: {
      tradeType?: string;
      year?: string;
      product?: string;
      productUuid?: string;
    }) => TradingAPI.GetTradeStatementsCSV(orgUuid, tradeType, year, product, productUuid),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        onError();
      },
    }
  );
};

const useGetSaleTx = (productUUID, operationUUID, enabled, onSuccess) => {
  const dispatch = useDispatch();

  return useQuery(
    tradesKeys.getSaleTx(productUUID, operationUUID),
    () => VaultAPI.GetTransaction(productUUID, operationUUID),
    {
      staleTime: 3000,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onSuccess: data => {
        const { operation } = data;
        dispatch(setSpendingDataAction(operation));
        dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.SUCCESS));
        onSuccess(operation);
      },
    }
  );
};

/**
 * Fetch the transaction associated with a sale and determine if the
 * transaction is approved or not. If the transaction has more approvals
 * than the associated quorum or if it is not part of
 * an approval quorum it will be marked as approved.
 */
export const useSaleTx = (uuid: string, operationUUID: string) => {
  // isTransactionApproved can be either true, false or undefined, this way
  // any consumer of the useSaleTx hook can determine if isTransactionApproved
  // has not been set yet and if needed can continue waiting until it is.
  const [isTransactionApproved, setIsTxApproved] = useState<boolean | undefined>(undefined);

  const dispatch = useDispatch();

  const currentOrg = useSelector(getCurrentOrg);
  const quorumSize = currentOrg.quorum_config?.min_approvals || 0;

  const onSuccess = operation => {
    // If the current page behind the sell stepper is the
    // sell's vaultpage, then set its show status to stale
    // so that the page knows to refetch the vault data, which gets
    // the latest status of the related sell bitcoin tx.
    if (window.location.pathname === `/vaults/${uuid}`) {
      dispatch(setVaultShowStatusAction("stale"));
    }
    const { btc_transaction_requests } = operation;
    const txRequest = btc_transaction_requests[0];
    const hasEnoughApprovals = Boolean(
      quorumSize && (txRequest?.approvals || []).length >= quorumSize
    );
    const isApproved = hasEnoughApprovals || postApprovalStates.includes(txRequest?.state);
    setIsTxApproved(isApproved);
  };

  const { isFetched, isError, isLoading, isFetchedAfterMount } = useGetSaleTx(
    uuid,
    operationUUID,
    !!operationUUID,
    onSuccess
  );
  return {
    isTransactionApproved,
    isTransactionFetched: isFetched,
    isTransactionLoading: isLoading,
    isTransactionError: isError,
    isTransactionFetchedAfterMount: isFetchedAfterMount,
  };
};

export enum SELL_STEPS {
  INITIAL_LOADING = "INITIAL_LOADING",
  SALE_DETAILS = "SALE_DETAILS",
  SELECT_KEYS = "SELECT_KEYS",
  REVIEW_TX = "REVIEW_TX",
  APPOVE_TX = "APPROVE_TX",
  SIGN_TX = "SIGN_TX",
  FINALIZE_AND_BROADCAST = "FINALIZE_AND_BROADCAST",
  CONFIRMATION = "CONFIRMATION",
  // offline states
  NO_BITCOIN_AVAILABLE = "NO_BITCOIN_AVAILABLE",
  SELLING_UNAVAILABLE = "SELLING_UNAVAILABLE",
  NETWORK_ERROR = "NETWORK_ERROR",
}
export const useDetermineInitialSellStep = (
  orgUuid: string,
  isApprovalStepRequired: boolean
): SELL_STEPS => {
  const {
    isLoading: isSellInfoLoading,
    isError: isSellInfoError,
    isSuccess: isTradingSellInfoSuccess,
    isFetchedAfterMount: isTradingSellInfoFetchedAfterMount,
  } = useTradingSellInfo(orgUuid);

  const {
    sellStatus,
    currentSaleUuid,
    sellAmount,
    bankName,
    bankLastFourDigits,
    selectedKeys,
    selectedSource,
    hasReviewedTx,
    hasConfirmedSignatures,
    hasBroadcastTx,
    transactionUuid,
    receivingAccountType,
  } = useSellBitcoinStore();

  const isInProgressSaleReadyToBeFeteched =
    !!orgUuid &&
    !!currentSaleUuid &&
    isTradingSellInfoSuccess &&
    isTradingSellInfoFetchedAfterMount;

  const {
    isLoading: isGetInProgressSaleLoading,
    isError: isGetInProgressSaleError,
    isFetchedAfterMount: isGetInProgressSaleFetchedAfterMount,
  } = useGetInProgressSale(currentSaleUuid, orgUuid, isInProgressSaleReadyToBeFeteched);

  const [initialActiveSaleStep, setInitialActiveSaleStep] = useState<SELL_STEPS | undefined>(
    undefined
  );

  // Only set the initial active sale step if it has not been set yet.
  // This way if the hook rerenders it will never set an initial active sale
  // step a second time.
  const setFirstInitialActiveSale = (newInitialActiveSaleStep: SELL_STEPS) => {
    if (!initialActiveSaleStep) {
      setInitialActiveSaleStep(newInitialActiveSaleStep);
    }
  };
  const {
    isTransactionFetched,
    isTransactionLoading,
    isTransactionApproved,
    isTransactionError,
    isTransactionFetchedAfterMount,
  } = useSaleTx(selectedSource?.id, transactionUuid);
  if (isSellInfoError || isGetInProgressSaleError || isTransactionError) {
    return SELL_STEPS.NETWORK_ERROR;
  }

  const saleHasNotBeenInitiated = sellStatus === SellStatus.ONLINE && !currentSaleUuid;
  const isInNeedOfFetchingSaleInProgress = currentSaleUuid && sellStatus === SellStatus.ONLINE;

  const userHasCompletedSaleDetailsStep =
    sellAmount &&
    ((bankName && bankLastFourDigits) ||
      receivingAccountType === ReceivingAccountType.CASH_BALANCE) &&
    selectedSource.id;
  const userHasCompletedSelectKeysStep =
    userHasCompletedSaleDetailsStep && selectedKeys.length === 2;
  const userHasCompletedReviewTxStep = userHasCompletedSelectKeysStep && hasReviewedTx;
  const userHasCompletedApproveTxStep = userHasCompletedReviewTxStep;
  const userHasCompletedConfirmTXStep =
    ((userHasCompletedReviewTxStep && !isApprovalStepRequired) ||
      (userHasCompletedApproveTxStep && isApprovalStepRequired)) &&
    hasConfirmedSignatures;
  const userHasCompletedBroadcastStep = userHasCompletedConfirmTXStep && hasBroadcastTx;
  const hasTransactionApprovalBeenSet = isTransactionApproved !== undefined;

  if (
    isSellInfoLoading ||
    isGetInProgressSaleLoading ||
    !sellStatus ||
    !isTradingSellInfoFetchedAfterMount ||
    (isInNeedOfFetchingSaleInProgress && !isGetInProgressSaleFetchedAfterMount) ||
    (!isTransactionFetchedAfterMount && isTransactionLoading) ||
    (isTransactionFetched && !hasTransactionApprovalBeenSet)
  ) {
    return SELL_STEPS.INITIAL_LOADING;
  }

  if ([SellStatus.OFFLINE, SellStatus.NO_BANKS, SellStatus.NO_VAULTS].includes(sellStatus)) {
    return SELL_STEPS.SELLING_UNAVAILABLE;
  } else if (sellStatus === SellStatus.NO_SELL_POWER) {
    return SELL_STEPS.NO_BITCOIN_AVAILABLE;
  } else if (saleHasNotBeenInitiated) {
    // Selling is online but the user does not have a currentSaleUuid
    // therefore navigate to the SALE_DETAILS step where
    // the user will send a POST request to create a currentSaleUuid
    // and initiate the sell process.
    return SELL_STEPS.SALE_DETAILS;
  } else if (isGetInProgressSaleFetchedAfterMount) {
    // Place the user on the latest step in the sell stepper
    // that has yet to be completed.
    // Therefore in succession look at the furthest possible step
    // and check if they have already completed said step, if they
    // have place them on the next step.
    // If they have not completed any steps yet place them on the
    // first active sell step, SALE_DETAILS.
    if (userHasCompletedBroadcastStep) {
      setFirstInitialActiveSale(SELL_STEPS.CONFIRMATION);
    } else if (!!userHasCompletedConfirmTXStep) {
      setFirstInitialActiveSale(SELL_STEPS.FINALIZE_AND_BROADCAST);
    } else if (userHasCompletedReviewTxStep) {
      if (!isApprovalStepRequired) {
        setFirstInitialActiveSale(SELL_STEPS.SIGN_TX);
      } else {
        if (isTransactionApproved && isTransactionFetchedAfterMount) {
          setFirstInitialActiveSale(SELL_STEPS.SIGN_TX);
        } else {
          setFirstInitialActiveSale(SELL_STEPS.APPOVE_TX);
        }
      }
    } else if (userHasCompletedSelectKeysStep) {
      setFirstInitialActiveSale(SELL_STEPS.REVIEW_TX);
    } else if (userHasCompletedSaleDetailsStep) {
      setFirstInitialActiveSale(SELL_STEPS.SELECT_KEYS);
    } else {
      setFirstInitialActiveSale(SELL_STEPS.SALE_DETAILS);
    }
    return initialActiveSaleStep;
  }
};

export const useGetOrgSellPower = (orgUuid: string) => {
  return useQuery(tradesKeys.orgSellPower(orgUuid), () => TradingAPI.GetOrgSellPower(orgUuid));
};

type useUpdateOrgSellPowerProps = {
  orgUuid: string;
  handleOnSuccess?: () => void;
  handleOnError?: (err: AxiosError) => void;
};
export const useUpdateOrgSellPower = ({
  orgUuid,
  handleOnSuccess,
  handleOnError,
}: useUpdateOrgSellPowerProps) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateOrgSellLimitRequest) => TradingAPI.UpdateOrgSellPower(orgUuid, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(tradesKeys.orgSellPower(orgUuid));

        if (handleOnSuccess) {
          handleOnSuccess();
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

type useGetLPTradeProps = {
  lpTradeUuid?: string;
  handleOnError?: (err: AxiosError) => void;
};
export const useGetLPTrade = ({ lpTradeUuid, handleOnError }: useGetLPTradeProps) => {
  return useQuery(tradesKeys.getLPTrade(lpTradeUuid), () => TradingAPI.GetLPTrade(lpTradeUuid), {
    refetchOnWindowFocus: false,
    enabled: !!lpTradeUuid,
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
  });
};

export const useGetLPTradesQuery = (
  requestParams: GetLpTradesQueryParams,
  onSuccess: (data: GetLpTrades200) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    tradesKeys.getLPTrades(
      requestParams.page,
      requestParams.perPage,
      requestParams.sortDirection,
      requestParams.total,
      requestParams.status
    ),
    () => TradingAPI.GetLPTrades(requestParams),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
      onSuccess: data => {
        onSuccess(data);
      },
    }
  );
};
export const useGetLPTradesMutation = (handleOnError?: (err: AxiosError) => void) => {
  return useMutation(
    (requestParams: GetLpTradesQueryParams) => TradingAPI.GetLPTrades(requestParams),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useGetLiquidityProviders = (handleOnError: (err: AxiosError) => void) => {
  return useQuery(tradesKeys.getLiquidityProviders, () => TradingAPI.GetLiquidityProviders(), {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
  });
};
export const useGetLpSettlementSummary = (
  liquidityProvider: string,
  handleOnError: (err: AxiosError) => void
) => {
  return useQuery(
    tradesKeys.getLpSettlementSummary(liquidityProvider),
    () => TradingAPI.GetLpSettlementSummary({ liquidityProvider: liquidityProvider }),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useGetLpSettlements = (handleOnError: (err: AxiosError) => void) => {
  return useQuery(tradesKeys.getLpSettlements, () => TradingAPI.GetLpSettlements(), {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
  });
};

type CreateLpSettlementErrorResponse = { message: string; status: string };

export const useCreateLpSettlement = (
  lpSettlement: CreateLpSettlementRequest,
  handleOnError: (errMsg?: string) => void,
  onSuccess: (data: CreateLpSettlement200["data"]) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => TradingAPI.CreateLpSettlementRequest(lpSettlement), {
    onError: (err: AxiosError) => {
      if (err.response.status === 400) {
        const errResponse = err.response?.data as CreateLpSettlementErrorResponse;
        handleOnError(errResponse.message);
      } else {
        handleOnError();
      }
    },
    onSuccess: data => {
      onSuccess(data.data);
      queryClient.invalidateQueries("getLPTrades");
      queryClient.invalidateQueries(tradesKeys.getLpSettlements);
    },
  });
};

export const useUpdateBatchSettlement = (
  batchSettlementUuid: string,
  batchSettlementRequestBody: UpdateLpSettlementRequest,
  onSuccess: () => void,
  handleOnError?: (err: AxiosError) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => TradingAPI.UpdateBatchSettlement(batchSettlementUuid, batchSettlementRequestBody),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
      onSuccess: () => {
        onSuccess();
        queryClient.invalidateQueries(tradesKeys.getLpSettlements);
        queryClient.invalidateQueries("getLPTrades");
        queryClient.invalidateQueries("getLpSettlementSummary");
      },
    }
  );
};
type DeleteBatchErrorResponse = { message: string };
export const useDeleteBatchSettlement = (
  batchSettlementUuid: string,
  onSuccess: () => void,
  handleOnError?: (err: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => TradingAPI.DeleteBatchSettlement(batchSettlementUuid), {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        const errorResponse = err.response.data as DeleteBatchErrorResponse;
        handleOnError(errorResponse.message);
      }
    },
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(tradesKeys.getLpSettlements);
      queryClient.invalidateQueries("getLPTrades");
      queryClient.invalidateQueries("getLpSettlementSummary");
    },
  });
};

export const useGetLpSettlementTrades = (
  liquidityProvider: string,
  handleOnError: (err: AxiosError) => void
) => {
  return useQuery(
    tradesKeys.getLpSettlementTrades(liquidityProvider),
    () => TradingAPI.GetLpSettlementTrades(liquidityProvider),
    {
      enabled: !!liquidityProvider,
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export type CreateLpTradeRequestErrors = { errors: Record<string, Record<string, string[]>> };

export const useCreateLpTrade = (
  lpTrade: CreateLpTradeRequest,
  onSuccess: () => void,
  handleOnError?: (err?: CreateLpTradeRequestErrors["errors"]) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => TradingAPI.CreateLPTrade(lpTrade), {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        const reponseErrors = err?.response?.data as CreateLpTradeRequestErrors;
        handleOnError(reponseErrors?.errors);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getLPTrades");
      queryClient.invalidateQueries("getLpSettlementSummary");
      onSuccess();
    },
  });
};

export const useUpdateLpTrade = (
  lpTradeUuid: string,
  lpTradeUpdates: UpdateLpTradeRequest,
  onSuccess: () => void,
  handleOnError?: (err: AxiosError) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => TradingAPI.UpdateLPTrade(lpTradeUuid, lpTradeUpdates), {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getLPTrades");
      queryClient.invalidateQueries("getLpSettlementSummary");
      onSuccess();
    },
  });
};

import { useState } from "react";

import { Grid, Table, TableBody, TableRow } from "@mui/material";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";
import Big from "big.js";
import Papa from "papaparse";

import { ConfirmOnDeviceIcon } from "Components/Shared/Elements/Account/ConfirmAddress/ConfirmOnDeviceIcon";
import { BatchSpendReviewTable } from "Components/Shared/Elements/BatchSpendReviewTable";
import { ExploreAddress } from "Components/Shared/Elements/Crypto/ExploreAddress";
import { AccountNameCell } from "Components/Shared/Elements/Summary/AccountNameCell";
import { CryptoAmountsCell } from "Components/Shared/Elements/Summary/CryptoAmountsCell";
import { OwnerNameCell } from "Components/Shared/Elements/Summary/OwnerNameCell";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import { useMemoizedState } from "Redux/selectors/hooks";
import { Loan, Vault } from "Specs/v1/getAccount/200";
import { cryptoToFiat } from "Utils/currency";
import { formatCurrency } from "Utils/strings";

interface IBatchOutput {
  address: string;
  amount: number;
}

export const getAccountDetails = (operation: { vault?: Vault; loan?: Loan }) => {
  if (operation.vault) {
    if (operation.vault.owner.personal) {
      return { isPersonal: true, product: operation.vault.owner };
    } else {
      return { isPersonal: false, product: operation.vault.owner };
    }
  } else if (operation.loan) {
    if (operation.loan.borrower.personal) {
      return { isPersonal: true, product: operation.loan.borrower };
    } else {
      return { isPersonal: false, product: operation.loan.borrower };
    }
  }
};

const genBatchOutputCsv = (batchOutputs: IBatchOutput[]) => {
  return btoa(
    Papa.unparse(batchOutputs.map(output => ({ Address: output.address, Amount: output.amount })))
  );
};

const ExternalOutputDetails = ({ output, prices }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <SummaryTableCell component="th">To</SummaryTableCell>
          <SummaryTableCell>
            <div>External address</div>
          </SummaryTableCell>
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Address</SummaryTableCell>
          <SummaryTableCell>
            <ExploreAddress address={output.address} unit="BTC" />
          </SummaryTableCell>
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Amount</SummaryTableCell>
          <CryptoAmountsCell
            crypto={`${formatCurrency(output.amount, 8, false, false)} BTC`}
            fiat={`$${formatCurrency(cryptoToFiat(output.amount, "BTC", prices), 2)}`}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ProductOutputDetails = ({ output, prices }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <SummaryTableCell component="th">To</SummaryTableCell>
          <AccountNameCell
            name={output.product.name}
            uuid={output.product.uuid}
            product_type={output.product.product_type}
          />
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Address</SummaryTableCell>
          <SummaryTableCell>
            <ExploreAddress address={output.address} unit="BTC" />
            {output.product.allowed_actions.includes("create_transaction") && (
              <ConfirmOnDeviceIcon address={output.address} />
            )}
          </SummaryTableCell>
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Amount</SummaryTableCell>
          <CryptoAmountsCell
            crypto={`${formatCurrency(output.amount, 8, false, false)} BTC`}
            fiat={`$${formatCurrency(cryptoToFiat(output.amount, "BTC", prices), 2)}`}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ChangeOutputDetails = ({
  accountName,
  accountType,
  accountUUID,
  output,
  prices,
  accountDetails: { isPersonal, product },
}) => {
  const orgType = useMemoizedState("account.orgs.current.type");
  const isUnchained = orgType === "unchained";

  return (
    <Table>
      <TableBody>
        <TableRow>
          <SummaryTableCell component="th">From</SummaryTableCell>
          <AccountNameCell name={accountName} uuid={accountUUID} product_type={accountType} />
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Change address</SummaryTableCell>
          <SummaryTableCell>
            <ExploreAddress address={output.address} unit="BTC" />
            {output.product.allowed_actions.includes("create_transaction") && (
              <ConfirmOnDeviceIcon address={output.address} />
            )}
          </SummaryTableCell>
        </TableRow>
        {isUnchained ? (
          <TableRow>
            <SummaryTableCell component="th">Account</SummaryTableCell>
            <OwnerNameCell product={product} isPersonal={isPersonal} />
          </TableRow>
        ) : null}
        <TableRow>
          <SummaryTableCell component="th">Change</SummaryTableCell>
          <CryptoAmountsCell
            crypto={`${formatCurrency(output.amount, 8)} BTC`}
            fiat={`$${formatCurrency(cryptoToFiat(output.amount, "BTC", prices), 2)}`}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

const BatchSpendReviewModal = ({
  open,
  onDismiss,
  batchOutputs,
}: {
  open: boolean;
  onDismiss: () => void;
  batchOutputs: IBatchOutput[];
}) => {
  const data = batchOutputs.map(output => ({
    address: { value: output.address },
    amount: { value: String(output.amount) },
  }));

  return (
    <Modal open={open} onDismiss={onDismiss} maxWidth="md" className="md:w-[45.8rem]">
      <ModalHeader>
        <ModalTitle subtitle="Confirm the addresses and amounts before proceeding.">
          Recipient details
        </ModalTitle>
      </ModalHeader>
      <ModalContent>
        <BatchSpendReviewTable data={data} withSummary />
      </ModalContent>
      <ModalFooter
        className="!mt-0"
        actions={[
          { children: "Close", type: "tertiary", onClick: () => onDismiss() },
          {
            children: [
              <a
                download="batch.csv"
                href={`data:application/octet-stream;charset=utf-8;base64,${genBatchOutputCsv(
                  batchOutputs
                )}`}
              >
                Download .csv file
              </a>,
            ],
            type: "text",
          },
        ]}
      />
    </Modal>
  );
};

const BatchOutputDetails = ({
  batchOutputs,
  prices,
}: {
  batchOutputs: IBatchOutput[];
  prices: any;
}) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const amount: number = batchOutputs.reduce(
    (acc: number, output: { amount: number }) => Big(acc).plus(output.amount).toNumber(),
    0
  );

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <SummaryTableCell component="th">To</SummaryTableCell>
            <SummaryTableCell>
              <div>
                {`${batchOutputs.length} addresses`}
                <Button type="text" onClick={() => setShowReviewModal(true)}>
                  (View)
                </Button>
              </div>
            </SummaryTableCell>
          </TableRow>
          <TableRow>
            <SummaryTableCell component="th">Amount</SummaryTableCell>
            <CryptoAmountsCell
              crypto={`${formatCurrency(amount, 8, false, false)} BTC`}
              fiat={`$${formatCurrency(cryptoToFiat(amount, "BTC", prices), 2)}`}
            />
          </TableRow>
        </TableBody>
      </Table>
      <BatchSpendReviewModal
        open={showReviewModal}
        onDismiss={() => setShowReviewModal(false)}
        batchOutputs={batchOutputs}
      />
    </>
  );
};

export const getOutputBucketsFromTxOutputs = (
  transactionOutputs,
  accountType: string,
  accountUUID: string
) => {
  let changeOutputs = [],
    productOutputs = [],
    externalOutputs = [];
  for (const output of transactionOutputs) {
    const relatedProductExists = Boolean(output.product) && Boolean(output.product.product_type);
    if (
      relatedProductExists &&
      output.product.product_type === accountType &&
      output.product.uuid === accountUUID
    ) {
      changeOutputs.push(output);
    } else if (relatedProductExists) {
      productOutputs.push(output);
    } else {
      externalOutputs.push(output);
    }
  }
  return { changeOutputs, productOutputs, externalOutputs };
};

type OutputDetailProps = {
  accountType: string;
  accountUUID: string;
  accountName: string;
  transactionOutputs: any;
  operation: { vault?: Vault; loan?: Loan };
};
export const OutputDetails = ({
  accountType,
  accountUUID,
  accountName,
  transactionOutputs,
  operation,
}: OutputDetailProps) => {
  const prices = useMemoizedState("crypto.prices.current");

  const accountDetails = getAccountDetails(operation);

  const { changeOutputs, productOutputs, externalOutputs } = getOutputBucketsFromTxOutputs(
    transactionOutputs,
    accountType,
    accountUUID
  );

  return (
    <>
      {productOutputs.length > 0 &&
        productOutputs.map((output, idx) => (
          <Grid item xs={12} key={idx}>
            <ProductOutputDetails output={output} prices={prices} />
          </Grid>
        ))}
      {externalOutputs.length > 1 && (
        <Grid item xs={12}>
          <BatchOutputDetails batchOutputs={externalOutputs} prices={prices} />
        </Grid>
      )}
      {externalOutputs.length === 1 && (
        <Grid item xs={12}>
          <ExternalOutputDetails output={externalOutputs[0]} prices={prices} />
        </Grid>
      )}
      {changeOutputs.length > 0 &&
        changeOutputs.map((output, idx) => (
          <Grid item xs={12} key={idx}>
            <ChangeOutputDetails
              accountType={accountType}
              accountUUID={accountUUID}
              accountName={accountName}
              output={output}
              prices={prices}
              accountDetails={accountDetails}
            />
          </Grid>
        ))}
    </>
  );
};

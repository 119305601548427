import React from "react";

import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";

import { DashboardCard } from "Components/Dashboard/DashboardCard";
import { useIsBuyBitcoinFeatureEnabled } from "Components/TradingDesk/hooks";
import { getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import { useHideTradingStatusCardMutation } from "Shared/api/hooks/trading";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { featureOn } from "Utils/config";

import { BuyAndSellBitcoinButtons } from "../BuyAndSellBitcoinButtons";
import onboardingStyles from "./TradingOnboardingCard.module.scss";
import styles from "./TradingOnboardingCompletedCard.module.scss";

interface TradingOnboardingCompletedCardProps {
  isCloseable?: boolean;
  currentOrg: CompleteOrg;
}

/**
 * Component which displays a card describing steps to start trading.
 * @returns {React.Component}
 */
const TradingOnboardingCompletedCard = ({
  isCloseable = true,
  currentOrg: org,
}: TradingOnboardingCompletedCardProps) => {
  const orgUuid: string = useSelector(getCurrentOrgId);
  const isSellBitcoinFeatureFlagEnabled = featureOn("sell_bitcoin", org) || undefined;

  const hideTradingStatusCardMutation = useHideTradingStatusCardMutation(orgUuid);

  const hideCard = () => {
    hideTradingStatusCardMutation.mutate();
  };
  const { isTradingFeatureAvailable } = useIsBuyBitcoinFeatureEnabled();

  const wireFundsStepText = isSellBitcoinFeatureFlagEnabled
    ? "Wire the funds to our account (buy) or send us your bitcoin (sell)"
    : "Wire funds from your UC-approved bank account";

  const depositedStepText = isSellBitcoinFeatureFlagEnabled
    ? "Bitcoin gets deposited into your vault (buy) or funds are sent to your bank (sell)"
    : "Bitcoin is deposited into your vault";

  return (
    <DashboardCard>
      <div className={styles.headerContainer}>
        <h1 className={styles.title}>Ready to trade!</h1>
        {isCloseable ? (
          <IconButton
            data-testid="closeTradingOnboardingCompletedCard"
            classes={{ root: styles.closeButton }}
            size="medium"
            onClick={hideCard}
          >
            <Close />
          </IconButton>
        ) : null}
      </div>
      <div className="prose">
        <p>
          Trading with Unchained is now enabled on your account. Just a quick reminder of how our
          trading service works:
        </p>
        <ul>
          <li>Execute a trade request</li>
          <li>{wireFundsStepText}</li>
          <li>{depositedStepText}</li>
          <li>
            Learn more{" "}
            <a href={"https://help.unchained.com/how-to-buy-bitcoin-trading-desk"} target="_blank">
              here
            </a>
          </li>
        </ul>
      </div>
      <BuyAndSellBitcoinButtons
        fullWidth={true}
        isTradingFeatureAvailable={isTradingFeatureAvailable}
        className={
          isSellBitcoinFeatureFlagEnabled
            ? "!bg-primary-100 !text-primary-700 hover:!border-primary-600 hover:!bg-primary-600 hover:!text-white"
            : onboardingStyles.buyBitcoinButton
        }
        isSellBitcoinFeatureFlagEnabled={isSellBitcoinFeatureFlagEnabled}
      />
    </DashboardCard>
  );
};

export { TradingOnboardingCompletedCard };

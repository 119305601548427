import React, { useContext, useState } from "react";

import { CloudUpload } from "@mui/icons-material";
import Dropzone from "react-dropzone";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";

import { handleFileDrop } from "./functions";

export const BatchDropzone = () => {
  const { setState } = useContext(WithdrawModalContext);
  const [showFileDropperValidationFeedback, setShowFileDropperValidationFeedback] =
    useState<boolean>(false);

  return (
    <>
      <Dropzone
        multiple={false}
        accept=".csv"
        onDropAccepted={files =>
          handleFileDrop(files, batchData => setState({ batchData, step: 1 }))
        }
        onDropRejected={files => setShowFileDropperValidationFeedback(true)}
        className={`
          flex
          flex-col
          items-center
          rounded-md
          border-2
          border-dashed
          border-gray-500
          py-4
        `}
      >
        <p className="mb-4">Click OR drag-and-drop a CSV file.*</p>
        <CloudUpload />
        <div className=" mt-4 text-xs text-gray-500">Accepted file types: .csv</div>
      </Dropzone>
      {showFileDropperValidationFeedback && (
        <div className="has-danger invalid-feedback">
          File type is not supported. Accepted file types are: .csv
        </div>
      )}
    </>
  );
};

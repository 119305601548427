import { useContext } from "react";

import { WizardStepperContext } from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";

import { Formik, FormikTextField } from "Components/FormFields";
import { useSwitchCurrentOrg } from "Components/Layout/Nav/hooks";
import { useNavigate } from "Components/Link";
import { useLoadingContext } from "Contexts/LoadingContext";
import { ManifestStep } from "Routes/onboard/(shared)";
import { OnboardBasicContext } from "Routes/onboard/(utils)";
import { OrgAPI, orgQueryKeys } from "Shared/api";
import { CompleteOrg, COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";
import { yup } from "Utils/yup";

const { BUSINESS } = COMPLETE_ORG_ACCOUNT_TYPE;

/**
 * Either rendered on the /onboard route or on /onboard/[uuid]
 * If on the former, creates a new org with that name, and then redirects to /onboard/[uuid]
 * If on the latter, updates the name, and moves onward in the flow.
 * */
export const BusinessName = ({ org }: { org?: CompleteOrg }) => {
  const { goToNext } = useContext(WizardStepperContext);
  const { showErrorToast } = useEasyToasts();
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries(orgQueryKeys.show(org?.uuid));
  const loading = useLoadingContext();
  const { from } = useContext(OnboardBasicContext) || {};
  const navigate = useNavigate();
  const switchCurrentOrg = useSwitchCurrentOrg();
  const updateName = useMutation((name: string) => OrgAPI.ChangeName(org?.uuid, name));
  const createOrg = useMutation((name: string) => OrgAPI.Create({ name, accountType: BUSINESS }));

  const updateOrCreate = (values: { name: string }) => {
    const mutation = org ? updateName : createOrg;
    loading.set(true);
    mutation.mutate(values.name, {
      onSuccess: async (resp: { org: { uuid: string } }) => {
        if (org) {
          invalidateQuery();
          loading.set(false);
          goToNext();
        } else {
          const uuid = resp.org?.uuid;
          await switchCurrentOrg(uuid);
          loading.set(false);
          navigate([`/onboard/${uuid}`, from].filter(Boolean).join("?from="));
        }
      },
      onError: err => {
        loading.set(false);
        showErrorToast(err);
      },
    });
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .label("Business name")
      .trefoilMinMax("name")
      .matches(
        /^[_.:'&\-a-zA-Z\d\s]+$/g,
        "Business name cannot contain commas, asterixes, parentheses, or other irregular characters."
      )
      .required(),
  });

  return (
    <Formik
      initialValues={{ name: org?.name || "" }}
      onSubmit={updateOrCreate}
      validationSchema={validationSchema}
      debugName="BusinessName"
    >
      {({ handleSubmit }) => {
        return (
          <ManifestStep
            width="max-w-sm"
            actions={[
              {
                onClick: handleSubmit,
                children: "Continue",
                buttonType: "submit",
              },
            ]}
            title="Name your business"
            description={`${org ? "Change the name of" : "Select a name for"} your organization.`}
          >
            <FormikTextField label="Business name" placeholder="Business name" name="name" />
          </ManifestStep>
        );
      }}
    </Formik>
  );
};

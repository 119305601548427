import React from "react";

import { ErrorOutline } from "@mui/icons-material";
import { ModalFooter, ButtonProps, ModalContent } from "@unchained/component-library";
import cx from "classnames";

import { findAndReplaceTextWithComponent } from "Utils/strings";

import { BuyPositionSummary } from "../components/BuyPositionSummary";
import styles from "./BuyBitcoinOutOfServiceModal.module.scss";
import { TradeModalContainer } from "./TradeModalContainer";

interface BuyBitcoinOutOfServiceModal {
  title: string;
  descriptionText: string;
  buttonText: string;
  onButtonClick: () => void;
  bitcoinPrice: string;
  headerTitle: string;
  secondaryButton?: ButtonProps;
}

/**
 * Component modal that displays the reason a user can not currently access the buy bitcoin feature.
 * @param {string} title - Out of service title.
 * @param {string} descriptionText - Out of service description.
 * @param {string} buttonText - Actionable Button text.
 * @param {function} onButtonClick - On button click callback.
 * @param {number} bitcoinPrice - Current bitcoin price.
 * @param {string} headerTitle - Modal title.
 * @param {ButtonProps | undefined} secondaryButton - Button configs to pass to the Modal Footer to generate another footer button.
 * @returns {React.Component}
 */
const BuyBitcoinOutOfServiceModal = ({
  title,
  descriptionText,
  buttonText = "close",
  onButtonClick = () => {},
  headerTitle = "Buy Bitcoin",
  secondaryButton,
}: BuyBitcoinOutOfServiceModal) => {
  const findAndReplaceClientServicesEmailTextWithATag = () => {
    const email = (
      <a key="email" href="mailto:clientservices@unchained.com">
        clientservices@unchained.com
      </a>
    );

    return findAndReplaceTextWithComponent(email, descriptionText, "clientservices@unchained.com");
  };

  const createFooterButtons = () => {
    // a single close button should be a secondary button type
    const firstButtonType =
      !secondaryButton && buttonText.toLowerCase() === "close"
        ? ("secondary" as const)
        : ("primary" as const);

    const firstButton = {
      type: firstButtonType,
      classes: {
        root: cx(styles.buttonRoot),
      },
      onClick: onButtonClick,
      children: buttonText,
    };
    return secondaryButton ? [firstButton, secondaryButton] : [firstButton];
  };

  const descriptionTextWithATags = findAndReplaceClientServicesEmailTextWithATag();
  return (
    <TradeModalContainer title={headerTitle}>
      <BuyPositionSummary />
      <ModalContent>
        <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
            <ErrorOutline
              color={"primary"}
              fontSize="large"
              classes={{ root: styles.accessTimeRoot }}
            />
            <h2 className={styles.title}>{title}</h2>
          </div>
          <p className={styles.descriptionText}>{descriptionTextWithATags}</p>
        </div>
      </ModalContent>
      <ModalFooter actions={createFooterButtons()} />
    </TradeModalContainer>
  );
};

export { BuyBitcoinOutOfServiceModal };

/* global trackEvent ga */
import _ from "lodash";

import { API } from "./api";

export class KeyAPI {
  static ListForOrg = async orgUUID => {
    const response = await API.Get(`/orgs/${orgUUID}/account_keys`);
    const { account_keys } = response.data;
    if (account_keys.length > 0) {
      const userKeysObj = _.keyBy(account_keys, "uuid");
      return userKeysObj;
    }
    return {};
  };

  static Get = async uuid => {
    const response = await API.Get(`/account_keys/${uuid}`);
    const { account_key } = response.data;
    return account_key;
  };

  static Create = async (orgUUID, data) => {
    const response = await API.Post(`/orgs/${orgUUID}/account_keys`, data);
    trackEvent({
      category: "FUNNEL/KEY",
      action: "created",
      label: data.unit,
    });
    return response.data;
  };

  static Check = async (data, uuid) => {
    const response = await API.Post(`/account_keys/${uuid}/check`, data);
    return response.data;
  };

  static Replace = async (uuid, data) => {
    const response = await API.Post(`/rekeys/${uuid}/replace`, data);
    return response.data;
  };

  static DisableCheck = async uuid => {
    const response = await API.Post(`/account_keys/${uuid}/check/disable`);
    return response.data;
  };

  static EnableCheck = async uuid => {
    const response = await API.Post(`/account_keys/${uuid}/check/enable`);
    return response.data;
  };

  static Rename = async (uuid, data) => {
    const response = await API.Put(`/account_keys/${uuid}`, data);
    return response.data;
  };

  static GetRekey = async uuid => {
    try {
      const response = await API.Get(`/account_keys/${uuid}/rekey`);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 404) {
        return {};
      }
      throw e;
    }
  };

  static ActivateDelegateKey = async accountKeyUUID => {
    const response = await API.Patch(`/account_keys/${accountKeyUUID}/activate-delegate`, {
      type: "delegated_custody",
    });
    return response.data;
  };
}

import { useCurrentOrg } from "./hooks";

type OrgFeature =
  | "sell_bitcoin"
  | "lead_bank_originations"
  | "consumer_lending"
  | "consumer_lending_ca"
  | "unchained_signing_fee"
  | "coldcard"
  | "custom_xpub_upload"
  | "custom_psbt_signing"
  | "hermit"
  | "qa_sign"
  | "locked_for_payment"
  | "cash_balance";

// Return undefined if the currentOrg's feature set has not loaded yet
export function useOrgFeatureOn(name: OrgFeature): boolean | undefined {
  const org = useCurrentOrg();

  if (org.all_features) {
    return Boolean(org.all_features[name]);
  } else {
    return undefined;
  }
}

import { GetSlice200, GetSliceAccountKeys200 } from "Specs/v1";

import { API } from "./api";

export class SliceAPI {
  static GetSlice = async address => {
    const response = await API.Get<GetSlice200>(`/slices/${address}`);
    return response.data;
  };

  static GetAccountKeys = async address => {
    const response = await API.Get<GetSliceAccountKeys200>(`/slices/${address}/account_keys`);
    return response.data;
  };

  static GetProduct = async address => {
    const response = await API.Get(`/slices/${address}/product`);
    return response.data;
  };
}

import { useIsBuyBitcoinFeatureEnabled } from "Components/TradingDesk/hooks";
import { manageOrgTitleMap } from "Routes/manage/OrgManage";
import { useGetAccount } from "Shared/api";
import { useQueryParams } from "Utils/uris";

export const useAccountSettingsLinks = () => {
  const { data } = useGetAccount();

  const { tab: tabInUrl } = useQueryParams();

  const { isTradingFeatureAvailable, hasFetched } = useIsBuyBitcoinFeatureEnabled();

  if (!data || !hasFetched) return { links: [], loaded: false };

  const { currentOrg: org } = data;
  const { account_type: accountType, max_members: maxMembers } = org || {};

  const links = [
    {
      children: manageOrgTitleMap[accountType],
      tabName: "manage",
      show: !(accountType === "individual" && maxMembers <= 1),
      to: "/manage",
    },
    {
      children: "IRA beneficiaries",
      tabName: "beneficiaries",
      show: accountType === "ira",
      to: "/ira-beneficiaries",
    },
    {
      children: "Document center",
      tabName: "documents",
      show: org && (isTradingFeatureAvailable || org.allowed_actions?.includes("view_payments")),
      to: "/documents",
    },
    { to: "/subscriptions", children: "Subscriptions", show: true },
  ].filter(link => link?.show) as {
    children: string;
    tabName: string;
    to: string;
  }[];

  return {
    links,
    loaded: true,
    index: Math.max(
      links.findIndex(t => t.tabName === tabInUrl),
      0
    ),
  };
};

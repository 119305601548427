import { PaginationState } from "@tanstack/react-table";
import { useQuery } from "react-query";

import { VaultMap } from "Components/VaultsView/Create/types";
import { Loan } from "Redux/selectors";
import { LoanAPI, VaultAPI, WalletAPI } from "Shared/api";
import {
  GetLoanBalances200,
  GetProductBtcTransactions200,
  GetVaultBalances200,
  GetWallets200,
} from "Specs/v1";
import { ProductType } from "Specs/v1/getWallets/params/path";

import { useGetPaginatedApiQuery } from "./helpers";

type Product = {
  uuid: string;
  [key: string]: unknown;
};

export const vaultRequestKeys = {
  all: ["vaults"] as const,
  showVault: uuid => [...vaultRequestKeys.all, "show", uuid],
  getBtcTransactions: (uuid, pagination, sortBy, sortOrder) => [
    ...vaultRequestKeys.showVault(uuid),
    "btcTransactions",
    pagination,
    sortBy,
    sortOrder,
  ],
  getWallets: uuid => [...vaultRequestKeys.showVault(uuid), "wallets"],
  getBalances: uuid => [...vaultRequestKeys.showVault(uuid), "balances"],
  getOrgVaults: uuid => [...vaultRequestKeys.all, "org", uuid],
};

export const loanRequestKeys = {
  all: ["loans"] as const,
  showLoan: uuid => [...loanRequestKeys.all, "show", uuid],
  getBtcTransactions: (uuid, pagination, sortBy, sortOrder) => [
    ...loanRequestKeys.showLoan(uuid),
    "btcTransactions",
    pagination,
    sortBy,
    sortOrder,
  ],
  getWallets: uuid => [...loanRequestKeys.showLoan(uuid), "wallets"],
  getBalances: uuid => [...loanRequestKeys.showLoan(uuid), "balances"],
  getOrgLoans: uuid => [...loanRequestKeys.all, "org", uuid],
};

export const useGetProductQuery = (productData: { vault?: Product; loan?: Product }) => {
  let uuid, useProductQuery;
  if (productData.vault) {
    uuid = productData.vault.uuid;
    useProductQuery = useGetVaultQuery;
  } else {
    uuid = productData.loan.uuid;
    useProductQuery = useGetLoanQuery;
  }
  return useProductQuery(uuid);
};

export const useGetVaultQuery = (vaultUuid: string) =>
  useQuery<Product, Error>(vaultRequestKeys.showVault(vaultUuid), () => VaultAPI.Get(vaultUuid));

export const useGetLoanQuery = (loanUuid: string) =>
  useQuery(loanRequestKeys.showLoan(loanUuid), () => LoanAPI.Get(loanUuid));

export const useGetOrgLoans = (orgUuid: string) =>
  useQuery<Record<string, Loan>, Error>(loanRequestKeys.getOrgLoans(orgUuid), () =>
    LoanAPI.ListForOrg(orgUuid)
  );

export const useGetOrgVaults = (orgUuid: string) => {
  return useQuery<VaultMap, Error>(vaultRequestKeys.getOrgVaults(orgUuid), () =>
    VaultAPI.ListForOrg(orgUuid)
  );
};

export const useGetProductBtcTransactions = (
  productType: "Vault" | "Loan",
  uuid: string,
  includeOwnTransferLocations: boolean,
  pagination: PaginationState,
  sortBy: "time" = "time",
  sortOrder: "asc" | "desc" = "desc",
  includeUnbroadcasted = false
) => {
  const api = productType === "Loan" ? LoanAPI : VaultAPI;
  const keys = productType === "Loan" ? loanRequestKeys : vaultRequestKeys;
  return useGetPaginatedApiQuery<GetProductBtcTransactions200>(
    pagination,
    keys.getBtcTransactions(uuid, pagination, sortBy, sortOrder),
    api.GetBtcTransactions,
    uuid,
    sortBy,
    sortOrder,
    includeOwnTransferLocations,
    includeUnbroadcasted
  );
};

export const useGetWallets = (productType: ProductType, uuid: string) => {
  const keys = productType === "loans" ? loanRequestKeys : vaultRequestKeys;
  return useQuery<GetWallets200>(keys.getWallets(uuid), () =>
    WalletAPI.ListForProduct(productType, uuid)
  );
};

export const useGetVaultBalances = (uuid: string) =>
  useQuery<GetVaultBalances200, Error>(vaultRequestKeys.getBalances(uuid), () =>
    VaultAPI.GetBalances(uuid)
  );

export const useGetLoanBalances = (uuid: string) =>
  useQuery<GetLoanBalances200, Error>(loanRequestKeys.getBalances(uuid), () =>
    LoanAPI.GetBalances(uuid)
  );

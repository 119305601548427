import React, { useContext, useEffect } from "react";

import { Modal } from "@unchained/component-library";

import { WithdrawWizard } from "./WithdrawWizard";
import { defaultContextState, WithdrawModalContext, withContext } from "./context";
import { IWithdrawModalContext } from "./types";

// If these values change, they may also need to be updated in
// VaultsView/Show/VaultCloseWithSpend. Due to a quirk in how tailwind
// namespaces its classes, it will not apply a style to string variables defined
// outside of the module containing the component. Because of this, they can't
// be reused.
const stepSizes = {
  DEFAULT: "md:w-[31.25rem]",
  BATCH_SPEND_STEP_1: "md:w-[45.8rem]",
  BATCH_SPEND_STEP_3: "md:w-[28.8rem]",
  BATCH_SPEND_STEP_4: "md:w-[36.3rem]",
  STEP_2: "md:w-[28.8rem]",
  STEP_3: "md:w-[36.3rem]",
};

// If these values change, they may also need to be updated in
// VaultsView/Show/VaultCloseWithSpend. Due to a quirk in how tailwind
// namespaces its classes, it will not apply a style to string variables defined
// outside of the module containing the component. Because of this, they can't
// be reused.
const stepSizer = (
  step: IWithdrawModalContext["step"],
  isBatchSpendFlow: IWithdrawModalContext["isBatchSpendFlow"]
) => {
  let size = stepSizes.DEFAULT;

  if (isBatchSpendFlow) {
    switch (step) {
      case 1:
        size = stepSizes.BATCH_SPEND_STEP_1;
        break;

      case 3:
        size = stepSizes.BATCH_SPEND_STEP_3;
        break;

      case 4:
        size = stepSizes.BATCH_SPEND_STEP_4;
        break;

      default:
        break;
    }
  } else {
    switch (step) {
      case 2:
        size = stepSizes.STEP_2;
        break;

      case 3:
        size = stepSizes.STEP_3;
        break;

      default:
        break;
    }
  }

  return size;
};

export const WithdrawModal = withContext(
  ({ open, onDismiss }: { open: boolean; onDismiss: () => void }) => {
    const context = useContext(WithdrawModalContext);

    useEffect(() => {
      // Reset the context state whenever closing the modal
      if (!open) {
        const initialState = { ...defaultContextState };
        // This timeout is needed to avoid the modal content popping initial on
        // close animation.
        setTimeout(() => context.setState(initialState), 300);
      }
    }, [open]);

    return (
      <Modal
        open={open}
        onDismiss={!context.submittingTransaction && (() => onDismiss())}
        disableClose={context.submittingTransaction}
        className={stepSizer(context.step, context.isBatchSpendFlow)}
        maxWidth="md"
      >
        <WithdrawWizard />
      </Modal>
    );
  }
);

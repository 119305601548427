import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Dropdown, HelperText, InputLabel } from "@unchained/component-library";
import cn from "classnames";
import { useField } from "formik";
import moment from "moment";

import { utcFromDateString } from "Utils/dates";

type FormikSplitDateSelectorType = {
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  name: string;
  label?: string;
  /** Optionally, have the selector process the date into any other format
   * prior to saving it to formik. Otherwise, it's saved to formik as an ISO string */
  prepareDate?: (date: Date) => string | Date;
};

export const yearsAgo = (years: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);
  return date;
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const days = Array.from({ length: 31 }, (_, i) => i + 1);

/**
 * Renders a date selector composed of three dropdowns (month, day, year).
 *
 * Expects to be nested within a formik context where the value under the `name` key (default, "date")
 * is a date object (or undefined). Will then update that date in formik context when a full date is selected, and prefill the
 * fields based on the passed date.
 *
 * Can be given minDate and maxDate as bounds, and can be disabled.
 * */
export const FormikSplitDateSelector = ({
  disabled,
  minDate = yearsAgo(100),
  maxDate = new Date(),
  name = "date",
  prepareDate = (date: Date) => date.toISOString(),
  className,
  label,
}: FormikSplitDateSelectorType) => {
  const [formikDate, meta, helpers] = useField(name);

  let date = formikDate?.value ? utcFromDateString(formikDate.value) : null;

  if (!date?.isValid()) date = null;

  const [max, min] = [maxDate, minDate].map(d => moment(d));
  const [year, setYear] = useState(date?.year() ?? undefined);
  const [month, setMonth] = useState(date?.month() ?? undefined);
  const [day, setDay] = useState(date?.date() ?? undefined);

  useEffect(() => {
    if (date?.isValid()) {
      setYear(date.year());
      setMonth(date.month());
      setDay(date.date());
    } else {
      setYear(undefined);
      setMonth(undefined);
      setDay(undefined);
    }
  }, [date]);

  const numYears = max.year() - min.year();
  const yearOpts = useMemo(
    () => Array.from({ length: numYears + 1 }, (_, i) => max.year() - i),
    [numYears, max]
  );

  const onSelect = useCallback(
    (field: "month" | "year" | "day", value: number) => {
      const set = {
        year: setYear,
        month: setMonth,
        day: setDay,
      }[field];

      set(value);

      const valueObj = { year, month, day } as Record<"year" | "month" | "day", number | undefined>;
      const values = Object.keys(valueObj).reduce(
        (acc, key) => ({
          ...acc,
          [key]: key === field ? value : valueObj[key],
        }),
        {}
      ) as Record<"year" | "month" | "day", number | undefined>;

      const everyValueSelected = Object.values(values).every(v => v !== undefined);

      if (!everyValueSelected) return;

      const date = utcFromDateString(
        // Moment needs values to be padded with zeroes, eg "2021-01-01"
        [
          values.year,
          (values.month + 1).toString().padStart(2, "0"),
          values.day.toString().padStart(2, "0"),
        ].join("-")
      );

      if (!date.isValid()) {
        // The second argument to setTouched tells it not to force a validation, which
        // would erase the explicitly-set error.
        helpers.setTouched(true, false);
        helpers.setError("Please select a valid date.");
      } else if (date.isBefore(min)) {
        helpers.setTouched(true, false);
        helpers.setError(`Please select a date after ${min.format("MMMM D, YYYY")}.`);
      } else if (date.isAfter(max)) {
        helpers.setTouched(true, false);
        helpers.setError(`Please select a date before ${max.format("MMMM D, YYYY")}.`);
      } else {
        helpers.setValue(prepareDate(date.toDate()));
        helpers.setError(undefined);
        setTimeout(() => helpers.setTouched(true), 0);
      }
    },
    [day, helpers, max, min, month, prepareDate, year]
  );

  const content = (
    <>
      <div
        className={cn(
          "col-span-1 grid w-full gap-2.5 sm:grid-cols-3 sm:gap-4",
          className,
          meta.error ? "mb-2" : null
        )}
        data-testid="split-date-selector"
      >
        <Dropdown
          fullWidth
          placeholder="Month"
          options={months.map(label => ({ label, value: months.indexOf(label) }))}
          disabled={disabled}
          selectedLabels={typeof month === "number" ? [months[month]] : []}
          onSelect={opt => onSelect("month", months.indexOf(opt.label))}
          data-testid="month"
        />
        <Dropdown
          fullWidth
          placeholder="Day"
          options={days.map(label => ({
            label: label.toString(),
            value: days.indexOf(label) + 1,
          }))}
          disabled={disabled}
          selectedLabels={day ? [day?.toString()] : []}
          onSelect={opt => onSelect("day", opt.value as number)}
          data-testid="day"
        />
        <Dropdown
          fullWidth
          placeholder="Year"
          options={yearOpts.map(label => ({ label: label.toString(), value: label }))}
          disabled={disabled}
          selectedLabels={year ? [year?.toString()] : []}
          onSelect={opt => onSelect("year", opt.value as number)}
          data-testid="year"
        />
      </div>
      {meta.error && meta.touched && <HelperText error>{meta.error}</HelperText>}
    </>
  );

  return label ? (
    <div className="flex w-full flex-col gap-2">
      <InputLabel>{label}</InputLabel>
      {content}
    </div>
  ) : (
    content
  );
};

import { Component } from "react";

import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { Button, Card, Loader } from "@unchained/component-library";
import { capitalize, get } from "lodash";

import { getCryptoPricesAction } from "Actions/cryptoActions/priceActions";
import {
  getAccountSpendingDataAction,
  resetSpendingWizardAction,
  setNextUnsignedSigRequestAction,
  setSelectedSigRequestAction,
} from "Actions/transactionActions/spendingActions";
import { getVaultShowUserAction } from "Actions/vaultActions/vaultShowActions";
import { PageTitle } from "Components/Layout/Header/Titles/PageTitle";
import { BasicPage } from "Components/Pages";
import { withCurrentOrg } from "Components/Shared/Elements/CurrentOrgWrapper";
import { Wizard } from "Components/Shared/wizard/Wizard";
import {
  spendingOperationSelector,
  spendingSigRequestsSelector,
} from "Redux/selectors/spendingSelectors";
import { TransactionAPI } from "Shared/api";
import { REQUEST_STATUS } from "Shared/api/api";
import { withRouter } from "Shared/components/HOCs";
import { triggerConfirmationModal } from "Shared/components/Modals";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { Verification } from "Specs/v1/getAccount/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { SigRequest } from "Specs/v1/getTransactionRequests/200";
import { hasPermission } from "Utils/acls";
import {
  splitOperationDescription,
  splitOperationType,
  splitOperationTypePlural,
} from "Utils/operationTypes";
import { detableize } from "Utils/strings";

import NotFound from "../../errors/NotFound";
import { ApprovalStep } from "./ApprovalStep";
import { BroadcastStep } from "./BroadcastStep/BroadcastStep";
import { SigningKeyList } from "./SigningStep/SigningKeyList";
import { SpendVerificationVideo } from "./SpendVerificationVideo";
import styles from "./SpendingWizard.module.scss";
import { SpendingWizardTitle } from "./SpendingWizardTitle";
import { VerificationStep } from "./VerificationStep";

interface QuorumConfig {
  min_approvals: number;
}

interface Owner {
  quorum_config?: QuorumConfig;
}

export function sleep(ms = 500) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export interface Operation {
  uuid: string;
  type: string;
  verification: Verification;
  verification_required: boolean;
  state: string;
  btc_transaction_requests: {
    signature_requests: SigRequest[];
    state:
      | "drafted"
      | "ready_to_sign"
      | "partially_signed"
      | "fully_signed"
      | "broadcasted"
      | "canceled"
      | "invalidated"
      | "failed";
    approvals: object[];
  }[];
  vault?: {
    owner?: Owner;
  };
  loan?: {
    owner?: Owner;
  };
  unchained_signature_requested?: boolean;
}

export const postApprovalStates = [
  "ready_to_sign",
  "partially_signed",
  "fully_signed",
  "broadcasted",
];

interface SpendingWizardProps {
  location: {
    pathname: string;
  };
  navigate: (path: string) => void;
  getAccountSpendingData: (...args: any[]) => unknown;
  getCryptoPrices: (...args: any[]) => unknown;
  setSelectedSigRequest: (...args: any[]) => unknown;
  setNextUnsignedSigRequest: (...args: any[]) => unknown;
  resetForm: (...args: any[]) => unknown;
  currentOrgUUID: string;
  status: string;
  sigRequests: SigRequest[];
  signableSigRequests: SigRequest[];
  sigRequestsForOwnedKeys: SigRequest[];
  sigRequestsForViewableKeys: SigRequest[];
  params: {
    uuid: string;
    operation_uuid: string;
  };
  // operations props that get loaded async
  description: string;
  operationType: string;
  operationState: string;
  operationUUID: string;
  verification: Verification;
  verificationRequired: boolean;
  txRequestState: string;
  accountType: string;
  accountUUID: string;
  currentOrg: CompleteOrg;
  operation: Operation;
  spendStatus: string;
  allowedActions: string[];
}

interface SpendingWizardState {
  processingVideo: boolean;
  loadingNewTx: boolean;
  // For the user who completes the approval step, we don't want to immediately forward them on,
  // but rather, we want them to be able to click Next. Given how the logic otherwise works (which
  // step you're on is set by what you've completed), the easiest way to do this seemed to be to just
  // explicitly track whether approval has been completed as part of this current flow.
  hasSteppedPastApproval?: boolean;
}

class SpendingWizardBase extends Component<SpendingWizardProps, SpendingWizardState> {
  _isMounted: boolean;
  constructor(props) {
    super(props);
    this.state = {
      processingVideo: false,
      loadingNewTx: false,
      hasSteppedPastApproval: undefined,
    };
  }

  componentDidMount = async () => {
    const { getCryptoPrices, resetForm, operation } = this.props;
    this._isMounted = true;
    if (!operation.uuid) this.setState({ loadingNewTx: true });
    // make sure the spending data is clear of any previous state
    resetForm();
    await this.getTransactionData();
    await getCryptoPrices();
    this.setState({ hasSteppedPastApproval: this.approvalIsComplete() });
  };

  approvalIsRequired = () => {
    const { currentOrg, operation } = this.props;
    const delegateSignatureRequested =
      operation.btc_transaction_requests[0].signature_requests.some(
        signatureRequest => signatureRequest.account_key.role === "delegate"
      );

    const quorum = currentOrg.quorum_config?.min_approvals || 0;
    const applyToAllTxs = currentOrg.quorum_config?.apply_to_all_txs;
    return (
      quorum &&
      (applyToAllTxs || operation.unchained_signature_requested || delegateSignatureRequested)
    );
  };

  approvalIsComplete = () => {
    const { currentOrg, operation } = this.props;

    if (!operation || !currentOrg) return false;

    const { btc_transaction_requests } = operation;
    const txRequest = btc_transaction_requests[0];
    const quorum = currentOrg.quorum_config?.min_approvals || 0;
    const hasEnoughApprovals = Boolean(quorum && (txRequest?.approvals || []).length >= quorum);
    return (
      !this.approvalIsRequired() ||
      hasEnoughApprovals ||
      postApprovalStates.includes(txRequest.state)
    );
  };

  // The steps arrayed with all relevant information
  allSteps = () => {
    const { currentOrg, operation, verification, verificationRequired } = this.props;
    if (!operation || !currentOrg) return [];

    const { btc_transaction_requests } = operation;
    const txRequest = btc_transaction_requests[0];

    const showApprove = this.approvalIsRequired();
    const showVerify = verificationRequired;

    return [
      {
        name: "Create",
        component: null,
        inWizard: false,
        inStepper: true,
        complete: true,
      },
      {
        name: "Verify",
        component: VerificationStep,
        inWizard: showVerify,
        inStepper: showVerify,
        complete:
          !["pending_processing", "pending_recording"].includes(verification.state) ||
          currentOrg.type == "delegate",
      },
      {
        name: "Approve",
        component: ApprovalStep,
        inWizard: showApprove,
        inStepper: showApprove,
        complete: this.approvalIsComplete() && this.state.hasSteppedPastApproval,
      },
      {
        name: "Sign",
        component: SigningKeyList,
        inWizard: true,
        inStepper: true,
        complete: Boolean(txRequest && ["fully_signed", "broadcasted"].includes(txRequest.state)),
      },
      {
        name: "Broadcast",
        component: BroadcastStep,
        inWizard: true,
        inStepper: true,
        complete: false,
      },
    ];
  };

  // Steps in the stepper, with their index in the stepper
  stepperSteps = () =>
    this.allSteps()
      .filter(step => step.inStepper)
      .map((step, index) => ({ ...step, index }));

  // Steps in the wizard, with their index in the wizard
  wizardSteps = () =>
    this.allSteps()
      .filter(step => step.inWizard)
      .map((step, index) => ({ ...step, index }));

  wizardStep = () => this.wizardSteps().find(step => !step.complete);
  stepperStep = () => this.stepperSteps().find(step => !step.complete);

  async componentDidUpdate(prevProps) {
    if (
      this.props.status === REQUEST_STATUS.SUCCESS &&
      prevProps.txRequestState !== this.props.txRequestState
    ) {
      this.updateWizard();
    }
    if (this.props.params.uuid === undefined || this.props.params.operation_uuid === undefined)
      return;
    if (
      this.props.params.uuid !== prevProps.params.uuid ||
      this.props.params.operation_uuid !== prevProps.params.operation_uuid ||
      (this.props.status === REQUEST_STATUS.STALE && prevProps.status !== REQUEST_STATUS.STALE)
    ) {
      this.setState(
        {
          loadingNewTx: true,
        },
        async () => {
          this.props.resetForm();
          await this.getTransactionData();
          this.setState({ loadingNewTx: false });
        }
      );
    } else if (prevProps.operation.uuid !== this.props.operation.uuid && this.state.loadingNewTx) {
      // update to end loading state if the operation uuid is now available
      this.setState({
        loadingNewTx: false,
      });
    }
  }

  getLocationInfo = () => {
    const { params, location } = this.props;
    const { uuid, operation_uuid } = params;

    const pathArray = location.pathname.split("/");
    const accountTypePlural = pathArray[1];
    const operationTypePlural = pathArray[3];
    const accountType = accountTypePlural.substring(0, accountTypePlural.length - 1);
    const operationType = operationTypePlural.substring(0, operationTypePlural.length - 1);

    return {
      accountType,
      accountTypePlural,
      operationType,
      uuid,
      operationUUID: operation_uuid,
    };
  };

  getSpendInfo = async () => {
    const { navigate } = this.props;
    const { accountType, uuid, operationType, operationUUID } = this.getLocationInfo();
    return (await this.props.getAccountSpendingData(
      accountType,
      uuid,
      operationType,
      operationUUID,
      navigate
    )) as Operation;
  };

  getTransactionData = async () => {
    const { params, status, navigate } = this.props;
    const { uuid } = params;

    try {
      const operation = await this.getSpendInfo();
      if (status === REQUEST_STATUS.SUCCESS) {
        this.updateWizard();
      }
      const { accountTypePlural } = this.getLocationInfo();
      // go back to main product page if operation doesn't exist or isn't active
      if (!operation || !Object.keys(operation).length || operation.state === "canceled") {
        navigate(`/${accountTypePlural}/${uuid}`);
      }

      this.updateWizard();
    } catch (e) {
      console.error(e.message);
    }
  };

  updateWizard = () => {
    const {
      setSelectedSigRequest,
      sigRequests,
      signableSigRequests,
      sigRequestsForOwnedKeys,
      sigRequestsForViewableKeys,
      setNextUnsignedSigRequest,
    } = this.props;

    if (signableSigRequests.length > 0) {
      setNextUnsignedSigRequest();
      return;
    }

    if (sigRequestsForOwnedKeys.length > 0) {
      setSelectedSigRequest(sigRequestsForOwnedKeys[0]);
      return;
    }

    if (sigRequestsForViewableKeys.length > 0) {
      setSelectedSigRequest(sigRequestsForViewableKeys[0]);
      return;
    }

    if (sigRequests.length > 0) {
      setSelectedSigRequest(sigRequests[0]);
    }
  };

  txName = () => {
    return detableize(this.props.operationType).toLowerCase();
  };

  onCancelTransactionClick = () => {
    const { operationType, operationUUID, accountUUID, navigate } = this.props;
    const noun = this.txName();
    const cancelTransaction = async () => {
      const [accountType, operation] = splitOperationTypePlural(operationType);

      await TransactionAPI.DeleteTransaction(accountType, accountUUID, operationUUID, operation);
      navigate(`/${accountType}/${accountUUID}`);
    };

    triggerConfirmationModal({
      title: `Cancel ${noun}`,
      text: `Are you sure you want to cancel this ${noun}?`,
      confirmationText: "Confirm",
      onConfirm: cancelTransaction,
      cancellationText: "Back",
    });
  };

  hasVerification = () => {
    const { verification } = this.props;
    return verification && verification.state !== "pending_recording";
  };

  handleViewVerification = () => {
    const title = capitalize(this.txName());
    AppModalManager.open(() => <SpendVerificationVideo title={title} />);
  };

  showViewButton = () =>
    this.props.verification &&
    this.hasVerification() &&
    this.props.verification?.allowed_actions?.includes("view_verification");

  showRecordButton = () =>
    this.wizardStep()?.name !== "Verify" &&
    !this.props.verificationRequired &&
    !this.hasVerification() &&
    this.props.verification &&
    this.props.verification.allowed_actions.includes("record_verification") &&
    // don't show button on the last step (broadcast)
    this.stepperStep()?.index < this.wizardSteps().length;

  handleProcessVerification = async () => {
    if (!this._isMounted) return;
    this.setState({ processingVideo: true }, async () => {
      const { verification } = await this.getSpendInfo();
      if (!this.hasVerification()) {
        await sleep(1000);
        return this.handleProcessVerification();
      }
      if (this._isMounted) this.setState({ processingVideo: false });
    });
  };

  handleRecordVerification = () => {
    AppModalManager.open(() => (
      <VerificationStep
        isModal
        next={() => this.handleProcessVerification()}
        accountType={this.props.accountType}
      />
    ));
  };

  componentWillUnmount() {
    const { resetForm } = this.props;
    resetForm();
    this._isMounted = false;
  }

  transactionIsDeletable = () => {
    const { accountType, operationType, spendStatus, allowedActions } = this.props;

    if (spendStatus === "Pending confirmation") {
      return false;
    }

    if (
      (accountType === "vault" &&
        ["vault_transaction", "batch_settlement", "vault_sale_transaction"].includes(
          operationType
        )) ||
      accountType === "loan"
    ) {
      return hasPermission(allowedActions, "cancel");
    }

    return false;
  };

  render() {
    const { status, description, txRequestState, operation, currentOrg, params } = this.props;
    const { processingVideo, loadingNewTx, hasSteppedPastApproval } = this.state;
    const { operationType } = splitOperationDescription(description);

    const txRequest = operation.btc_transaction_requests?.[0];

    if (
      status === "pending" ||
      loadingNewTx ||
      !txRequest ||
      !currentOrg ||
      hasSteppedPastApproval === undefined
    ) {
      return <Loader className="h-screen" />;
    } else if (status === "notFound") {
      return <NotFound />;
    }

    return (
      <BasicPage>
        <PageTitle className="mb-6">
          <SpendingWizardTitle
            productType={splitOperationType(operation.type)[0]}
            operationType={splitOperationType(operation.type)
              .map(word => capitalize(word))
              .join(" ")}
            uuid={params.uuid}
            name={get(operation, "vault.name")}
          />
        </PageTitle>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item xs={12} sm={11} md={12} classes={{ item: styles.cardContainer }}>
            <Card>
              <Grid container className={styles.signingWrapper}>
                <Grid item classes={{ item: styles.stepper }}>
                  <Stepper activeStep={this.stepperStep()?.index} alternativeLabel>
                    {this.stepperSteps().map(step => (
                      <Step key={step.name}>
                        <StepLabel
                          StepIconProps={{
                            classes: {
                              active: styles.icon__active,
                              completed: styles.icon__completed,
                            },
                          }}
                        >
                          {step.name}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>

                <Grid item classes={{ item: styles.content }}>
                  <Wizard
                    onNextComponent={() => {
                      if (
                        this.wizardStep()?.name === "Approve" &&
                        !this.state.hasSteppedPastApproval
                      ) {
                        this.setState({ hasSteppedPastApproval: true });
                      }
                    }}
                    manifest={this.wizardSteps()}
                    startIndex={this.wizardStep()?.index}
                  />
                </Grid>

                <div className={styles.buttons}>
                  {this.showViewButton() && (
                    <Button type="secondary" onClick={this.handleViewVerification}>
                      View verification
                    </Button>
                  )}
                  {this.showRecordButton() && (
                    <Button
                      type="secondary"
                      onClick={() => this.handleRecordVerification()}
                      disabled={processingVideo}
                    >
                      {processingVideo ? "Processing video" : "Record verification"}
                    </Button>
                  )}
                  {this.transactionIsDeletable() && (
                    <Button
                      type="destructive"
                      onClick={this.onCancelTransactionClick}
                      disabled={txRequestState === "broadcasted"}
                    >
                      Cancel {operationType.toLowerCase()}
                    </Button>
                  )}
                </div>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </BasicPage>
    );
  }
}

const mapDispatchToProps = {
  getCryptoPrices: getCryptoPricesAction,
  getAccountSpendingData: getAccountSpendingDataAction,
  getVaultShowData: getVaultShowUserAction,
  setSelectedSigRequest: setSelectedSigRequestAction,
  resetForm: resetSpendingWizardAction,
  setNextUnsignedSigRequest: setNextUnsignedSigRequestAction,
};

const mapStateToProps = state => {
  return {
    currentOrgUUID: state.account.orgs.current.uuid,
    currentOrg: state.account.orgs.current,
    status: state.transaction.spending.status,
    ...spendingOperationSelector(state),
    ...spendingSigRequestsSelector(state),
  };
};

export default withCurrentOrg(withRouter(SpendingWizardBase), mapStateToProps, mapDispatchToProps);

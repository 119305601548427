import { UsdWithdrawalStatusEnum } from "Specs/v1/getWithdrawalActivity/200";

export type DropDownSelectionType = {
  value: string;
  label: string;
};

export type StatusOptionType = {
  value: UsdWithdrawalStatusEnum | "none";
  label: string;
};

export enum WithdrawalAdminTypeEnum {
  updater = "updater",
  confirmer = "confirmer",
}

import { useContext, useEffect, useState } from "react";

import { Loader, WizardStepperContext } from "@unchained/component-library";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { useNavigate } from "Components/Link";
import {
  StepColumn,
  StepDescription,
  StepTitle,
  StepTop,
} from "Components/Shared/Layouts/FullPageWizard";
import { useLoadingContext } from "Contexts/LoadingContext";
import { InvoiceReceiptShow } from "Routes/invoices/(shared)/InvoiceReceiptShow";
import { Shipping } from "Routes/invoices/(shared)/ShippingAddressShow";
import { applyCouponCodeToSubscription } from "Routes/invoices/(shared)/utils";
import { useGetAccount } from "Shared/api";
import { Invoice, InvoiceAPI, SubscriptionAPI } from "Shared/api/v2";
import { useOrgSubscriptionBySku } from "Shared/api/v2/hooks/subscriptions";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { CreateSubscriptionRequest, Subscription } from "Specs/v2/components";
import { useEasyToasts } from "Utils/toasts";

import { GetSetObj } from "../(utils)";
import { getSubscriptionItems } from "../[uuid]/(shared)";
import { TrustUserOptionValue } from "../[uuid]/(trust)/payment";
import { ShippingInfo } from "./ShippingAddressStep";

export const CreateOrgSubscriptionAndRedirectToPayment = ({
  org,
  setSelectedPricing,
  userCount,
  shippingInfo,
}: {
  org: CompleteOrg | IraOrg;
  setSelectedPricing: (v: boolean) => void;
  userCount: 1 | 2 | TrustUserOptionValue;
  shippingInfo: GetSetObj<ShippingInfo>;
}) => {
  let { uuid } = useParams<{ uuid: string }>();
  const [body, setBody] = useState<CreateSubscriptionRequest>();
  const loading = useLoadingContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showErrorToast, showApiSuccessToast } = useEasyToasts();

  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const { goTo } = useContext(WizardStepperContext);
  const { user } = useGetAccount()?.data || {};
  const isSignature = user?.signature && (org as CompleteOrg)?.account_type !== "business";

  const existingSubscriptionSku = getSubscriptionItems(org, userCount)[0][0].sku;
  const existingSubscriptionQuery = useOrgSubscriptionBySku(uuid, existingSubscriptionSku);

  const createSubscription = async (req: CreateSubscriptionRequest) => {
    const sub = await SubscriptionAPI.Create(req);
    setSubscription(sub);
    return sub;
  };

  const onContinueClick = async () => {
    loading.set(true);
    if (subscription?.id && subscription.invoices?.length > 0) {
      navigate(`/invoices/${subscription.invoices[0].id}?from=/onboard/${uuid}`);
    } else {
      try {
        const sub = await createSubscription(body);
        if (sub?.id) {
          navigate(`/invoices/${sub.invoices[0].id}?from=/onboard/${uuid}`);
        }
      } catch (e) {
        handleCreateError(e);
      }
    }
  };

  const applyCouponCode = async (couponCode: string) => {
    loading.set(true);
    try {
      let sub;
      if (!subscription) {
        sub = await SubscriptionAPI.Create({ ...body, couponCode });
      } else {
        sub = await applyCouponCodeToSubscription(
          couponCode,
          subscription.invoices[0].id,
          uuid,
          shippingInfo.value
        );
      }

      setSubscription(sub);
      setInvoice(await InvoiceAPI.Get(sub.invoices[0].id));
      setSelectedPricing(true);
      showApiSuccessToast({
        title: "Coupon applied",
        description: "Your cart has been updated.",
      });
    } catch (e) {
      handleCreateError(e);
    }

    loading.set(false);
  };

  const handleCreateError = e => {
    if (e.response?.data?.message?.includes("coupon code")) {
      showErrorToast(e, { title: "Invalid coupon code" });
    } else if (e.response?.data?.message?.includes("customer's location")) {
      showErrorToast(e, { title: "Invalid shipping location" });
    } else {
      showErrorToast(e);
    }

    loading.set(false);
  };

  const cancelAndGoBack = async () => {
    if (subscription?.id) {
      loading.set(true);
      await SubscriptionAPI.Cancel(subscription.id);
      setSubscription(null);
      setInvoice(null);
      loading.set(false);
    }

    setSelectedPricing(false);

    goTo("Hardware & onboarding options", "Concierge Onboarding");
  };

  useEffect(() => {
    if (existingSubscriptionQuery.data?.data?.length > 0) {
      const nonCanceledSubscription = existingSubscriptionQuery.data.data.find(
        sub => !["incomplete_expired", "canceled"].includes(sub.status)
      );
      if (nonCanceledSubscription) {
        SubscriptionAPI.Get(nonCanceledSubscription.id).then(sub => {
          setSelectedPricing(true);
          setSubscription(sub);
          InvoiceAPI.Get(sub.invoices[0].id).then(invoice => {
            setInvoice(invoice);
          });
        });
      }
    }
  }, [existingSubscriptionQuery.data]);

  useEffect(() => {
    const [items, addInvoiceItems] = getSubscriptionItems(org, userCount);
    const isIra = "iraPlan" in org;
    const conciergeSelection = isIra ? org.conciergeOnboarding : org.concierge_onboarding;
    let shipping = shippingInfo.value;
    if (conciergeSelection === false && shippingInfo.value) {
      shippingInfo.set(undefined);
      shipping = undefined;
    }

    setBody({
      orgId: uuid,
      items,
      addInvoiceItems,
      shipping,
    });
  }, [org]);

  return (
    <div className="max-h-screen max-w-4xl">
      <StepColumn
        width="full"
        loading={loading.value}
        actions={[
          {
            children: "Go to checkout",
            onClick: onContinueClick,
            disabled: loading.value,
          },
          {
            children: "Change selections",
            onClick: cancelAndGoBack,
            type: "text",
          },
        ]}
      >
        <div className="flex flex-col gap-8 lg:flex-row">
          <div className="flex-1">
            <InvoiceReceiptShow
              invoice={
                (subscription && invoice
                  ? invoice
                  : {
                      lineItems: [...(body?.items || []), ...(body?.addInvoiceItems || [])],
                      discounts: isSignature ? [{ name: "Signature" }] : [],
                    }) as Invoice
              }
              applyCouponCode={c => applyCouponCode(c)}
            />
            {shippingInfo.value?.address && (
              <Shipping name={shippingInfo.value?.name} address={shippingInfo.value?.address} />
            )}
          </div>
          <div className="flex flex-1 flex-col gap-4">
            <StepTop>
              <StepTitle>Review cart</StepTitle>
              <StepDescription className="md:max-w-sm">
                {isSignature
                  ? `Thank you for being a Signature client. You'll see an overview of our standard
                  pricing to the left, but all of your onboarding and device fees are covered under
                  the Signature subscription.`
                  : "Continue to enter your payment information and complete your order."}
              </StepDescription>
            </StepTop>
          </div>
        </div>
      </StepColumn>
    </div>
  );
};

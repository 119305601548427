import {
  COLDCARD,
  ColdcardMultisigWalletConfig,
  ConfigAdapter,
  MultisigWalletConfig,
} from "@caravan/wallets";

import { WalletAPI } from "Shared/api";
import { WalletUuid } from "Shared/api/walletApi";
import { GetWalletConfig200 } from "Specs/v1";
import { AccountKey } from "Specs/v1/getAccount/200";
import { downloadTextFile } from "Utils/download";
import { Product } from "Utils/productUtils";

type ProductUuid = Product["uuid"];

export const getColdcardConfig = (
  walletConfig: GetWalletConfig200,
  productUuid: string
): string => {
  walletConfig.uuid = getColdcardConfigUuid(walletConfig, productUuid);
  let interaction = ConfigAdapter({
    KEYSTORE: COLDCARD,
    jsonConfig: walletConfig as MultisigWalletConfig,
  });
  // So an error will be thrown if the config file doesn't have all the necessary pieces... ^
  if (interaction instanceof ColdcardMultisigWalletConfig) {
    // only Coldcard has this method so we need this check for typescript
    return interaction.adapt();
  }
  return "";
};

export const getColdcardConfigName = (walletConfig: GetWalletConfig200): string =>
  `wc-${walletConfig.uuid}.txt`;

export const getColdcardConfigUuid = (
  walletConfig?: GetWalletConfig200,
  productUuid?: ProductUuid
) => {
  if (!walletConfig) return "";
  const startingAddressIndex = walletConfig.startingAddressIndex;
  return startingAddressIndex === 0
    ? `${productUuid}_${walletConfig.uuid}`
    : // this is basically just for legacy wallets that were rekeyed
      // pre-braids and so used starting index to differentiate them
      `${productUuid}_${startingAddressIndex.toString()}`;
};

export class DownloadColdcardConfig {
  productUUID: ProductUuid;
  errorMessage: string;
  // wallet configs in coldcard need a special uuid
  walletConfig?: GetWalletConfig200 & { uuid?: string };
  ccReady: boolean;
  keysNeedingXfp: AccountKey[];
  walletUuid: WalletUuid;

  constructor({
    productUUID,
    walletUuid,
    walletConfig,
  }: {
    productUUID: ProductUuid;
    walletUuid: WalletUuid;
    walletConfig?: GetWalletConfig200;
  }) {
    this.productUUID = productUUID;
    this.walletUuid = walletUuid;
    this.errorMessage = "";
    this.walletConfig = walletConfig || null;
    this.ccReady = true;
    this.keysNeedingXfp = [];
  }

  // Talk to the backend to get a walletConfig and then check that all keys have xfps
  establishWalletConfig = async () => {
    try {
      this.walletConfig = await WalletAPI.GetConfig(this.walletUuid);
    } catch (e) {
      const { data } = e.response;
      this.errorMessage = data.message;
      throw e;
    }
    // Checking for Coldcard ready -- we could make @caravan/wallets a little smarter about how it fails, but
    // we have more relevant information for our application in the store.
    const { extendedPublicKeys } = this.walletConfig;
    if (extendedPublicKeys) {
      const keysNeedingXfp = [];
      extendedPublicKeys.forEach(key => {
        if (key.xfp === "Unknown") {
          keysNeedingXfp.push(key.name);
        }
      });
      this.ccReady = keysNeedingXfp.length === 0;
      this.keysNeedingXfp = keysNeedingXfp;
    }
  };

  handleDownloadConfigClick = () => {
    this.reshapeConfig();
  };

  // This tries to reshape it to a Coldcard Wallet Config via @caravan/wallets
  reshapeConfig = () => {
    let body = "";
    if (this.walletConfig) {
      body = getColdcardConfig(this.walletConfig, this.productUUID);
      if (!body) {
        this.errorMessage =
          "Product is not Coldcard ready. Please perform key checks on each key to make sure root fingerprints are available.";
        return;
      }
      const filename = getColdcardConfigName(this.walletConfig);
      downloadTextFile(body, filename);
      this.errorMessage = "";
    }
  };
}

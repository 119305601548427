import { CheckIfOrgClosureIsAllowed200, PostOrgUserSignerRoleErrors } from "Specs/v2";
import { OrgPayingCustomer, SetupIntent } from "Specs/v2/components";

import { ApiType } from "../apiType";

export const OrgAPIV2Factory = (API: ApiType) => ({
  UpdateFCPNote: async (uuid, data: { fcpNote?: string }) => {
    const response = await API.Put<{ fcpNote: string }>(`/orgs/${uuid}/fcp_note`, data);
    return response.data;
  },

  UpdateCRR: async (uuid, data: { crrScore?: number; crrIdentifier?: string }) => {
    const response = await API.Put<{ crrScore: number; crrIdentifier: string }>(
      `/orgs/${uuid}/crr`,
      data
    );
    return response.data;
  },

  CreateChangeProposalGroup: async (orgUuid: string, data: any) =>
    (await API.Post(`/orgs/${orgUuid}/change-proposal-groups`, data)).data,

  GetClosureAllowed: async (orgUuid: string) =>
    (await API.Get<CheckIfOrgClosureIsAllowed200>(`/orgs/${orgUuid}/closure-allowed`)).data,

  CloseAccount: async (orgUuid: string) => (await API.Post(`/orgs/${orgUuid}/close`)).data,

  BypassPayment: async (orgUuid: string) =>
    (await API.Post(`/orgs/${orgUuid}/bypass-payment`)).data,

  GetPayingCustomer: async (orgUuid: string) => {
    try {
      return (await API.Get<OrgPayingCustomer>(`/orgs/${orgUuid}/paying-customer`)).data;
    } catch (error) {
      if (error?.response?.data?.message == "Paying customer not found") {
        return undefined;
      } else {
        throw error;
      }
    }
  },

  CreateSetupIntent: async (orgUuid: string) =>
    (await API.Post<SetupIntent>(`/orgs/${orgUuid}/paying-customer/setup-intent`)).data,

  DetachPaymentMethod: async (orgUuid: string, paymentMethodId: string) =>
    (await API.Post(`/orgs/${orgUuid}/paying-customer/detach-payment-method`, { paymentMethodId }))
      .data,

  UpdatePaymentMethod: async (orgUuid: string, data: any) =>
    (await API.Patch(`/orgs/${orgUuid}/paying-customer/payment-method`, data)).data,

  LockForPayment: async (orgUuid: string) =>
    (await API.Post(`/orgs/${orgUuid}/lock-for-payment`)).data,

  UnlockForPayment: async (orgUuid: string) =>
    (await API.Post(`/orgs/${orgUuid}/unlock-for-payment`)).data,

  PostOrgUserKeySignerRole: async (orgUuid: string, userUuid: string) => {
    return await API.Post<PostOrgUserSignerRoleErrors>(
      `/orgs/${orgUuid}/members/${userUuid}/signer`
    );
  },

  RemoveMember: async (orgUUID: string, email: string) => {
    const response = await API.Post(`/orgs/${orgUUID}/members/remove`, { email });
    return response.data;
  },
});

import { useEffect } from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Loader } from "@unchained/component-library";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router-dom";

import Flash from "Components/Flash";
import { Layout } from "Components/Layout";
import { Overlay } from "Components/Layout/Overlay";
import { useNavigate } from "Components/Link";
import { TradeWirePaymentDueToast } from "Components/TradingDesk/components";
import { BuyBitcoinProvider } from "Contexts/BuyBitcoin";
import { SellBitcoinProvider } from "Contexts/SellBitcoin/sellBitcoinContext";
import { useMemoizedState } from "Redux/selectors/hooks";
import { REQUEST_STATUS, useGetBtcPriceQuery } from "Shared/api";
import { withAccount } from "Shared/api/hooks/account";
import { SlideoutContainer } from "Shared/components/SlideoutManager";
import { ACTIVE_TIMEOUT, isActive, setActive } from "Utils/user";

import { AppModalManager } from "../shared/components/Modals/AppModalManager";
import { ModalContainer } from "../shared/components/Modals/ModalContainer";

const { SUCCESS } = REQUEST_STATUS;

const UserAppBase = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
    }
  }, [location, navigate]);

  // Log out after 15 minutes of inactivity.
  useIdleTimer({
    timeout: ACTIVE_TIMEOUT,
    onIdle() {
      // If there's a modal open, close it first before logging out
      if (AppModalManager) AppModalManager.close();
      navigate(`/logout?next=${encodeURIComponent(window.location.pathname)}`);
    },
    onAction() {
      setActive();
    },
    debounce: 500,
    // If multiple tabs are open, this ensures all are logged out.
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  // Log out if the user re-opens tab after 15 minutes of closing last tab.
  // The above `useIdleTimer` does not keep state after the last tab is closed,
  // so we store the last active timestamp in `localStorage` ourselves.
  useEffect(() => {
    if (isActive() === false) {
      navigate(`/logout?next=${encodeURIComponent(window.location.pathname)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BuyBitcoinProvider>
        <SellBitcoinProvider>
          <Layout />
          {/* Items which need to render outside of the main layout */}
          <Flash userAuthenticated={true} />
          <Overlay />
          <SlideoutContainer />
          <ModalContainer />
          <TradeWirePaymentDueToast />
        </SellBitcoinProvider>
      </BuyBitcoinProvider>
    </LocalizationProvider>
  );
};

export default withAccount(({ shouldOnboard }) => {
  const navigate = useNavigate();
  useGetBtcPriceQuery();

  useEffect(() => {
    const next = encodeURIComponent(window.location.pathname);

    if (window.TREFOIL_USER === undefined) return navigate(`/login?next=${next}`);

    if (shouldOnboard && next !== "onboard") navigate("/onboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Downstream components require the account data in state to be hydrated
  // Wait til it is before rendering the app
  const userStatus = useMemoizedState("account.user.status");
  const orgStatus = useMemoizedState("account.orgs.status");

  if ([userStatus, orgStatus].some(status => status !== SUCCESS))
    return <Loader className="h-screen" />;

  return <UserAppBase />;
});

import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import DeviceInteractionStep from "../../../SigningDevices/DeviceInteractionStep";
import { DEVICE_INTERACTION_TYPE_ENUM } from "../../../Support/SupportData";
import { SignKey } from "./SignKey";

const SignKeyStepBase = ({ walletType, ...rest }) => {
  return (
    <DeviceInteractionStep
      interactionType={DEVICE_INTERACTION_TYPE_ENUM.SIGNING}
      InteractionComponent={SignKey}
      walletType={walletType}
      size="sm"
      {...rest}
    />
  );
};

SignKeyStepBase.propTypes = {
  walletType: PropTypes.string.isRequired,
  next: PropTypes.func, //.isRequired,
  back: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    walletType: state.accountKeys.keyCheckWizard.walletType,
  };
};

export const SignKeyStep = connect(mapStateToProps)(SignKeyStepBase);

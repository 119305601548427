import React from "react";

import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";

export const LedgerLogo = ({ viewBox = "0 0 24 24", htmlColor = "black", classes = {} }, props) => (
  <SvgIcon {...props} viewBox={viewBox} htmlColor={htmlColor} classes={classes}>
    <path d="M18.8 2H9.59998V14.3H22V5.2C22 3.4 20.5 2 18.8 2Z" />
    <path d="M6.8 2H5.2C3.4 2 2 3.4 2 5.2V6.8H6.8V2Z" />
    <path d="M2 9.6001H6.8V14.4001H2V9.6001Z" />
    <path d="M17.2 21.9998H18.7999C20.5999 21.9998 22 20.5998 22 18.7998V17.2998H17.2V21.9998Z" />
    <path d="M9.59998 17.2002H14.4V22.0002H9.59998V17.2002Z" />
    <path d="M2 17.2002V18.8002C2 20.6002 3.4 22.0002 5.2 22.0002H6.8V17.2002H2Z" />
  </SvgIcon>
);

LedgerLogo.propTypes = {
  classes: PropTypes.object,
  htmlColor: PropTypes.string,
  viewBox: PropTypes.string,
};

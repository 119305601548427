const handleDeviceError = e => {
  const notConnectedOrPluggedIn = ["No Ledger device found (timeout)", "No Ledger device found"];
  const timedOutOrDisconnected = [
    "Failed to sign with Ledger device: U2F TIMEOUT",
    "Failed to sign with Ledger device: U2F DEVICE_INELIGIBLE",
    "Failed to sign with Ledger device: U2F OTHER_ERROR",
  ];
  const knownBackendErrors = [
    "Key check failed.",
    "Key check failed. Root fingerprint does not match. Is this the right device?",
    "Key check failed. Public key does not match. Is this the right device?",
    "A transfer error has occurred.", //DOMException if you freeze the device in U2F and then try webusb and unplug it
    "Select your device in the WebUSB dialog box. Make sure it's plugged in, unlocked, and has the Bitcoin app open.",
    "Safari is not a supported browser.",
  ];
  let cleanMessage;

  //LEDGER ERRORS
  if (
    e.message ===
    "Ledger device: Security not satisfied (dongle locked or have invalid access rights) (0x6982)"
  ) {
    cleanMessage = `Are you on the home screen? Make sure to open the Bitcoin app. (0x6982)`;
  } else if (e.message === "Ledger device: INS_NOT_SUPPORTED (0x6d00)") {
    cleanMessage = `Are you on the home screen? Make sure to open the Bitcoin app. (0x6d00)`;
  } else if (e.message === "Ledger device: UNKNOWN_ERROR (0x6511)") {
    cleanMessage = "Are you on the home screen? Make sure to open the Bitcoin app. (0x6511)";
  } else if (e.message === "Ledger device: CLA_NOT_SUPPORTED (0x6e00)") {
    cleanMessage = `To use your Ledger device, please turn on Developer Mode in Ledger Live and download the Bitcoin Legacy App (0x6e00)`;
  } else if (e.message === "Ledger device: UNKNOWN_ERROR (0x6d02)") {
    cleanMessage = `Are you on the home screen? Make sure to open the Bitcoin app. (0x6d02)`;
  } else if (e.message === "Ledger device: UNKNOWN_ERROR (0xb007)") {
    cleanMessage = `Are you sure you have the right device? If seed has a passphrase, make sure to enter it. (0xb007)`;
  } else if (e.message === "Ledger device: UNKNOWN_ERROR (0xb008)") {
    cleanMessage = `There seems to be a problem with your device registration. Please contact our support team. (0xb008)`;
  } else if (e.message.includes("Ledger device: UNKNOWN_ERROR (0x6e01)")) {
    //0x6e01 is the error code for "invalid status"
    cleanMessage = `Are you on the home screen? Make sure to open the Bitcoin app. (0x6e01)`;
  } else if (notConnectedOrPluggedIn.includes(e.message)) {
    cleanMessage = `Please ensure your Ledger is unlocked and your Bitcoin app is open.`;
  } else if (timedOutOrDisconnected.includes(e.message)) {
    cleanMessage =
      `The key export has timed out. ` +
      `Ensure your device is connected, unlocked, and your Bitcoin app is open and try again.`;
  } else if (
    e.message === "Ledger device: Condition of use not satisfied (denied by the user?) (0x6985)"
  ) {
    cleanMessage = "Request was cancelled. Please try again. (0x6985)";
  } else if (e.message === "Error with Ledger transport") {
    cleanMessage = "WebUSB prompt was cancelled. Please try again.";
  } else if (
    e.message === "Invalid channel" ||
    e.message ===
      "Failed to execute 'requestDevice' on 'USB': Must be handling a user gesture to show a permission request."
  ) {
    cleanMessage =
      "Problem communicating via WebUSB. Please try re-opening the Bitcoin app or reconnecting your Ledger.";
  } else if (e.message === "Ledger device: Internal error, please report (0x6faa)") {
    cleanMessage = `There is a pending action on your Ledger.  Cancel it and try again. (0x6faa)`;
  } else if (e.message === "Action cancelled by user" || e.message === "Cancelled") {
    cleanMessage = `Request was cancelled. Please try again.`;
  } else if (e.message === "Error signing spend.") {
    cleanMessage = `There was an error signing the spend. Is this the right keystore?`;
  } else if (e.message === "Unable to reset the device.") {
    cleanMessage =
      `A recent Chrome update (v87) requires another step before proceeding. ` +
      `Please visit chrome://flags/#new-usb-backend and change the setting to 'Disabled'. ` +
      `Then, restart your browser and try again.`;
  }
  //TREZOR ERRORS
  else if (e.message === "Popup closed") {
    cleanMessage = `Popup closed. Trezor requires you to interact via a popup.`;
  } else if (e.message === "Device disconnected") {
    cleanMessage = `Device was disconnected. Please plug it in and try again.`;
  } else if (
    e.message === "Pubkey not found in multisig script" ||
    e.message === "Failed to derive scriptPubKey"
  ) {
    cleanMessage = `Public key not found in multisig script. Is this the right device?`;
  } else if (e.message === "Permissions not granted") {
    cleanMessage = `Request was denied. Please grant permission and try again.`;
  } else if (e.message === "Forbidden key path") {
    cleanMessage = `Forbidden key path. Please email help@unchained.com for help.`;
  } else if (e.message === "Transport is missing") {
    cleanMessage =
      "Unable to connect to Trezor device. Please ensure Trezor Suite or Trezor Bridge is running and try again.";
  } else if (e.message === "Failed to fetch") {
    cleanMessage =
      "Encountered error when communicating with Trezor device. Please ensure Trezor Suite or Trezor Bridge is running and try again.";
  } else if (e.message === "Interaction timeout") {
    cleanMessage = "Trezor interaction timed out. Please try again later.";
  } else if (e.message === "All backends are down") {
    cleanMessage = "Trezor's servers can't be reached. Please try again later.";
  } else if (e.message === "session not found") {
    cleanMessage =
      "Something went wrong. Close Trezor Suite or other incompatible applications and try again. You could also try another USB cable or USB port.";
  }
  // Confirm On Device Errors.
  else if (e.message === "Can't encode address") {
    cleanMessage =
      "This device cannot sign for the selected key.\nPlease check your device or passphrase if enabled.";
  } else if (e.message === "Wrong public key specified") {
    cleanMessage =
      "This device can sign for a key in this address, but not the selected key.\nPlease check your device or passphrase if enabled.";
  }
  //Coldcard Errors
  else if (e.message === "Unable to parse JSON.") {
    cleanMessage = "Was that a JSON file? We were not able to parse it.";
  } else if (e.message === "ExtendedPublicKeys missing at least one xfp.") {
    cleanMessage = "Please perform a keycheck on your keys to make this product Coldcard ready.";
  } else if (e.message === "Missing private key for hardened child key") {
    cleanMessage =
      "If you've restored a Trezor or Ledger to your Coldcard by copying wallet words, " +
      "you may lose the ability to perform key checks with this key, but you'll still be able to " +
      "sign transactions. If you'd like to perform key checks, we suggest you make new keys from your Coldcard.";
  } else if (e.message === "Invalid network version") {
    cleanMessage = "Did you change your Coldcard network to match the environment?";
  }
  // Custom Error
  else if (
    e.message.includes("ExtendedPublicKey") ||
    e.message.includes("Root fingerprint validation")
  ) {
    cleanMessage = e.message;
  }
  // Browser Error
  else if (e.message === "Unable to claim interface.") {
    cleanMessage =
      "Are you still connected to the device in another tab? Please close the other tab or disconnect and reconnect your device and then try again.";
  }
  //TREFOIL ERRORS (leave it alone)
  else if (knownBackendErrors.includes(e.message)) {
    cleanMessage = e.message;
  } else {
    cleanMessage = "Unknown error: " + e.message;
  }
  return cleanMessage;
};

export { handleDeviceError };

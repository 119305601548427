import { FC } from "react";

import { UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";

import { ReactQueryLoader } from "Components/Shared/Elements/ReactQueryLoader";
import { useErrorToast } from "Utils/toasts";
import { useQueryParams } from "Utils/uris";

/** Function which takes an object with URL params and passed component props, and returns a useQuery result. */
type GetUseQuery<Props> = (getArgsArgs: {
  params: Record<string, string>;
  props: Props;
  queryParams: Record<string, unknown>;
}) => UseQueryResult<unknown>;

/** The string value to pass the result under to the child component. If undefined, result is spread */
type ResultKey = string | undefined;
type QueryTuple<Props = Record<string, unknown>> = [GetUseQuery<Props>, ResultKey];
type QueryResult = Record<string, unknown> | Record<string, Record<string, unknown>>;

/**
 * Sort of the React Query version of React Redux's `connect`. This provides a neater interface for "wrapping" a component
 * with the API data it requires. Until that data resolves, a loading spinner is shown. If an error hits, a toast is shown.
 * Once it resolves, the data is passed to the underlying component.
 *
 * This "wrapper" is a bit more complex than the typical useQuery pattern, and is mostly better for cases where you want the "main" component
 * to receive the data unconditionally, so it can use it in a useEffect, etc.
 *
 * @param Component The Component to be rendered once the provided query has been resolved
 * @param useQuery The React Query hook to be resolved and passed to the Component
 * @param getArgs A function returning an ordered array of args to pass to the query func.
 *                     getArgs takes an object with URL `params` and passed `props`
 * @param resultKey The name of the prop to pass the query data to the Component as.
 *                  If not provided, the incoming data from the query will be spread into the Component's props.
 *
 * @example
 *
 * const OrgNameContent = ({ org }: { org: CompleteOrg }) => {
 *  const { name } = org;
 *  return <div>Org name: {name}</div>
 * }
 *
 * export const OrgName = withQuery(
 *  OrgNameContent,
 *  useGetOrg,
 *  ({ params }) => [params.uuid], // useGetOrg takes a single uuid param. The uuid comes from the URL.
 *  "org" // The OrgNameContent component expects an "org" prop, so we pass the data as that prop.
 * );
 */
export const withQuery = <Props, ProvidedProps extends keyof Props>(
  Component: FC<Props>,
  getUseQuery: GetUseQuery<Omit<Props, ProvidedProps>>,
  resultKey: ResultKey = undefined
) => {
  type NeededProps = Omit<Props, ProvidedProps>;
  const ComponentWithQuery: FC<NeededProps> = (props: NeededProps) => {
    const showErrorToast = useErrorToast();
    const params = useParams();
    const queryParams = useQueryParams();
    const query = getUseQuery({ params, props, queryParams });

    return (
      <ReactQueryLoader useQueryResult={query} handleError={showErrorToast}>
        {({ data }) => {
          const resultProps = (resultKey ? { [resultKey]: data } : data) as
            | QueryResult
            | Record<string, QueryResult> as Props;
          return <Component {...props} {...resultProps} />;
        }}
      </ReactQueryLoader>
    );
  };

  return ComponentWithQuery;
};

/** The plural version of withQuery. Passed a component and an array of query arguments */
export const withQueries = <Props, ProvidedProps extends keyof Props>(
  Component: FC<Props>,
  /** A tuple of values representing a query */
  queries: QueryTuple<Props>[]
) => {
  type NeededProps = Omit<Props, ProvidedProps>;
  return (
    queries
      // Reverse the array so the queries are applied in the correct order
      // this allows results from the first query to be passed to the second in props, etc.
      .reverse()
      .reduce(
        (Component, query) => withQuery<Props, ProvidedProps>(Component, ...query),
        Component
      ) as FC<NeededProps>
  );
};
